export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Upload: { input: any; output: any; }
  Void: { input: any; output: any; }
  DateTime: { input: string; output: string; }
  Date: { input: string; output: string; }
  Time: { input: string; output: string; }
  Email: { input: any; output: any; }
  Identifier: { input: number; output: number; }
  UUID: { input: string; output: string; }
  JSON: { input: string; output: string; }
};

export type MilkingParlorIntervalPenGroupsConnection = {
  __typename?: 'MilkingParlorIntervalPenGroupsConnection';
  edges: Array<MilkingParlorIntervalPenGroupEdge>;
};

export type SetReproductionCrReportSettingsInput = {
  farmID?: InputMaybe<Scalars['ID']['input']>;
  since: Scalars['Date']['input'];
  till: Scalars['Date']['input'];
  femaleAnimalKind?: FemaleAnimalKind;
  xAxisMode?: ConceptionRateParameterEnum;
  xAxisStep?: InputMaybe<ReproductionCrDateStep>;
  xAxisValues?: InputMaybe<Array<ReproductionCrValueInput>>;
  xAxisShouldShowOther?: Scalars['Boolean']['input'];
  xAxisOtherFromTotalThresholdPercent?: InputMaybe<Scalars['Int']['input']>;
  yAxisMode?: ReproductionYAxisMode;
  yAxisShouldCompareToPreviousPeriod?: Scalars['Boolean']['input'];
  yAxisCompareToPreviousPeriodMode?: InputMaybe<CompareToPreviousPeriodMode>;
  yAxisCompareToPreviousPeriodChartKind?: InputMaybe<ReproductionCrReportChartKind>;
  lactationGroupNumbers?: InputMaybe<Array<LactationGroupNumber>>;
  cowIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  groupBy?: InputMaybe<ConceptionRateParameterEnum>;
  groupByValues?: InputMaybe<Array<ReproductionCrValueInput>>;
  groupByChartKind?: InputMaybe<ReproductionCrReportChartKind>;
  groupByStep?: InputMaybe<ReproductionCrDateStep>;
};

export type BlueprintViewTableSettings = {
  __typename?: 'BlueprintViewTableSettings';
  fields: Array<SourceField>;
  masterFields: Array<SourceField>;
  groupByFields: Array<SourceField>;
};

export type CowStaticField = CowField & {
  __typename?: 'CowStaticField';
  valueKind: ValueKindEnum;
  value?: Maybe<BlueprintValue>;
  isPinned: Scalars['Boolean']['output'];
  verboseName: Scalars['String']['output'];
  kind: SourceFieldKindEnum;
  section: SourceSectionType;
};

export type CustomReportChartValueAxis = {
  __typename?: 'CustomReportChartValueAxis';
  valueKey: CustomReportSettingsValue;
};

export type FilterValueInput = {
  intValue?: InputMaybe<Scalars['Int']['input']>;
  floatValue?: InputMaybe<Scalars['Float']['input']>;
  datetimeValue?: InputMaybe<Scalars['DateTime']['input']>;
  idValue?: InputMaybe<Scalars['ID']['input']>;
  idsValue?: InputMaybe<Array<Scalars['ID']['input']>>;
  strValue?: InputMaybe<Scalars['String']['input']>;
};

export type FarmConnection = {
  __typename?: 'FarmConnection';
  edges: Array<FarmEdge>;
  nodes: Array<Farm>;
  pageInfo: BeriaPageInfo;
};

export type CreateBlueprintFilterGroupInput = {
  blueprintID: Scalars['ID']['input'];
  logicOperator: LogicOperator;
  blueprintFilterGroupID?: InputMaybe<Scalars['ID']['input']>;
};

export type DatePeriodInput = {
  interval?: InputMaybe<DatePeriodIntervalInput>;
  daysFromToday?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateCustomReportInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<CustomReportSettingsInput>;
  blueprintRoleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum VitalityFilter {
  Alive = 'ALIVE',
  Dead = 'DEAD',
  All = 'ALL'
}

export type ProtocolInjection = {
  __typename?: 'ProtocolInjection';
  id: Scalars['ID']['output'];
  company: Company;
  injection?: Maybe<Injection>;
  protocol: Protocol;
  comment: Scalars['String']['output'];
  dayNumber: Scalars['Int']['output'];
};

export type SourceSectionType = {
  __typename?: 'SourceSectionType';
  kind: SourceSectionKindEnum;
  verboseName: Scalars['String']['output'];
  fields: Array<SourceFieldType>;
};

export type ReproductionCrReportConnection = {
  __typename?: 'ReproductionCrReportConnection';
  pageInfo: PageInfo;
  edges: Array<ReproductionCrReportEdge>;
  nodes: Array<ReproductionCrReport>;
};

export enum CustomReportSortByKindEnum {
  Default = 'DEFAULT',
  Value = 'VALUE'
}

export type ReproductionCrCalculatedComparisonReport = ReproductionCrCalculatedReportInterface & {
  __typename?: 'ReproductionCrCalculatedComparisonReport';
  cacheCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  xAxisValues: Array<ReproductionCrValue>;
  groupByValues: Array<ReproductionCrValue>;
  actual: ReproductionCrDetailedRowsByEntity;
  historic: ReproductionCrDetailedRowsByEntity;
  historicPeriod: ReproductionCrCalculatedComparisonReportHistoricPeriod;
};

export type IntegratorConnection = {
  __typename?: 'IntegratorConnection';
  edges: Array<IntegratorEdge>;
  nodes: Array<Integrator>;
  pageInfo: BeriaPageInfo;
};

export type UpdateBlueprintRoleInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<BlueprintRoleColor>;
};

export type ProtocolDiseaseConnection = {
  __typename?: 'ProtocolDiseaseConnection';
  pageInfo: PageInfo;
  edges: Array<ProtocolDiseaseEdge>;
  nodes: Array<Disease>;
};

export enum MilkingMistakeKindsEnum {
  Reattach = 'REATTACH',
  LateRehang = 'LATE_REHANG',
  NoMilk = 'NO_MILK',
  EnteredTwice = 'ENTERED_TWICE',
  NoLetdown = 'NO_LETDOWN',
  EarlyFalloff = 'EARLY_FALLOFF',
  ManualDetach = 'MANUAL_DETACH',
  ManualMode = 'MANUAL_MODE'
}

export type BlueprintRole = {
  __typename?: 'BlueprintRole';
  id: Scalars['ID']['output'];
  company: Company;
  shouldBeMigratedOnDelete: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  color: BlueprintRoleColor;
};

export type BeriaPageInfo = {
  __typename?: 'BeriaPageInfo';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
  endCursor?: Maybe<Scalars['String']['output']>;
};

export type ReadOnlyArgument = Argument & {
  __typename?: 'ReadOnlyArgument';
  name: Scalars['String']['output'];
  verboseName: Scalars['String']['output'];
};

export type CustomReportChartAggValueAxis = {
  __typename?: 'CustomReportChartAggValueAxis';
  valueKey: CustomReportSettingsValue;
  aggFormula?: Maybe<CustomReportChartAggFormulaKindEnum>;
  withRightScale: Scalars['Boolean']['output'];
};

export type SomaticCellsReportSettingsHistoricFilter = {
  __typename?: 'SomaticCellsReportSettingsHistoricFilter';
  kind?: Maybe<MilkingHistoricFilterKind>;
  value?: Maybe<SomaticCellsReportSettingsHistoricValue>;
};

export type BlueprintLaunchRow = BlueprintLaunchDataRow | BlueprintLaunchGroupedRow;

export type ReproductionCrRowByAnimalCycleValueInput = {
  periodStart: Scalars['Int']['input'];
  periodEnd?: InputMaybe<Scalars['Int']['input']>;
};

export enum BlueprintRoleColor {
  Grey = 'GREY',
  Red = 'RED',
  Violet = 'VIOLET',
  Orange = 'ORANGE',
  Green = 'GREEN',
  Blue = 'BLUE',
  Yellow = 'YELLOW',
  Pink = 'PINK'
}

export enum MarkingMeanEnum {
  Label = 'LABEL',
  Microchip = 'MICROCHIP',
  Brand = 'BRAND',
  Tattoo = 'TATTOO',
  Bolus = 'BOLUS',
  Ring = 'RING',
  ElectronicRing = 'ELECTRONIC_RING',
  Collar = 'COLLAR',
  ElectronicColar = 'ELECTRONIC_COLAR',
  WingTag = 'WING_TAG',
  ElectronicWingTag = 'ELECTRONIC_WING_TAG',
  ElectronicTag = 'ELECTRONIC_TAG',
  TissueSection = 'TISSUE_SECTION',
  Nameplate = 'NAMEPLATE'
}

export type BlueprintLaunch = {
  __typename?: 'BlueprintLaunch';
  id: Scalars['ID']['output'];
  user: User;
  blueprint?: Maybe<Blueprint>;
  hasEvents: Scalars['Boolean']['output'];
  events: CowEventInterfaceConnection;
  cowsCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
};


export type BlueprintLaunchEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomMilkingReportChart = CustomMilkingReportChartInterface & {
  __typename?: 'CustomMilkingReportChart';
  xAxisLabels: CustomMilkingReportChartXAxisLabels;
  yAxisDatasets: Array<Array<Maybe<Scalars['Float']['output']>>>;
  yAxisCowCounts: Array<Array<Scalars['Int']['output']>>;
  yAxisDatasetLabels: Array<CustomMilkingReportDatasetInterface>;
  cacheCreatedAt: Scalars['DateTime']['output'];
};

export enum ReproductionCrReportChartKind {
  Bar = 'BAR',
  Line = 'LINE'
}

export type ReproductionCrRowsByBullBreed = ReproductionCrDetailedRowsByEntity & {
  __typename?: 'ReproductionCrRowsByBullBreed';
  hashID: Scalars['String']['output'];
  total?: Maybe<ReproductionCrRowsRow>;
  other?: Maybe<ReproductionCrRowsRow>;
  rows: Array<ReproductionCrRowsRow>;
  rowNames: Array<Maybe<Breed>>;
};

export type CreateCustomReportInput = {
  name: Scalars['String']['input'];
  blueprintID: Scalars['ID']['input'];
  blueprintRoleIDs?: Array<Scalars['ID']['input']>;
};

export type HerriotIntegrationSettings = {
  __typename?: 'HerriotIntegrationSettings';
  apiKey: Scalars['String']['output'];
  apiLogin: Scalars['String']['output'];
  issuerID: Scalars['UUID']['output'];
  breedingValueType: BreedingValueTypeEnum;
  markingMeans: MarkingMeanEnum;
  supervisedObjectGuid: Scalars['UUID']['output'];
  initiatorLogin: Scalars['String']['output'];
  keepingPurpose: HerriotIntegrationEntity;
  keepingType: HerriotIntegrationEntity;
  markingAttachmentLocation: HerriotIntegrationEntity;
};

export type DefaultEvent = Event & {
  __typename?: 'DefaultEvent';
  name: Scalars['String']['output'];
  kind: EventKindEnum;
  shortcode: DefaultEventEnum;
  description: Scalars['String']['output'];
};

export type BlueprintEdge = {
  __typename?: 'BlueprintEdge';
  node: Blueprint;
  cursor: Scalars['String']['output'];
};

export type BullConnection = {
  __typename?: 'BullConnection';
  pageInfo: PageInfo;
  edges: Array<BullEdge>;
  nodes: Array<Bull>;
};

export type BlueprintLaunchInput = {
  inputID: Scalars['ID']['input'];
  value: BlueprintLaunchInputValueInput;
};

export type BlueprintViewGraphSettingsInput = {
  kind: ViewGraphKindEnum;
};

export enum CowState {
  Calf = 'CALF',
  Fresh = 'FRESH',
  Open = 'OPEN',
  Bred = 'BRED',
  Preg = 'PREG',
  Dry = 'DRY',
  LateDry = 'LATE_DRY',
  Sold = 'SOLD',
  Moved = 'MOVED',
  Dnb = 'DNB',
  Dead = 'DEAD',
  Bull = 'BULL'
}

export type CustomMilkingReportSettings = {
  __typename?: 'CustomMilkingReportSettings';
  dataSource: CustomMilkingReportDataSource;
  since: Scalars['Date']['output'];
  till: Scalars['Date']['output'];
  mode: CustomMilkingReportXAxisMode;
  modeMaxCutoffValue?: Maybe<Scalars['Int']['output']>;
  step?: Maybe<CustomMilkingReportXAxisStep>;
  shouldCompareWithHistoricData: Scalars['Boolean']['output'];
  compareWithHistoricDataPeriod?: Maybe<CompareToPreviousPeriodMode>;
  yAxisFields: Array<CustomMilkingReportYAxisField>;
  filters: Array<CustomMilkingReportSettingsFilters>;
  grouping?: Maybe<CustomMilkingReportSettingsGrouping>;
  blueprint?: Maybe<Blueprint>;
};

export type CustomMilkingReportSettingsGroupingHistoric = {
  __typename?: 'CustomMilkingReportSettingsGroupingHistoric';
  kind?: Maybe<MilkingHistoricFilterKind>;
};

export enum TestMilkingUploadResolveConflictsStrategy {
  Sum = 'SUM',
  Overwrite = 'OVERWRITE',
  Ignore = 'IGNORE'
}

export type CreateCompanyUserInput = {
  firstName?: Scalars['String']['input'];
  lastName?: Scalars['String']['input'];
  middleName?: Scalars['String']['input'];
  email: Scalars['Email']['input'];
  companyID: Scalars['ID']['input'];
  farmIDs?: Array<Scalars['ID']['input']>;
  blueprintRoleIDs?: Array<Scalars['ID']['input']>;
};

export type CowLactationGraph = {
  __typename?: 'CowLactationGraph';
  lactation: Lactation;
  lactationNumber: Scalars['Int']['output'];
  milk: Array<CowMilkOnDayInMilk>;
  testMilk: Array<CowTestMilkOnDayInMilk>;
  predictedMilk: Array<CowPredictedMilkOnDayInMilk>;
  penGroups: Array<CowInPenGroup>;
  events: Array<CowEventAtDayInMilk>;
  daysInMilk: Scalars['Int']['output'];
  latestDayInMilkHappenedAt: Scalars['DateTime']['output'];
};

export type DiseaseEdge = {
  __typename?: 'DiseaseEdge';
  node: Disease;
  cursor: Scalars['String']['output'];
};

export type LivestockForecastSetting = {
  __typename?: 'LivestockForecastSetting';
  id: Scalars['ID']['output'];
  farm: Farm;
  company: Company;
  dryPeriod?: Maybe<Scalars['Int']['output']>;
  dryPeriodCalc: Scalars['Int']['output'];
  freshPeriod?: Maybe<Scalars['Int']['output']>;
  freshPeriodCalc: Scalars['Int']['output'];
  cowsVoluntaryWaitingPeriod?: Maybe<Scalars['Int']['output']>;
  cowsVoluntaryWaitingPeriodCalc: Scalars['Int']['output'];
  heifersVoluntaryWaitingPeriod?: Maybe<Scalars['Int']['output']>;
  heifersVoluntaryWaitingPeriodCalc: Scalars['Int']['output'];
  cowsPregnancyRate?: Maybe<Scalars['Float']['output']>;
  cowsPregnancyRateCalc: Scalars['Float']['output'];
  heifersPregnancyRate?: Maybe<Scalars['Float']['output']>;
  heifersPregnancyRateCalc: Scalars['Float']['output'];
  numberOfCyclesCalc: Scalars['Float']['output'];
  cowsLeftInFirstLactRate?: Maybe<Scalars['Float']['output']>;
  cowsLeftInFirstLactRateCalc: Scalars['Float']['output'];
  cowsLeftInOtherLactRate?: Maybe<Scalars['Float']['output']>;
  cowsLeftInOtherLactRateCalc: Scalars['Float']['output'];
  bullsLeftZeroToTwoMonthRate?: Maybe<Scalars['Float']['output']>;
  bullsLeftZeroToTwoMonthRateCalc: Scalars['Float']['output'];
  bullsLeftThreeToSixMonthRate?: Maybe<Scalars['Float']['output']>;
  bullsLeftThreeToSixMonthRateCalc: Scalars['Float']['output'];
  bullsLeftSevenToTwelveMonthRate?: Maybe<Scalars['Float']['output']>;
  bullsLeftSevenToTwelveMonthRateCalc: Scalars['Float']['output'];
  heifersLeftZeroToTwoMonthRate?: Maybe<Scalars['Float']['output']>;
  heifersLeftZeroToTwoMonthRateCalc: Scalars['Float']['output'];
  heifersLeftThreeToSixMonthRate?: Maybe<Scalars['Float']['output']>;
  heifersLeftThreeToSixMonthRateCalc: Scalars['Float']['output'];
  heifersLeftSevenToTwelveMonthRate?: Maybe<Scalars['Float']['output']>;
  heifersLeftSevenToTwelveMonthRateCalc: Scalars['Float']['output'];
  heifersInFirstLactRate?: Maybe<Scalars['Float']['output']>;
  heifersInFirstLactRateCalc: Scalars['Float']['output'];
  heifersInOtherLactRate?: Maybe<Scalars['Float']['output']>;
  heifersInOtherLactRateCalc: Scalars['Float']['output'];
  stillbirthsInFirstLactRate?: Maybe<Scalars['Float']['output']>;
  stillbirthsInFirstLactRateCalc: Scalars['Float']['output'];
  stillbirthsInOtherLactRate?: Maybe<Scalars['Float']['output']>;
  stillbirthsInOtherLactRateCalc: Scalars['Float']['output'];
};

export type CreateSourceFieldInput = {
  blueprintSourceSectionID: Scalars['ID']['input'];
  kind: SourceFieldKindEnum;
  name: Scalars['String']['input'];
};

export enum CustomReportValueFormulaKindEnum {
  Count = 'COUNT',
  Counta = 'COUNTA',
  CountUnique = 'COUNT_UNIQUE',
  Max = 'MAX',
  Min = 'MIN',
  Median = 'MEDIAN',
  Average = 'AVERAGE',
  Sum = 'SUM'
}

export type ReproductionCrRowsRow = {
  __typename?: 'ReproductionCrRowsRow';
  hashID: Scalars['String']['output'];
  cr?: Maybe<Scalars['Int']['output']>;
  pregnant?: Maybe<Scalars['Int']['output']>;
  notPregnant?: Maybe<Scalars['Int']['output']>;
  other?: Maybe<Scalars['Int']['output']>;
  abortions?: Maybe<Scalars['Int']['output']>;
  inseminations?: Maybe<Scalars['Int']['output']>;
  uncheckedInseminations?: Maybe<Scalars['Int']['output']>;
  uncheckedInseminationsPercent?: Maybe<Scalars['Int']['output']>;
  inseminationsPercent?: Maybe<Scalars['Int']['output']>;
  semenDosesTillPregnancyCount?: Maybe<Scalars['Float']['output']>;
};

export type ReproductionCrCalculatedReportInterface = {
  cacheCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  xAxisValues: Array<ReproductionCrValue>;
  groupByValues: Array<ReproductionCrValue>;
};

export type CustomReportNestedRow = CustomReportRowInterface & {
  __typename?: 'CustomReportNestedRow';
  blueprintSourceFieldValue?: Maybe<CustomReportBlueprintSourceFieldValue>;
  values: Array<Maybe<CustomReportRowValue>>;
  nestedRows: Array<CustomReportRow>;
};

export type CowEventInterfaceEdge = {
  __typename?: 'CowEventInterfaceEdge';
  node: CowEvent;
  cursor: Scalars['String']['output'];
};

export type SourceFieldConnection = {
  __typename?: 'SourceFieldConnection';
  pageInfo: PageInfo;
  edges: Array<SourceFieldEdge>;
  nodes: Array<SourceField>;
};

export type ActionType = {
  __typename?: 'ActionType';
  kind: ActionKindEnum;
  verboseName: Scalars['String']['output'];
};

export type CalendarRowType = CalendarDivider | CalendarEntry;

export type CreateReproductionHdrAndPrReportInput = {
  name: Scalars['String']['input'];
  roleIDs?: Array<Scalars['ID']['input']>;
};

export type ReproductionCrInseminationNumberValue = {
  __typename?: 'ReproductionCrInseminationNumberValue';
  inseminationNumber?: Maybe<Scalars['Int']['output']>;
};

export type SomaticCellsReportChart = SomaticCellsReportChartInterface & {
  __typename?: 'SomaticCellsReportChart';
  dates: Array<Scalars['Date']['output']>;
  researchedAnimalsCounts: Array<Maybe<Scalars['Int']['output']>>;
  chronicallyIllPercents: Array<Maybe<Scalars['Float']['output']>>;
  chronicallyIllCounts: Array<Maybe<Scalars['Int']['output']>>;
  illPercents: Array<Maybe<Scalars['Float']['output']>>;
  illCounts: Array<Maybe<Scalars['Int']['output']>>;
  recoveredPercents: Array<Maybe<Scalars['Float']['output']>>;
  recoveredCounts: Array<Maybe<Scalars['Int']['output']>>;
  healthyPercents: Array<Maybe<Scalars['Float']['output']>>;
  healthyCounts: Array<Maybe<Scalars['Int']['output']>>;
  highValueFirstTestMilkingPercents: Array<Maybe<Scalars['Float']['output']>>;
  highValueFirstTestMilkingCounts: Array<Maybe<Scalars['Int']['output']>>;
  normalValueFirstTestMilkingPercents: Array<Maybe<Scalars['Float']['output']>>;
  normalValueFirstTestMilkingCounts: Array<Maybe<Scalars['Int']['output']>>;
  probabilityOfRecoveryPercents: Array<Maybe<Scalars['Float']['output']>>;
  probabilityOfIllnessPercents: Array<Maybe<Scalars['Float']['output']>>;
  cacheCreatedAt: Scalars['DateTime']['output'];
};

export type UpdateBlueprintRunSettingsInput = {
  runType?: InputMaybe<RunType>;
  interval?: InputMaybe<ScheduleInterval>;
  repeatInterval?: InputMaybe<Scalars['Int']['input']>;
  startOn?: InputMaybe<Scalars['DateTime']['input']>;
  autostartOn?: InputMaybe<Scalars['DateTime']['input']>;
  dows?: InputMaybe<Array<Array<Dow>>>;
};

export type ProtocolScheduledOperationInput = {
  day: Scalars['Int']['input'];
  comment: Scalars['String']['input'];
  operationID: Scalars['ID']['input'];
};

export type CowPlannedInjectionsEdge = {
  __typename?: 'CowPlannedInjectionsEdge';
  node: CowPlannedInjections;
  cursor: Scalars['String']['output'];
};

export type CowPlannedInjections = {
  __typename?: 'CowPlannedInjections';
  cow: Cow;
  cowProtocol: CowProtocol;
  protocol?: Maybe<Protocol>;
  disease?: Maybe<Disease>;
  protocolPenGroup?: Maybe<PenGroup>;
  injections: Array<Injection>;
  protocolPenGroupName: Scalars['String']['output'];
  diseaseName: Scalars['String']['output'];
  protocolName: Scalars['String']['output'];
  expectedDaysOnProtocol: Scalars['Int']['output'];
  protocolDayNumber: Scalars['Int']['output'];
  protocolDayComments: Array<Scalars['String']['output']>;
  injectionNames: Array<Scalars['String']['output']>;
};

export type EventIdInput = {
  eventID?: InputMaybe<Scalars['ID']['input']>;
  defaultEventKind?: InputMaybe<DefaultEventEnum>;
};

export type TestMilkingUploadEdge = {
  __typename?: 'TestMilkingUploadEdge';
  node: TestMilkingUpload;
  cursor: Scalars['String']['output'];
};

export type CustomReportChartEmptyReport = CustomReportChartInterface & {
  __typename?: 'CustomReportChartEmptyReport';
  xAxisLabels: Array<Maybe<CustomReportBlueprintSourceFieldValue>>;
};

export type SourceFieldValueInput = {
  intValue?: InputMaybe<Scalars['Int']['input']>;
  floatValue?: InputMaybe<Scalars['Float']['input']>;
  dateValue?: InputMaybe<Scalars['Date']['input']>;
  strValue?: InputMaybe<Scalars['String']['input']>;
  idValue?: InputMaybe<Scalars['ID']['input']>;
};

export type UserCompanyEdge = {
  __typename?: 'UserCompanyEdge';
  id: Scalars['ID']['output'];
  node: Company;
  cursor: Scalars['String']['output'];
  canEditSettings: Scalars['Boolean']['output'];
  blueprintRoles?: Maybe<Array<BlueprintRole>>;
};

export type InseminationScheme = Event & {
  __typename?: 'InseminationScheme';
  name: Scalars['String']['output'];
  kind: EventKindEnum;
  id: Scalars['ID']['output'];
  company: Company;
  description: Scalars['String']['output'];
  code: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
};

export type MonitorLaunchValue = {
  happenedAt: Scalars['DateTime']['output'];
  monitorLaunch: MonitorLaunch;
};

export type SomaticCellsReport = {
  __typename?: 'SomaticCellsReport';
  id: Scalars['ID']['output'];
  company: Company;
  settings: SomaticCellsReportSettings;
  calculatedReport: SomaticCellsReportChartInterface;
  createdAt: Scalars['DateTime']['output'];
};


export type SomaticCellsReportCalculatedReportArgs = {
  invalidateCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CowFieldMappingInput = {
  targetField: CowFieldsEnum;
  importedName?: InputMaybe<Scalars['String']['input']>;
};

export type CowDefaultEvent = CowEvent & {
  __typename?: 'CowDefaultEvent';
  happenedAt: Scalars['DateTime']['output'];
  comment: Scalars['String']['output'];
  /** @deprecated for backwards compat; remove */
  canRollback: Scalars['Boolean']['output'];
  cow: Cow;
  lactation?: Maybe<Lactation>;
  event: DefaultEvent;
  id: Scalars['ID']['output'];
  company: Company;
  retirementReason?: Maybe<CowRetirementReason>;
  protocol?: Maybe<Protocol>;
  penGroupFrom?: Maybe<PenGroup>;
  penGroupTo?: Maybe<PenGroup>;
  daysInPregnancy?: Maybe<Scalars['Int']['output']>;
  blueprintLaunch?: Maybe<BlueprintLaunch>;
  weighing?: Maybe<Weighing>;
  heightMeasurement?: Maybe<HeightMeasurement>;
  /** @deprecated typo */
  bodyCondtionScore?: Maybe<BodyConditionScore>;
  bodyConditionScore?: Maybe<BodyConditionScore>;
  insemination?: Maybe<Insemination>;
  calving?: Maybe<Calving>;
  copyKey?: Maybe<CowsCopyKey>;
};

export type CowMilkOnDayInMilk = {
  __typename?: 'CowMilkOnDayInMilk';
  happenedAt: Scalars['Date']['output'];
  milkWeightKilograms: Scalars['Float']['output'];
  fatPercent?: Maybe<Scalars['Float']['output']>;
  proteinPercent?: Maybe<Scalars['Float']['output']>;
  daysInMilk: Scalars['Int']['output'];
};

export type CustomReportSettingsSortBy = {
  __typename?: 'CustomReportSettingsSortBy';
  kind: CustomReportSortByKindEnum;
  sortingValue?: Maybe<CustomReportSettingsSortBySortingValue>;
};

export type CowsStatusCount = {
  __typename?: 'CowsStatusCount';
  milking: Scalars['Int']['output'];
  allDry: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ReproductionCrRowsByInseminationScheme = ReproductionCrDetailedRowsByEntity & {
  __typename?: 'ReproductionCrRowsByInseminationScheme';
  hashID: Scalars['String']['output'];
  total?: Maybe<ReproductionCrRowsRow>;
  other?: Maybe<ReproductionCrRowsRow>;
  rows: Array<ReproductionCrRowsRow>;
  rowNames: Array<Maybe<InseminationScheme>>;
};

export enum ReproductionHdrAndPrXAxisMode {
  DaysInMilk = 'DAYS_IN_MILK',
  Date = 'DATE'
}

export type ReproductionRowByDateRowNameInput = {
  since: Scalars['Date']['input'];
  till: Scalars['Date']['input'];
};

export type CreateSemenDoseInput = {
  batchNumber: Scalars['String']['input'];
  dosesCount: Scalars['Int']['input'];
  studCode: Scalars['String']['input'];
  deliveryDate?: InputMaybe<Scalars['Date']['input']>;
  bullID: Scalars['ID']['input'];
};

export type CompanyFarmEdge = {
  __typename?: 'CompanyFarmEdge';
  node: Farm;
  cursor: Scalars['String']['output'];
};

export type EventInterfaceEdge = {
  __typename?: 'EventInterfaceEdge';
  node: Event;
  cursor: Scalars['String']['output'];
};

export type CustomReportBlueprintLaunchResultFilterInput = {
  blueprintSourceFieldID: Scalars['ID']['input'];
  blueprintSourceFieldValue: CustomReportBlueprintSourceFieldValueInput;
};

export type MilkingParlorScheduleReportStallEntry = {
  __typename?: 'MilkingParlorScheduleReportStallEntry';
  stallNumber: Scalars['Int']['output'];
  milkings: Array<ParlorStallEntryMilkingType>;
};

export type UpdateBlueprintInput = {
  blueprintRoleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  templateID?: InputMaybe<Scalars['ID']['input']>;
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  runSettings?: InputMaybe<UpdateBlueprintRunSettingsInput>;
  generalSettings?: InputMaybe<UpdateBlueprintGeneralSettingsInput>;
  isForIntegratorOnly?: InputMaybe<Scalars['Boolean']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateBlueprintInputInput = {
  blueprintID: Scalars['ID']['input'];
  blueprintCycleInputID?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
};

export type ImportedCowField = {
  __typename?: 'ImportedCowField';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  field?: Maybe<CowFieldsEnum>;
};

export type WritableArgument = Argument & {
  __typename?: 'WritableArgument';
  name: Scalars['String']['output'];
  verboseName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  value?: Maybe<BlueprintValue>;
  kind: ValueKindEnum;
  isRequired: Scalars['Boolean']['output'];
  valueKind?: Maybe<NodeValueKindEnum>;
};

export enum CompareOperator {
  Eq = 'EQ',
  Ne = 'NE',
  Gte = 'GTE',
  Gt = 'GT',
  Lte = 'LTE',
  Lt = 'LT',
  In = 'IN',
  NotIn = 'NOT_IN',
  IsNull = 'IS_NULL',
  NotNull = 'NOT_NULL'
}

export type CowPlannedInjectionsConnection = {
  __typename?: 'CowPlannedInjectionsConnection';
  pageInfo: PageInfo;
  edges: Array<CowPlannedInjectionsEdge>;
  nodes: Array<CowPlannedInjections>;
};

export type Event = {
  name: Scalars['String']['output'];
  kind: EventKindEnum;
};

export type ReproductionTargets = {
  __typename?: 'ReproductionTargets';
  hdrCowsTargetPercent: Scalars['Int']['output'];
  hdrCowsDeviationThresholdPercent: Scalars['Int']['output'];
  hdrHeifersTargetPercent: Scalars['Int']['output'];
  hdrHeifersDeviationThresholdPercent: Scalars['Int']['output'];
  prCowsTargetPercent: Scalars['Int']['output'];
  prCowsDeviationThresholdPercent: Scalars['Int']['output'];
  prHeifersTargetPercent: Scalars['Int']['output'];
  prHeifersDeviationThresholdPercent: Scalars['Int']['output'];
  crCowsTargetPercent: Scalars['Int']['output'];
  crCowsDeviationThresholdPercent: Scalars['Int']['output'];
  crHeifersTargetPercent: Scalars['Int']['output'];
  crHeifersDeviationThresholdPercent: Scalars['Int']['output'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<UserEdge>;
  nodes: Array<User>;
  pageInfo: BeriaPageInfo;
};

export type BlueprintFilter = {
  __typename?: 'BlueprintFilter';
  id: Scalars['ID']['output'];
  company: Company;
  field: SourceField;
  value?: Maybe<BlueprintValue>;
  compareOperator: CompareOperator;
};

export type TestMilkingEdge = {
  __typename?: 'TestMilkingEdge';
  node: TestMilking;
  cursor: Scalars['String']['output'];
};

export type CustomMilkingReportChartXAxisIntLabels = {
  __typename?: 'CustomMilkingReportChartXAxisIntLabels';
  xAxisIntLabels: Array<Scalars['Int']['output']>;
};

export type CalendarRowTypeEdge = {
  __typename?: 'CalendarRowTypeEdge';
  node: CalendarRowType;
  cursor: Scalars['String']['output'];
};

export enum MilkParlorStatsDateKind {
  Generic = 'GENERIC',
  Predicted = 'PREDICTED',
  NoData = 'NO_DATA'
}

export type LivestockLactationPeriodsTotalMilk = {
  __typename?: 'LivestockLactationPeriodsTotalMilk';
  firstLactation: Scalars['Int']['output'];
  otherLactations: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CustomReportBlueprintSourceFieldValueInput = {
  intValue?: InputMaybe<Scalars['Int']['input']>;
  floatValue?: InputMaybe<Scalars['Float']['input']>;
  strValue?: InputMaybe<Scalars['String']['input']>;
  datetimeValue?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomReportBlueprintLaunchResult = {
  __typename?: 'CustomReportBlueprintLaunchResult';
  columnSourceFields: Array<SourceField>;
  rows: Array<CustomReportBlueprintLaunchResultRow>;
};

export type CustomMilkingReportSettingsHistoricValueLactationGroupNumbers = {
  __typename?: 'CustomMilkingReportSettingsHistoricValueLactationGroupNumbers';
  lactationGroupNumbers?: Maybe<Array<LactationGroupNumber>>;
};

export type CreateSourceSectionInput = {
  blueprintID: Scalars['ID']['input'];
  kind: SourceSectionKindEnum;
};

export type ArgumentValueInput = {
  intValue?: InputMaybe<Scalars['Int']['input']>;
  floatValue?: InputMaybe<Scalars['Float']['input']>;
  datetimeValue?: InputMaybe<Scalars['DateTime']['input']>;
  idValue?: InputMaybe<Scalars['ID']['input']>;
  idsValue?: InputMaybe<Array<Scalars['ID']['input']>>;
  strValue?: InputMaybe<Scalars['String']['input']>;
};

export type Blueprint = {
  __typename?: 'Blueprint';
  id: Scalars['ID']['output'];
  company: Company;
  /** @deprecated use just "roles" */
  blueprintRoles: Array<BlueprintRole>;
  roles: Array<BlueprintRole>;
  runSettings: BlueprintRunSettings;
  generalSettings: BlueprintGeneralSettings;
  viewSettings: BlueprintViewSettings;
  template?: Maybe<Blueprint>;
  sourceSections: Array<SourceSection>;
  masterSourceSections: Array<SourceSection>;
  inputs: Array<BlueprintInput>;
  cycleInputs: Array<BlueprintCycleInput>;
  specialInput?: Maybe<BlueprintSpecialInput>;
  actions: Array<BlueprintAction>;
  filterGroups: Array<BlueprintFilterGroup>;
  unions: Array<BlueprintUnion>;
  launches: BlueprintLaunchConnection;
  name: Scalars['String']['output'];
  description: Scalars['String']['output'];
  isTemplate: Scalars['Boolean']['output'];
  kind?: Maybe<BlueprintKind>;
  isForIntegratorOnly: Scalars['Boolean']['output'];
  priority: Scalars['Int']['output'];
};


export type BlueprintLaunchesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type PenGroupEdge = {
  __typename?: 'PenGroupEdge';
  node: PenGroup;
  cursor: Scalars['String']['output'];
};

export type OperationEdge = {
  __typename?: 'OperationEdge';
  node: Operation;
  cursor: Scalars['String']['output'];
};

export type LivestockCows = {
  __typename?: 'LivestockCows';
  firstLactation: LivestockCowsLactation;
  otherLactations: LivestockCowsLactation;
  milkingRate: Scalars['Float']['output'];
  total: Scalars['Int']['output'];
};

export type UpdateCustomReportChartSettingsInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<CustomReportChartKindEnum>;
  xAxis?: InputMaybe<CustomReportChartXAxisInput>;
  yAxes?: InputMaybe<Array<CustomReportChartYAxisInput>>;
};

export type CustomMilkingReportChartXAxisLabels = CustomMilkingReportChartXAxisIntLabels | CustomMilkingReportChartXAxisDateLabels;

export type ReproductionCrRowsByDate = ReproductionCrDetailedRowsByEntity & {
  __typename?: 'ReproductionCrRowsByDate';
  hashID: Scalars['String']['output'];
  total?: Maybe<ReproductionCrRowsRow>;
  other?: Maybe<ReproductionCrRowsRow>;
  rows: Array<ReproductionCrRowsRow>;
  rowNames: Array<Maybe<ReproductionCrDateValue>>;
};

export type HeightMeasurement = {
  __typename?: 'HeightMeasurement';
  id: Scalars['ID']['output'];
  happenedAt: Scalars['DateTime']['output'];
  heightCentimeters: Scalars['Float']['output'];
};

export type LivestockLiveMonthsPeriodsPlusTotal = {
  __typename?: 'LivestockLiveMonthsPeriodsPlusTotal';
  zeroToTwoMonth: Scalars['Int']['output'];
  threeToSixMonth: Scalars['Int']['output'];
  sevenToTwelveMonth: Scalars['Int']['output'];
  twelvePlusMonth: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type MilkingParlorGeneralReportRow = MilkingParlorGeneralReportRowEntry | MilkingParlorGeneralReportGroupedRowEntry;

export enum LactationGroupNumber {
  First = 'FIRST',
  Second = 'SECOND',
  Third = 'THIRD'
}

export type MilkingParlorIntervalConnection = {
  __typename?: 'MilkingParlorIntervalConnection';
  pageInfo: PageInfo;
  edges: Array<MilkingParlorIntervalEdge>;
  nodes: Array<MilkingParlorInterval>;
};

export type CustomReportChartXAxis = CustomReportChartValueAxis | CustomReportChartFieldAxis;

export type File = {
  __typename?: 'File';
  downloadUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  extension: Scalars['String']['output'];
};

export type Protocol = Event & {
  __typename?: 'Protocol';
  name: Scalars['String']['output'];
  kind: EventKindEnum;
  id: Scalars['ID']['output'];
  company: Company;
  diseases: Array<Disease>;
  diseasesConnection: ProtocolDiseaseConnection;
  penGroup?: Maybe<PenGroup>;
  protocolInjections: Array<ProtocolInjection>;
  totalTreatmentCost?: Maybe<Scalars['Float']['output']>;
  expectedTotalTreatmentCost?: Maybe<Scalars['Float']['output']>;
  daysMeatWithhold: Scalars['Int']['output'];
  daysMilkWithhold: Scalars['Int']['output'];
  injectionsSchedule: Array<ProtocolScheduledInjection>;
  operationsSchedule: Array<ProtocolScheduledOperation>;
  processKind: ProcessKindEnum;
  expectedDaysOnProtocol?: Maybe<Scalars['Int']['output']>;
  daysUntilCheckAfterLastThreatment?: Maybe<Scalars['Int']['output']>;
  forYoung: Scalars['Boolean']['output'];
  forCows: Scalars['Boolean']['output'];
  deactivatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomMilkingReportSettingsHistoricFilterInput = {
  kind?: InputMaybe<MilkingHistoricFilterKind>;
  value?: InputMaybe<CustomMilkingReportSettingsHistoricValueInput>;
};

export type OperationConnection = {
  __typename?: 'OperationConnection';
  pageInfo: PageInfo;
  edges: Array<OperationEdge>;
  nodes: Array<Operation>;
};

export type FetchFromFileInput = {
  file: Scalars['Upload']['input'];
  provider: ImportProvidersEnum;
};

export type BlueprintLaunchSpecialInput = {
  value: BlueprintLaunchInputValueInput;
};

export enum ParlorExportFileKindEnum {
  AlproFull = 'ALPRO__FULL',
  DelproFull = 'DELPRO__FULL',
  DataflowFull = 'DATAFLOW__FULL',
  BoumaticFull = 'BOUMATIC__FULL',
  DairyPlanCowStatus = 'DAIRY_PLAN__COW_STATUS',
  DairyPlanCutCodes = 'DAIRY_PLAN__CUT_CODES'
}

export type ActivateCowsCopyKeyInput = {
  key: Scalars['String']['input'];
  arriveDate: Scalars['Date']['input'];
  penGroupID: Scalars['ID']['input'];
  farmID: Scalars['ID']['input'];
  identifierMappings: Array<IdentifierMappingInput>;
  diseaseMappings: Array<ItemMappingInput>;
  operationMappings: Array<ItemMappingInput>;
  inseminationBullMappings: Array<ItemMappingInput>;
};

export type CowLactationConnection = {
  __typename?: 'CowLactationConnection';
  pageInfo: PageInfo;
  edges: Array<CowLactationEdge>;
  nodes: Array<Lactation>;
};

export enum FemaleAnimalKind {
  Heifer = 'HEIFER',
  Cow = 'COW'
}

export type CustomReportSettingsColumn = {
  __typename?: 'CustomReportSettingsColumn';
  blueprintSourceField: SourceField;
  order: CustomReportOrderEnum;
  sortBy: CustomReportSettingsSortBy;
  withTotal: Scalars['Boolean']['output'];
};

export type ReproductionHdrAndPrRow = {
  hashID: Scalars['String']['output'];
  fitForInsemination?: Maybe<Scalars['Int']['output']>;
  inseminated?: Maybe<Scalars['Int']['output']>;
  hdr?: Maybe<Scalars['Int']['output']>;
  fitForPregnancy?: Maybe<Scalars['Int']['output']>;
  pregnant?: Maybe<Scalars['Int']['output']>;
  pr?: Maybe<Scalars['Int']['output']>;
  notPregnant?: Maybe<Scalars['Int']['output']>;
  notPregnantPercent?: Maybe<Scalars['Int']['output']>;
  cr?: Maybe<Scalars['Int']['output']>;
  abortions?: Maybe<Scalars['Int']['output']>;
};

export type CutCodeConnection = {
  __typename?: 'CutCodeConnection';
  pageInfo: PageInfo;
  edges: Array<CutCodeEdge>;
  nodes: Array<CutCode>;
};

export type DatePeriodInterval = {
  __typename?: 'DatePeriodInterval';
  since: Scalars['Date']['output'];
  till: Scalars['Date']['output'];
};

export type CreateCowInput = {
  comment?: Scalars['String']['input'];
  identifier: Scalars['Identifier']['input'];
  oldIdentifier?: InputMaybe<Scalars['Identifier']['input']>;
  name?: Scalars['String']['input'];
  birthWeightKilograms?: InputMaybe<Scalars['Float']['input']>;
  currentHeightCentimeters?: InputMaybe<Scalars['Float']['input']>;
  usdaNumber?: Scalars['String']['input'];
  registrationNumber?: Scalars['String']['input'];
  breed?: Scalars['String']['input'];
  compNumber?: Scalars['String']['input'];
  lamenessScore?: Scalars['Int']['input'];
  nipplesCount?: Scalars['Int']['input'];
  electronicTagNumber?: Scalars['String']['input'];
  transponderNumber?: Scalars['String']['input'];
  herriotCode?: Scalars['String']['input'];
  herriotCodeSetAt?: InputMaybe<Scalars['DateTime']['input']>;
  selexBreedID?: InputMaybe<Scalars['Int']['input']>;
  dateOfBirth: Scalars['DateTime']['input'];
  calvingID?: InputMaybe<Scalars['ID']['input']>;
  farmID: Scalars['ID']['input'];
  state?: CowState;
  penGroupID: Scalars['ID']['input'];
  currentWeightKilograms?: InputMaybe<Scalars['Float']['input']>;
  motherID?: InputMaybe<Scalars['ID']['input']>;
  motherDcID?: InputMaybe<Scalars['Int']['input']>;
  motherSelexID?: InputMaybe<Scalars['String']['input']>;
  motherName?: Scalars['String']['input'];
  motherUsdaNumber?: Scalars['String']['input'];
  fatherID?: InputMaybe<Scalars['ID']['input']>;
  fatherDcID?: InputMaybe<Scalars['Int']['input']>;
  fatherSelexID?: InputMaybe<Scalars['String']['input']>;
  fatherName?: Scalars['String']['input'];
  fatherUsdaNumber?: Scalars['String']['input'];
  numberOnPreviousFarm?: Scalars['String']['input'];
  retirementReason?: InputMaybe<CowRetirementReason>;
  retiredAt?: InputMaybe<Scalars['DateTime']['input']>;
  dcID?: InputMaybe<Scalars['Int']['input']>;
  selexID?: InputMaybe<Scalars['String']['input']>;
  herdEntryDate?: InputMaybe<Scalars['DateTime']['input']>;
  lactationNumber?: InputMaybe<Scalars['Int']['input']>;
  currentLactationHappenedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InjectionConnection = {
  __typename?: 'InjectionConnection';
  pageInfo: PageInfo;
  edges: Array<InjectionEdge>;
  nodes: Array<Injection>;
};

export type ProtocolEdge = {
  __typename?: 'ProtocolEdge';
  node: Protocol;
  cursor: Scalars['String']['output'];
};

export enum MilkingParlorManufacturerEnum {
  Gea = 'GEA',
  Boumatic = 'BOUMATIC',
  Dataflow = 'DATAFLOW',
  Alpro = 'ALPRO',
  Delpro = 'DELPRO'
}

export type ExportFileInput = {
  provider: ExportProvidersEnum;
  farmID: Scalars['ID']['input'];
  companyID?: InputMaybe<Scalars['ID']['input']>;
  period?: InputMaybe<DatePeriodInput>;
  eventsToExport?: InputMaybe<Array<ExportEventsEnum>>;
};

export type CreateReproductionCrReportInput = {
  name: Scalars['String']['input'];
  roleIDs?: Array<Scalars['ID']['input']>;
};

export type UpdateUserInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
};

export type Injection = Event & {
  __typename?: 'Injection';
  name: Scalars['String']['output'];
  kind: EventKindEnum;
  id: Scalars['ID']['output'];
  company: Company;
  hasProtocol: Scalars['Boolean']['output'];
  manufacturer?: Maybe<InjectionsManufacturer>;
  daysMilkWithholdAfterLastThreatment: Scalars['Int']['output'];
  daysMeatWithholdAfterLastThreatment: Scalars['Int']['output'];
  cost?: Maybe<Scalars['Float']['output']>;
  isDefault: Scalars['Boolean']['output'];
};

export type BlueprintCycleInput = {
  __typename?: 'BlueprintCycleInput';
  id: Scalars['ID']['output'];
  company: Company;
  inputs: Array<BlueprintInput>;
  createdAt: Scalars['DateTime']['output'];
};

export type BlueprintLaunchEdge = {
  __typename?: 'BlueprintLaunchEdge';
  node: BlueprintLaunch;
  cursor: Scalars['String']['output'];
};

export type ReproductionCrRowsByDows = ReproductionCrDetailedRowsByEntity & {
  __typename?: 'ReproductionCrRowsByDows';
  hashID: Scalars['String']['output'];
  total?: Maybe<ReproductionCrRowsRow>;
  other?: Maybe<ReproductionCrRowsRow>;
  rows: Array<ReproductionCrRowsRow>;
  rowNames: Array<Maybe<ReproductionCrRowByDowsValue>>;
};

export type UpdateCompanyInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  licensedBy?: InputMaybe<Scalars['DateTime']['input']>;
  tzIdentifier?: InputMaybe<Scalars['String']['input']>;
};

export type BlueprintAction = {
  __typename?: 'BlueprintAction';
  id: Scalars['ID']['output'];
  verboseName: Scalars['String']['output'];
  company: Company;
  parentFilterGroup?: Maybe<BlueprintFilterGroup>;
  arguments: Array<Argument>;
  kind: ActionKindEnum;
};

export type MonitorConnection = {
  __typename?: 'MonitorConnection';
  pageInfo: PageInfo;
  edges: Array<MonitorEdge>;
  nodes: Array<MonitorGroupMonitorEntry>;
};

export type CreateBlueprintInput = {
  name: Scalars['String']['input'];
  description: Scalars['String']['input'];
  blueprintRoleIDs: Array<Scalars['ID']['input']>;
  templateID?: InputMaybe<Scalars['ID']['input']>;
  isTemplate: Scalars['Boolean']['input'];
  runSettings: CreateBlueprintRunSettingsInput;
  generalSettings: CreateBlueprintGeneralSettingsInput;
  isForIntegratorOnly?: Scalars['Boolean']['input'];
  priority?: Scalars['Int']['input'];
};

export type CustomMilkingReportSettingsActualFilterInput = {
  masterBlueprintSourceFieldID?: InputMaybe<Scalars['ID']['input']>;
  value?: InputMaybe<SourceFieldValueInput>;
};

export type ProtocolScheduledInjection = {
  __typename?: 'ProtocolScheduledInjection';
  day: Scalars['Int']['output'];
  comment: Scalars['String']['output'];
  injection: Injection;
};

export type ImportFileInput = {
  file: Scalars['Upload']['input'];
  provider: ImportProvidersEnum;
  farmID: Scalars['ID']['input'];
  companyID?: InputMaybe<Scalars['ID']['input']>;
  eventsMapping?: InputMaybe<Array<EventMappingInput>>;
  cowFieldsMapping?: InputMaybe<Array<CowFieldMappingInput>>;
};

export type UpdateMilkingParlorInput = {
  farmID?: InputMaybe<Scalars['ID']['input']>;
  manufacturer?: InputMaybe<MilkingParlorManufacturerEnum>;
  kind?: InputMaybe<MilkingParlorKindEnum>;
  name?: InputMaybe<Scalars['String']['input']>;
  capacityStart?: InputMaybe<Scalars['Int']['input']>;
  capacityEnd?: InputMaybe<Scalars['Int']['input']>;
  connectionInfo?: InputMaybe<Scalars['String']['input']>;
  sortingGatesCount?: InputMaybe<Scalars['Int']['input']>;
  tagField?: InputMaybe<ParlorExportStatusFileTagFieldEnum>;
  regNumField?: InputMaybe<RegNumFieldEnum>;
};

export type InjectionsManufacturerConnection = {
  __typename?: 'InjectionsManufacturerConnection';
  pageInfo: PageInfo;
  edges: Array<InjectionsManufacturerEdge>;
  nodes: Array<InjectionsManufacturer>;
};

export type CustomReportFormulaColumn = CustomReportColumnInterface & {
  __typename?: 'CustomReportFormulaColumn';
  blueprintSourceField: SourceField;
  formula: CustomReportValueFormulaKindEnum;
  view: CustomReportValueViewKindEnum;
};

export type CustomReportChartEmptyChart = CustomReportChartInterface & {
  __typename?: 'CustomReportChartEmptyChart';
  xAxisLabels: Array<Maybe<CustomReportBlueprintSourceFieldValue>>;
};

export type UpdateFarmInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID']['output'];
  disabledAt?: Maybe<Scalars['DateTime']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  middleName: Scalars['String']['output'];
  farms: Array<Farm>;
  companies: UserCompanyConnection;
  integrator?: Maybe<Integrator>;
  email: Scalars['Email']['output'];
};

export type SemenDoseConnection = {
  __typename?: 'SemenDoseConnection';
  pageInfo: PageInfo;
  edges: Array<SemenDoseEdge>;
  nodes: Array<SemenDose>;
};

export type ReproductionCrReport = {
  __typename?: 'ReproductionCrReport';
  id: Scalars['ID']['output'];
  settings: ReproductionCrReportSettings;
  roles: Array<BlueprintRole>;
  calculatedReport: ReproductionCrCalculatedReportInterface;
  name: Scalars['String']['output'];
};


export type ReproductionCrReportCalculatedReportArgs = {
  invalidateCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum AnimalKindEnum {
  Cow = 'COW',
  Heifer = 'HEIFER'
}

export type InseminationSchemeConnection = {
  __typename?: 'InseminationSchemeConnection';
  pageInfo: PageInfo;
  edges: Array<InseminationSchemeEdge>;
  nodes: Array<InseminationScheme>;
};

export type ReproductionCrCalculatedCrossTableReport = ReproductionCrCalculatedReportInterface & {
  __typename?: 'ReproductionCrCalculatedCrossTableReport';
  cacheCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  xAxisValues: Array<ReproductionCrValue>;
  groupByValues: Array<ReproductionCrValue>;
  cr: ReproductionCrCrossTableSubTable;
  checkedInseminations: ReproductionCrCrossTableSubTable;
  successInseminations: ReproductionCrCrossTableSubTable;
};

export type UpdateDiseaseInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  protocolIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  comment?: InputMaybe<Scalars['String']['input']>;
};

export type Calving = {
  __typename?: 'Calving';
  id: Scalars['ID']['output'];
  company: Company;
  cow: Cow;
  employee?: Maybe<Employee>;
  results: Array<CalvingResult>;
  happenedAt: Scalars['DateTime']['output'];
  isAbortion: Scalars['Boolean']['output'];
  difficultyScore: Scalars['Int']['output'];
};

export type ReproductionCrDetailedRowsByEntity = {
  hashID: Scalars['String']['output'];
  total?: Maybe<ReproductionCrRowsRow>;
  other?: Maybe<ReproductionCrRowsRow>;
  rows: Array<ReproductionCrRowsRow>;
};

export type CowProtocol = CowEvent & {
  __typename?: 'CowProtocol';
  happenedAt: Scalars['DateTime']['output'];
  comment: Scalars['String']['output'];
  canRollback: Scalars['Boolean']['output'];
  cow: Cow;
  lactation?: Maybe<Lactation>;
  id: Scalars['ID']['output'];
  company: Company;
  protocol: Protocol;
  cowProtocolInjections: Array<CowProtocolInjection>;
  currentProtocolDay: Scalars['Int']['output'];
  diseaseName: Scalars['String']['output'];
  stoppedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CalendarEntry = {
  __typename?: 'CalendarEntry';
  date: Scalars['DateTime']['output'];
  blueprint: Blueprint;
};

export type CreateMilkingParlorInput = {
  farmID: Scalars['ID']['input'];
  manufacturer: MilkingParlorManufacturerEnum;
  kind: MilkingParlorKindEnum;
  name: Scalars['String']['input'];
  capacityStart: Scalars['Int']['input'];
  capacityEnd: Scalars['Int']['input'];
  sortingGatesCount: Scalars['Int']['input'];
  connectionInfo: Scalars['String']['input'];
  tagField?: ParlorExportStatusFileTagFieldEnum;
  regNumField?: RegNumFieldEnum;
};

export type CowEvent = {
  happenedAt: Scalars['DateTime']['output'];
  comment: Scalars['String']['output'];
  canRollback: Scalars['Boolean']['output'];
  cow?: Maybe<Cow>;
  lactation?: Maybe<Lactation>;
};

export type CreateProtocolInput = {
  name: Scalars['String']['input'];
  penGroupID?: InputMaybe<Scalars['ID']['input']>;
  expectedDaysOnProtocol?: InputMaybe<Scalars['Int']['input']>;
  daysUntilCheckAfterLastThreatment?: InputMaybe<Scalars['Int']['input']>;
  totalTreatmentCost?: InputMaybe<Scalars['Float']['input']>;
};

export type BlueprintViewGraphSettings = {
  __typename?: 'BlueprintViewGraphSettings';
  kind: ViewGraphKindEnum;
};

export type CustomReport = {
  __typename?: 'CustomReport';
  id: Scalars['ID']['output'];
  company: Company;
  blueprint: Blueprint;
  settings: CustomReportSettings;
  chartsSettings: Array<CustomReportChartSettings>;
  /** @deprecated use just "roles" */
  blueprintRoles: Array<BlueprintRole>;
  roles: Array<BlueprintRole>;
  name: Scalars['String']['output'];
};

export type UpdateCowInput = {
  identifier?: InputMaybe<Scalars['Identifier']['input']>;
  oldIdentifier?: InputMaybe<Scalars['Identifier']['input']>;
  calvingID?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  farmID?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<CowState>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  birthWeightKilograms?: InputMaybe<Scalars['Float']['input']>;
  currentWeightKilograms?: InputMaybe<Scalars['Float']['input']>;
  currentHeightCentimeters?: InputMaybe<Scalars['Float']['input']>;
  usdaNumber?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  breed?: InputMaybe<Scalars['String']['input']>;
  selexBreedID?: InputMaybe<Scalars['Int']['input']>;
  motherDcID?: InputMaybe<Scalars['Int']['input']>;
  motherSelexID?: InputMaybe<Scalars['String']['input']>;
  motherName?: InputMaybe<Scalars['String']['input']>;
  motherUsdaNumber?: InputMaybe<Scalars['String']['input']>;
  fatherDcID?: InputMaybe<Scalars['Int']['input']>;
  fatherSelexID?: InputMaybe<Scalars['String']['input']>;
  fatherName?: InputMaybe<Scalars['String']['input']>;
  fatherUsdaNumber?: InputMaybe<Scalars['String']['input']>;
  compNumber?: InputMaybe<Scalars['String']['input']>;
  numberOnPreviousFarm?: InputMaybe<Scalars['String']['input']>;
  retirementReason?: InputMaybe<CowRetirementReason>;
  retiredAt?: InputMaybe<Scalars['DateTime']['input']>;
  dcID?: InputMaybe<Scalars['Int']['input']>;
  selexID?: InputMaybe<Scalars['String']['input']>;
  herdEntryDate?: InputMaybe<Scalars['DateTime']['input']>;
  lamenessScore?: InputMaybe<Scalars['Int']['input']>;
  nipplesCount?: InputMaybe<Scalars['Int']['input']>;
  electronicTagNumber?: InputMaybe<Scalars['String']['input']>;
  transponderNumber?: InputMaybe<Scalars['String']['input']>;
  bloodProteinTestValue?: InputMaybe<Scalars['Float']['input']>;
  bloodProteinTestHappenedAt?: InputMaybe<Scalars['DateTime']['input']>;
  herriotCode?: InputMaybe<Scalars['String']['input']>;
  herriotCodeSetAt?: InputMaybe<Scalars['DateTime']['input']>;
  collarNumber?: InputMaybe<Scalars['String']['input']>;
  earTagNumber?: InputMaybe<Scalars['String']['input']>;
  penGroupID?: InputMaybe<Scalars['ID']['input']>;
  motherID?: InputMaybe<Scalars['ID']['input']>;
  fatherID?: InputMaybe<Scalars['ID']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
};

export type CustomReportChart = CustomReportChartInterface & {
  __typename?: 'CustomReportChart';
  xAxisLabels: Array<Maybe<CustomReportBlueprintSourceFieldValue>>;
  yAxisDatasets: Array<Array<CustomReportChartValue>>;
};

export type SetReproductionHdrAndPrReportSettingsInput = {
  farmID?: InputMaybe<Scalars['ID']['input']>;
  period: DatePeriodInput;
  femaleAnimalKind?: FemaleAnimalKind;
  xAxisMode?: ReproductionHdrAndPrXAxisMode;
  yAxisMode?: ReproductionYAxisMode;
  yAxisMetrics: Array<ReproductionHdrAndPrYAxisMetric>;
  yAxisShouldCompareToPreviousPeriod?: Scalars['Boolean']['input'];
  yAxisCompareToPreviousPeriodMode?: InputMaybe<CompareToPreviousPeriodMode>;
  yAxisCompareToPreviousPeriodChartKind?: InputMaybe<ReproductionCrReportChartKind>;
  lactationGroupNumbers?: InputMaybe<Array<LactationGroupNumber>>;
  cowIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  voluntaryWaitingPeriodDays: Scalars['Int']['input'];
};

export type BlueprintLaunchConnection = {
  __typename?: 'BlueprintLaunchConnection';
  pageInfo: PageInfo;
  edges: Array<BlueprintLaunchEdge>;
  nodes: Array<BlueprintLaunch>;
};

export type CustomReportSettings = {
  __typename?: 'CustomReportSettings';
  rows: Array<CustomReportSettingsRow>;
  columns: Array<CustomReportSettingsColumn>;
  values: Array<CustomReportSettingsValue>;
};

export type BlueprintLaunchInputValueInput = {
  intValue?: InputMaybe<Scalars['Int']['input']>;
  floatValue?: InputMaybe<Scalars['Float']['input']>;
  datetimeValue?: InputMaybe<Scalars['DateTime']['input']>;
  idValue?: InputMaybe<Scalars['ID']['input']>;
  idsValue?: InputMaybe<Array<Scalars['ID']['input']>>;
  strValue?: InputMaybe<Scalars['String']['input']>;
  jsonValue?: InputMaybe<Scalars['JSON']['input']>;
};

export type CustomReportChartYAxisInput = {
  valueKey?: InputMaybe<CustomReportSettingsValueInput>;
  blueprintSourceFieldValue?: InputMaybe<CustomReportBlueprintSourceFieldValueInput>;
  aggFormula?: InputMaybe<CustomReportChartAggFormulaKindEnum>;
  withRightScale?: Scalars['Boolean']['input'];
};

export type CreateMonitorEntryInput = {
  target?: InputMaybe<Scalars['Float']['input']>;
  calculationMethod: CalculationMethodEnum;
  name: Scalars['String']['input'];
  leftBlueprintID: Scalars['ID']['input'];
  leftBlueprintSourceFieldID?: InputMaybe<Scalars['ID']['input']>;
  rightBlueprintID?: InputMaybe<Scalars['ID']['input']>;
  monitorGroupID: Scalars['ID']['input'];
};

export type LivestockCowsLactation = {
  __typename?: 'LivestockCowsLactation';
  dry: Scalars['Int']['output'];
  milking: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type MilkingParlorMeterFunctionReportToleranceRowEntry = MilkingParlorMeterFunctionReportEntry & {
  __typename?: 'MilkingParlorMeterFunctionReportToleranceRowEntry';
  cowsCount?: Maybe<Scalars['Int']['output']>;
  milkDeviationFromExpectedKilograms?: Maybe<Scalars['Float']['output']>;
  milkWeightKilograms?: Maybe<Scalars['Float']['output']>;
  durationSeconds?: Maybe<Scalars['Float']['output']>;
  flowRate?: Maybe<Scalars['Float']['output']>;
  conductivity?: Maybe<Scalars['Float']['output']>;
  avgPeakFlowRate?: Maybe<Scalars['Float']['output']>;
  fallCount?: Maybe<Scalars['Int']['output']>;
  manualModeCount?: Maybe<Scalars['Int']['output']>;
  manualDetachCount?: Maybe<Scalars['Int']['output']>;
};

export type CreateCalvingActionCalfInputGql = {
  __typename?: 'CreateCalvingActionCalfInputGQL';
  state: CalfLifeState;
  identifier: Scalars['Identifier']['output'];
  isBull?: Maybe<Scalars['Boolean']['output']>;
  penGroupID?: Maybe<Scalars['ID']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  herriotCode: Scalars['String']['output'];
};

export type UpdateSourceFieldInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CustomMilkingReportSettingsActualFilter = {
  __typename?: 'CustomMilkingReportSettingsActualFilter';
  value?: Maybe<SourceFieldValue>;
  masterBlueprintSourceField?: Maybe<SourceField>;
};

export enum ExportJobStatus {
  New = 'NEW',
  Doing = 'DOING',
  Done = 'DONE'
}

export type CustomReportPivotTable = CustomReportPivotTableInterface & {
  __typename?: 'CustomReportPivotTable';
  columns: Array<CustomReportColumnInterface>;
  rows: Array<CustomReportRowInterface>;
};

export type InjectionsManufacturer = {
  __typename?: 'InjectionsManufacturer';
  id: Scalars['ID']['output'];
  company: Company;
  name: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
};

export type InseminationEdge = {
  __typename?: 'InseminationEdge';
  node: Insemination;
  cursor: Scalars['String']['output'];
};

export type ReproductionCrReportSettings = {
  __typename?: 'ReproductionCrReportSettings';
  since: Scalars['Date']['output'];
  till: Scalars['Date']['output'];
  femaleAnimalKind: FemaleAnimalKind;
  xAxisMode: ConceptionRateParameterEnum;
  xAxisStep?: Maybe<ReproductionCrDateStep>;
  xAxisShouldShowOther: Scalars['Boolean']['output'];
  xAxisOtherFromTotalThresholdPercent?: Maybe<Scalars['Int']['output']>;
  yAxisMode: ReproductionYAxisMode;
  yAxisShouldCompareToPreviousPeriod: Scalars['Boolean']['output'];
  yAxisCompareToPreviousPeriodMode?: Maybe<CompareToPreviousPeriodMode>;
  yAxisCompareToPreviousPeriodChartKind?: Maybe<ReproductionCrReportChartKind>;
  lactationGroupNumbers?: Maybe<Array<LactationGroupNumber>>;
  groupBy?: Maybe<ConceptionRateParameterEnum>;
  groupByChartKind?: Maybe<ReproductionCrReportChartKind>;
  groupByStep?: Maybe<ReproductionCrDateStep>;
  farm?: Maybe<Farm>;
  cows?: Maybe<Array<Cow>>;
  xAxisValues?: Maybe<Array<ReproductionCrValue>>;
  groupByValues?: Maybe<Array<ReproductionCrValue>>;
};

export type ReproductionHdrAndPrCalculatedReport = ReproductionHdrAndPrCalculatedReportInterface & {
  __typename?: 'ReproductionHdrAndPrCalculatedReport';
  cacheCreatedAt: Scalars['DateTime']['output'];
  rows: Array<ReproductionHdrAndPrRow>;
  total?: Maybe<ReproductionHdrAndPrRow>;
};

export type UpdateInseminationInput = {
  happenedAt?: InputMaybe<Scalars['DateTime']['input']>;
  movedToGroupWithBullAt?: InputMaybe<Scalars['DateTime']['input']>;
  result?: InputMaybe<InseminationResult>;
  resultAt?: InputMaybe<Scalars['DateTime']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  cowID?: InputMaybe<Scalars['ID']['input']>;
  bullID?: InputMaybe<Scalars['ID']['input']>;
  semenDoseID?: InputMaybe<Scalars['ID']['input']>;
  schemeID?: InputMaybe<Scalars['ID']['input']>;
  employeeID?: InputMaybe<Scalars['ID']['input']>;
};

export type TestMilkingConnection = {
  __typename?: 'TestMilkingConnection';
  pageInfo: PageInfo;
  edges: Array<TestMilkingEdge>;
  nodes: Array<TestMilking>;
};

export enum ParlorExportStatusFileTagFieldEnum {
  Trans = 'TRANS',
  Eid = 'EID'
}

export type CustomMilkingReportDatasetHistoricPenGroup = CustomMilkingReportDatasetInterface & {
  __typename?: 'CustomMilkingReportDatasetHistoricPenGroup';
  field: CustomMilkingReportYAxisField;
  penGroup?: Maybe<PenGroup>;
};

export type ReproductionCrRowsByIntervalBetweenInseminations = ReproductionCrDetailedRowsByEntity & {
  __typename?: 'ReproductionCrRowsByIntervalBetweenInseminations';
  hashID: Scalars['String']['output'];
  total?: Maybe<ReproductionCrRowsRow>;
  other?: Maybe<ReproductionCrRowsRow>;
  rows: Array<ReproductionCrRowsRow>;
  rowNames: Array<Maybe<ReproductionCrIntervalBetweenInseminationsValue>>;
};

export type MilkingParlorInterval = {
  __typename?: 'MilkingParlorInterval';
  id: Scalars['ID']['output'];
  milkingParlor: MilkingParlor;
  penGroups: MilkingParlorIntervalPenGroupsConnection;
  intervalStart: Scalars['Time']['output'];
  intervalEnd: Scalars['Time']['output'];
};

export type CustomReportEdge = {
  __typename?: 'CustomReportEdge';
  node: CustomReport;
  cursor: Scalars['String']['output'];
};

export type SourceFieldValue = Bull | Calving | Cow | Disease | Employee | Farm | Injection | Insemination | InseminationScheme | PenGroup | Protocol | SemenDose | Operation | StrHardcodedValue | IntHardcodedValue | FloatHardcodedValue | DatetimeHardcodedValue | DateHardcodedValue;

export type ReproductionHdrAndPrCalculatedReportComparison = ReproductionHdrAndPrCalculatedReportInterface & {
  __typename?: 'ReproductionHdrAndPrCalculatedReportComparison';
  cacheCreatedAt: Scalars['DateTime']['output'];
  actual: ReproductionHdrAndPrCalculatedReport;
  historic: ReproductionHdrAndPrCalculatedReport;
  historicPeriod: DatePeriod;
};

export enum CowsCopyReasonEnum {
  Sell = 'SELL',
  Move = 'MOVE'
}

export type CalendarDivider = {
  __typename?: 'CalendarDivider';
  date: Scalars['DateTime']['output'];
};

export type CustomReportSettingsColumnInput = {
  order: CustomReportOrderEnum;
  sortBy: CustomReportSettingsSortByInput;
  withTotal: Scalars['Boolean']['input'];
  blueprintSourceFieldID: Scalars['ID']['input'];
};

export type ParlorExportFilesInput = {
  kinds: Array<ParlorExportFileKindEnum>;
  farmID: Scalars['ID']['input'];
  companyID: Scalars['ID']['input'];
  onTime?: Scalars['DateTime']['input'];
  since?: InputMaybe<Scalars['DateTime']['input']>;
  onlyCows?: Scalars['Boolean']['input'];
  onlyHeifers?: Scalars['Boolean']['input'];
  onlyScheduled?: Scalars['Boolean']['input'];
  milkingParlorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MonitorGroup = {
  __typename?: 'MonitorGroup';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type IntegratorEdge = {
  __typename?: 'IntegratorEdge';
  node: Integrator;
  cursor: Scalars['String']['output'];
};

export type CustomMilkingReportDataset = CustomMilkingReportDatasetInterface & {
  __typename?: 'CustomMilkingReportDataset';
  field: CustomMilkingReportYAxisField;
};

export type ExportJobConnection = {
  __typename?: 'ExportJobConnection';
  pageInfo: PageInfo;
  edges: Array<ExportJobEdge>;
  nodes: Array<ExportJob>;
};

export type CreateBlueprintUnionInput = {
  blueprintID: Scalars['ID']['input'];
  name?: Scalars['String']['input'];
};

export type CreateEmployeeInput = {
  number?: Scalars['String']['input'];
  firstName?: Scalars['String']['input'];
  middleName?: Scalars['String']['input'];
  lastName?: Scalars['String']['input'];
  comment?: Scalars['String']['input'];
  companyID?: InputMaybe<Scalars['ID']['input']>;
};

export enum ValueKindEnum {
  Date = 'DATE',
  Index = 'INDEX',
  LactationIndex = 'LACTATION_INDEX',
  LactationState = 'LACTATION_STATE',
  InjectionId = 'INJECTION_ID',
  InseminationId = 'INSEMINATION_ID',
  ProtocolId = 'PROTOCOL_ID',
  EventId = 'EVENT_ID',
  EventIds = 'EVENT_IDS',
  CowId = 'COW_ID',
  CalvingId = 'CALVING_ID',
  BullId = 'BULL_ID',
  CowIds = 'COW_IDS',
  DiseaseId = 'DISEASE_ID',
  Int = 'INT',
  Float = 'FLOAT',
  Numeric = 'NUMERIC',
  Text = 'TEXT',
  PenGroupId = 'PEN_GROUP_ID',
  Dow = 'DOW',
  CowState = 'COW_STATE',
  CowRetirementReason = 'COW_RETIREMENT_REASON',
  BullRetirementReason = 'BULL_RETIREMENT_REASON',
  FarmId = 'FARM_ID',
  EmployeeId = 'EMPLOYEE_ID',
  Void = 'VOID',
  Bool = 'BOOL',
  OperationId = 'OPERATION_ID',
  SemenDoseBatchId = 'SEMEN_DOSE_BATCH_ID',
  InseminationSchemeId = 'INSEMINATION_SCHEME_ID',
  Limb = 'LIMB'
}

export enum ViewKindEnum {
  Table = 'TABLE',
  Graph = 'GRAPH',
  Action = 'ACTION'
}

export type CowConnection = {
  __typename?: 'CowConnection';
  pageInfo: PageInfo;
  edges: Array<CowEdge>;
  nodes: Array<Cow>;
};

export type ExpectedRetiredCount = {
  __typename?: 'ExpectedRetiredCount';
  firstLact: Scalars['Int']['output'];
  otherLact: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CreateCustomReportChartSettingsInput = {
  name: Scalars['String']['input'];
  customReportID: Scalars['ID']['input'];
  kind: CustomReportChartKindEnum;
  xAxis?: InputMaybe<CustomReportChartXAxisInput>;
  yAxes: Array<CustomReportChartYAxisInput>;
};

export enum EventsSort {
  KindAsc = 'KIND_ASC',
  KindDesc = 'KIND_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type DairyPlanMilkImportFileInput = {
  file: Scalars['Upload']['input'];
  farmID: Scalars['ID']['input'];
  companyID?: InputMaybe<Scalars['ID']['input']>;
  milkingParlorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type BlueprintLaunchGroupedRow = {
  __typename?: 'BlueprintLaunchGroupedRow';
  groupKey: Scalars['String']['output'];
  groupValue: Scalars['JSON']['output'];
  rows: Array<BlueprintLaunchRow>;
  rowsCount: Scalars['Int']['output'];
};

export enum HerriotIntegrationEntityKindEnum {
  KeepingPurpose = 'KEEPING_PURPOSE',
  KeepingType = 'KEEPING_TYPE',
  MarkingAttachmentLocation = 'MARKING_ATTACHMENT_LOCATION'
}

export type CustomMilkingReportDatasetHistoricLactationGroupNumber = CustomMilkingReportDatasetInterface & {
  __typename?: 'CustomMilkingReportDatasetHistoricLactationGroupNumber';
  field: CustomMilkingReportYAxisField;
  lactationGroupNumber?: Maybe<LactationGroupNumber>;
};

export enum ReproductionCrDateStep {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH'
}

export type ReproductionCrCalculatedReport = ReproductionCrCalculatedReportInterface & {
  __typename?: 'ReproductionCrCalculatedReport';
  cacheCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  xAxisValues: Array<ReproductionCrValue>;
  groupByValues: Array<ReproductionCrValue>;
  detailedRows: ReproductionCrDetailedRowsByEntity;
};

export type CustomReportPivotTableEmpty = CustomReportPivotTableInterface & {
  __typename?: 'CustomReportPivotTableEmpty';
  columns: Array<CustomReportColumnInterface>;
  rows: Array<CustomReportRowInterface>;
};

export type ReproductionCrValueInput = {
  date?: InputMaybe<ReproductionRowByDateRowNameInput>;
  bullID?: InputMaybe<Scalars['ID']['input']>;
  employeeID?: InputMaybe<Scalars['ID']['input']>;
  inseminationSchemeID?: InputMaybe<Scalars['ID']['input']>;
  inseminationNumber?: InputMaybe<Scalars['Int']['input']>;
  semenSupplierName?: InputMaybe<Scalars['String']['input']>;
  intervalBetweenInseminations?: InputMaybe<ReproductionCrIntervalBetweenInseminationsValueInput>;
  dows?: InputMaybe<Dow>;
  animalCycle?: InputMaybe<ReproductionCrRowByAnimalCycleValueInput>;
  bullBreed?: InputMaybe<Scalars['String']['input']>;
};

export type CustomReportSettingsRow = {
  __typename?: 'CustomReportSettingsRow';
  blueprintSourceField: SourceField;
  order: CustomReportOrderEnum;
  sortBy: CustomReportSettingsSortBy;
  withTotal: Scalars['Boolean']['output'];
};

export enum ReproductionYAxisMode {
  ClosestToMax = 'CLOSEST_TO_MAX',
  Percent_100 = 'PERCENT_100'
}

export enum CalfLifeState {
  Alive = 'ALIVE',
  Dead = 'DEAD'
}

export type CreatePenGroupInput = {
  comment?: Scalars['String']['input'];
  name: Scalars['String']['input'];
  identifier: Scalars['Identifier']['input'];
  isMilking: Scalars['Boolean']['input'];
  isDry: Scalars['Boolean']['input'];
  isHospital: Scalars['Boolean']['input'];
  isCalf: Scalars['Boolean']['input'];
  isArtificialInsemination: Scalars['Boolean']['input'];
  isWithBull: Scalars['Boolean']['input'];
  isUser: Scalars['Boolean']['input'];
  capacity?: InputMaybe<Scalars['Int']['input']>;
  dcID?: InputMaybe<Scalars['Int']['input']>;
  selexID?: InputMaybe<Scalars['String']['input']>;
  farmID: Scalars['ID']['input'];
};

export type PenGroupConnection = {
  __typename?: 'PenGroupConnection';
  pageInfo: PageInfo;
  edges: Array<PenGroupEdge>;
  nodes: Array<PenGroup>;
};

export type ReproductionHdrAndPrReportConnection = {
  __typename?: 'ReproductionHdrAndPrReportConnection';
  pageInfo: PageInfo;
  edges: Array<ReproductionHdrAndPrReportEdge>;
  nodes: Array<ReproductionHdrAndPrReport>;
};

export type SomaticCellsReportSettingsHistoricValueLactationNumbers = {
  __typename?: 'SomaticCellsReportSettingsHistoricValueLactationNumbers';
  lactationNumbers?: Maybe<Array<Scalars['Int']['output']>>;
};

export type CompanyUserConnection = {
  __typename?: 'CompanyUserConnection';
  edges: Array<CompanyUserEdge>;
  nodes: Array<User>;
  pageInfo: BeriaPageInfo;
};

export enum BullRetirementReason {
  RespiratoryDisease = 'RESPIRATORY_DISEASE',
  BirthTrauma = 'BIRTH_TRAUMA',
  LimbsDisease = 'LIMBS_DISEASE',
  LimbsTrauma = 'LIMBS_TRAUMA',
  InternalOrgansDisease = 'INTERNAL_ORGANS_DISEASE',
  Reproduction = 'REPRODUCTION',
  GastrointestinalDisease = 'GASTROINTESTINAL_DISEASE',
  ZoologicalDefect = 'ZOOLOGICAL_DEFECT',
  Other = 'OTHER'
}

export type CowEdge = {
  __typename?: 'CowEdge';
  node: Cow;
  cursor: Scalars['String']['output'];
};

export type SourceField = {
  __typename?: 'SourceField';
  id: Scalars['ID']['output'];
  verboseName: Scalars['String']['output'];
  returnValueKind: ValueKindEnum;
  company: Company;
  arguments: Array<WritableArgument>;
  kind: SourceFieldKindEnum;
  name: Scalars['String']['output'];
};

export type LivestockForecastMonth = {
  __typename?: 'LivestockForecastMonth';
  farmIDs: Array<Scalars['ID']['output']>;
  company: Company;
  livestockCows: LivestockCows;
  livestockHeifers: LivestockLiveMonthsPeriodsPlusTotal;
  livestockBulls: LivestockLiveMonthsPeriodsPlusTotal;
  calvings: LivestockCalvings;
  expectedDry: LivestockLactationPeriodsTotal;
  expectedRetired: LivestockExpectedRetired;
  milk: LivestockLactationPeriodsTotalMilk;
  forecastAt: Scalars['Date']['output'];
  livestockTotal: Scalars['Int']['output'];
  milkPerHead: Scalars['Int']['output'];
};

export enum CustomReportValueViewKindEnum {
  Default = 'DEFAULT',
  PercentByRow = 'PERCENT_BY_ROW',
  PercentByColumn = 'PERCENT_BY_COLUMN',
  PercentByTotal = 'PERCENT_BY_TOTAL'
}

export type ReproductionCrIntervalBetweenInseminationsValue = {
  __typename?: 'ReproductionCrIntervalBetweenInseminationsValue';
  periodStart?: Maybe<Scalars['Int']['output']>;
  periodEnd?: Maybe<Scalars['Int']['output']>;
};

export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  node: Company;
  cursor: Scalars['String']['output'];
};

export type UpdateBlueprintUnionInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type DairyPlanCowStatusExportFileInput = {
  farmID: Scalars['ID']['input'];
  companyID?: InputMaybe<Scalars['ID']['input']>;
};

export type CustomMilkingReportSettingsGroupingInput = {
  kind?: InputMaybe<MilkingHistoricFilterKind>;
  masterBlueprintSourceFieldID?: InputMaybe<Scalars['ID']['input']>;
};

export type SourceFieldEdge = {
  __typename?: 'SourceFieldEdge';
  node: SourceField;
  cursor: Scalars['String']['output'];
};

export type CowMilkParlorStatsOnDate = {
  __typename?: 'CowMilkParlorStatsOnDate';
  date: Scalars['Date']['output'];
  weightKilograms?: Maybe<Scalars['Float']['output']>;
  kind: MilkParlorStatsDateKind;
};

export type LivestockForecastPeriod = {
  __typename?: 'LivestockForecastPeriod';
  farmIDs: Array<Scalars['ID']['output']>;
  company: Company;
  cowsStatusCount: CowsStatusCount;
  calvingCount: CalvingCount;
  expectedRetiredCount: ExpectedRetiredCount;
  cowsMilking: CowsMilking;
  since: Scalars['Date']['output'];
  till: Scalars['Date']['output'];
  dryStartCount: Scalars['Int']['output'];
  expectedNewbornHeifersCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ParlorStallEntryMistakeType = {
  __typename?: 'ParlorStallEntryMistakeType';
  kind: MilkingMistakeKindsEnum;
  happenedAt: Scalars['DateTime']['output'];
  durationSec: Scalars['Int']['output'];
  endedAt: Scalars['DateTime']['output'];
};

export type EmployeeTypeEdge = {
  __typename?: 'EmployeeTypeEdge';
  node: Employee;
  cursor: Scalars['String']['output'];
};

export type BlueprintUnion = {
  __typename?: 'BlueprintUnion';
  id: Scalars['ID']['output'];
  company: Company;
  filterGroup: BlueprintFilterGroup;
  name: Scalars['String']['output'];
};

export type UpdateInjectionInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  daysMilkWithholdAfterLastThreatment?: InputMaybe<Scalars['Int']['input']>;
  daysMeatWithholdAfterLastThreatment?: InputMaybe<Scalars['Int']['input']>;
  cost?: InputMaybe<Scalars['Float']['input']>;
  supplier?: InputMaybe<Scalars['String']['input']>;
  injectionsManufacturerID?: InputMaybe<Scalars['ID']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
};

export type CalculateCustomReportChartSettingsInput = {
  kind: CustomReportChartKindEnum;
  xAxis?: InputMaybe<CustomReportChartXAxisInput>;
  yAxes: Array<CustomReportChartYAxisInput>;
};

export type SetSomaticCellsReportSettingsInput = {
  blueprintID?: InputMaybe<Scalars['ID']['input']>;
  since: Scalars['Date']['input'];
  till: Scalars['Date']['input'];
  sccThousandsPerMl?: InputMaybe<Scalars['Float']['input']>;
  filters: Array<SomaticCellsReportSettingsHistoricFilterInput>;
};

export type UpdateCompanyUserInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  farmIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  blueprintRoleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type CowsCopyKey = {
  __typename?: 'CowsCopyKey';
  creator: User;
  cows: Array<Cow>;
  operations: Array<Operation>;
  diseases: Array<Disease>;
  inseminationBulls: Array<Bull>;
  company: Company;
  toCompany?: Maybe<Company>;
  key: Scalars['String']['output'];
  reason: CowsCopyReasonEnum;
  departDate: Scalars['Date']['output'];
  arriveDate?: Maybe<Scalars['Date']['output']>;
  activatedAt?: Maybe<Scalars['DateTime']['output']>;
  toCompanyInn?: Maybe<Scalars['String']['output']>;
  toCompanyName?: Maybe<Scalars['String']['output']>;
};

export type CowField = {
  valueKind: ValueKindEnum;
  value?: Maybe<BlueprintValue>;
  isPinned: Scalars['Boolean']['output'];
  verboseName: Scalars['String']['output'];
  kind: SourceFieldKindEnum;
  section: SourceSectionType;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  node: User;
  cursor: Scalars['String']['output'];
};

export type ReproductionCrIntervalBetweenInseminationsValueInput = {
  periodStart: Scalars['Int']['input'];
  periodEnd?: InputMaybe<Scalars['Int']['input']>;
};

export enum DefaultEventEnum {
  Died = 'DIED',
  Dry = 'DRY',
  Fresh = 'FRESH',
  Ok = 'OK',
  Reck = 'RECK',
  Heat = 'HEAT',
  Bred = 'BRED',
  Preg = 'PREG',
  Open = 'OPEN',
  Prev = 'PREV',
  Move = 'MOVE',
  Bullpen = 'BULLPEN',
  Abort = 'ABORT',
  Dnb = 'DNB',
  Sold = 'SOLD',
  Moved = 'MOVED',
  Check = 'CHECK',
  Calfvac = 'CALFVAC',
  Xid = 'XID',
  Culture = 'CULTURE',
  Footrim = 'FOOTRIM',
  Gnrh = 'GNRH',
  Hosp = 'HOSP',
  Ovsynch = 'OVSYNCH',
  Prost = 'PROST',
  Toclose = 'TOCLOSE',
  Vacc = 'VACC',
  Well = 'WELL',
  Illmisc = 'ILLMISC',
  Weight = 'WEIGHT',
  Height = 'HEIGHT',
  Bcs = 'BCS',
  Enter = 'ENTER'
}

export type MilkingParlorFlowRateReportGroupedRowEntry = MilkingParlorFlowRateReportEntry & {
  __typename?: 'MilkingParlorFlowRateReportGroupedRowEntry';
  cowStallPerHour?: Maybe<Scalars['Float']['output']>;
  flowRate0To15Sec?: Maybe<Scalars['Float']['output']>;
  flowRate15To30Sec?: Maybe<Scalars['Float']['output']>;
  flowRate30To60Sec?: Maybe<Scalars['Float']['output']>;
  peakFlowRate?: Maybe<Scalars['Float']['output']>;
  milkWeight0To120Sec?: Maybe<Scalars['Float']['output']>;
  milkWeightPercent0To120Sec?: Maybe<Scalars['Float']['output']>;
};

export type UpdateReproductionHdrAndPrReportInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  roleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type CreateUserInput = {
  firstName?: Scalars['String']['input'];
  lastName?: Scalars['String']['input'];
  middleName?: Scalars['String']['input'];
  email: Scalars['Email']['input'];
  companyIDs?: Array<Scalars['ID']['input']>;
  farmIDs?: Array<Scalars['ID']['input']>;
};

export type Operation = Event & {
  __typename?: 'Operation';
  name: Scalars['String']['output'];
  kind: EventKindEnum;
  id: Scalars['ID']['output'];
  company: Company;
  isDefault: Scalars['Boolean']['output'];
};

export type Insemination = {
  __typename?: 'Insemination';
  id: Scalars['ID']['output'];
  company: Company;
  cow: Cow;
  semenDose?: Maybe<SemenDose>;
  bull: Bull;
  employee?: Maybe<Employee>;
  scheme?: Maybe<InseminationScheme>;
  daysInMilk: Scalars['Int']['output'];
  happenedAt: Scalars['DateTime']['output'];
  movedToGroupWithBullAt?: Maybe<Scalars['DateTime']['output']>;
  result: InseminationResult;
  resultAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ReproductionHdrAndPrCalculatedReportInterface = {
  cacheCreatedAt: Scalars['DateTime']['output'];
};

export type ReproductionMetric = {
  __typename?: 'ReproductionMetric';
  targetPercent?: Maybe<Scalars['Int']['output']>;
  valuePercent?: Maybe<Scalars['Int']['output']>;
  deltaPercent?: Maybe<Scalars['Int']['output']>;
  deviationThresholdPercent?: Maybe<Scalars['Int']['output']>;
  calculatedOn: Scalars['Date']['output'];
};

export type SetArgumentInput = {
  valueKind: NodeValueKindEnum;
  value: ArgumentValueInput;
};

export type BlueprintViewTableSettingsInput = {
  fieldIDs: Array<Scalars['ID']['input']>;
  groupByFieldIDs?: Array<Scalars['ID']['input']>;
};

export type ReproductionSettings = {
  __typename?: 'ReproductionSettings';
  cowsVoluntaryWaitingPeriodDays: Scalars['Int']['output'];
  heifersVoluntaryWaitingPeriodDays: Scalars['Int']['output'];
};

export enum SourceFieldKindEnum {
  AgeOnDate = 'AGE_ON_DATE',
  AgeOnDateInYearsAndMonths = 'AGE_ON_DATE_IN_YEARS_AND_MONTHS',
  AnimalGender = 'ANIMAL_GENDER',
  BullBreed = 'BULL_BREED',
  BullCalvingId = 'BULL_CALVING_ID',
  BullCategory = 'BULL_CATEGORY',
  BullComment = 'BULL_COMMENT',
  BullDateOfBirth = 'BULL_DATE_OF_BIRTH',
  BullDcId = 'BULL_DC_ID',
  BullFarmId = 'BULL_FARM_ID',
  BullFatherDcId = 'BULL_FATHER_DC_ID',
  BullFatherId = 'BULL_FATHER_ID',
  BullFatherName = 'BULL_FATHER_NAME',
  BullFatherSelexId = 'BULL_FATHER_SELEX_ID',
  BullFatherUsdaNumber = 'BULL_FATHER_USDA_NUMBER',
  BullId = 'BULL_ID',
  BullIdentifier = 'BULL_IDENTIFIER',
  BullMotherDcId = 'BULL_MOTHER_DC_ID',
  BullMotherId = 'BULL_MOTHER_ID',
  BullMotherName = 'BULL_MOTHER_NAME',
  BullMotherSelexId = 'BULL_MOTHER_SELEX_ID',
  BullMotherUsdaNumber = 'BULL_MOTHER_USDA_NUMBER',
  BullName = 'BULL_NAME',
  BullNumberOnPreviousFarm = 'BULL_NUMBER_ON_PREVIOUS_FARM',
  BullPenGroupId = 'BULL_PEN_GROUP_ID',
  BullRegistrationNumber = 'BULL_REGISTRATION_NUMBER',
  BullRetiredAt = 'BULL_RETIRED_AT',
  BullRetirementReason = 'BULL_RETIREMENT_REASON',
  BullSelexId = 'BULL_SELEX_ID',
  BullState = 'BULL_STATE',
  BullStudCode = 'BULL_STUD_CODE',
  BullUsdaNumber = 'BULL_USDA_NUMBER',
  CalfGender = 'CALF_GENDER',
  CalfIdentifier = 'CALF_IDENTIFIER',
  CalfStatus = 'CALF_STATUS',
  CalvingComment = 'CALVING_COMMENT',
  CalvingCowId = 'CALVING_COW_ID',
  CalvingDefaultEventId = 'CALVING_DEFAULT_EVENT_ID',
  CalvingDifficultyScore = 'CALVING_DIFFICULTY_SCORE',
  CalvingEmployeeId = 'CALVING_EMPLOYEE_ID',
  CalvingHappenedAt = 'CALVING_HAPPENED_AT',
  CalvingId = 'CALVING_ID',
  CalvingIsAbortion = 'CALVING_IS_ABORTION',
  CeilNumeric = 'CEIL_NUMERIC',
  Comment = 'COMMENT',
  CommentOfBcs = 'COMMENT_OF_BCS',
  CommentOfEvent = 'COMMENT_OF_EVENT',
  CommentOfWeighing = 'COMMENT_OF_WEIGHING',
  CountEvents = 'COUNT_EVENTS',
  CowBirthWeightKilograms = 'COW_BIRTH_WEIGHT_KILOGRAMS',
  CowBloodProteinTestHappenedAt = 'COW_BLOOD_PROTEIN_TEST_HAPPENED_AT',
  CowBloodProteinTestValue = 'COW_BLOOD_PROTEIN_TEST_VALUE',
  CowBreed = 'COW_BREED',
  CowCalvingId = 'COW_CALVING_ID',
  CowCollarNumber = 'COW_COLLAR_NUMBER',
  CowComment = 'COW_COMMENT',
  CowCompNumber = 'COW_COMP_NUMBER',
  CowCurrentHeightCentimeters = 'COW_CURRENT_HEIGHT_CENTIMETERS',
  CowCurrentWeightKilograms = 'COW_CURRENT_WEIGHT_KILOGRAMS',
  CowDateOfBirth = 'COW_DATE_OF_BIRTH',
  CowDcId = 'COW_DC_ID',
  CowEarTagNumber = 'COW_EAR_TAG_NUMBER',
  CowElectronicTagNumber = 'COW_ELECTRONIC_TAG_NUMBER',
  CowFarmId = 'COW_FARM_ID',
  CowFatherDcId = 'COW_FATHER_DC_ID',
  CowFatherId = 'COW_FATHER_ID',
  CowFatherName = 'COW_FATHER_NAME',
  CowFatherSelexId = 'COW_FATHER_SELEX_ID',
  CowFatherUsdaNumber = 'COW_FATHER_USDA_NUMBER',
  CowHerdEntryDate = 'COW_HERD_ENTRY_DATE',
  CowHerriotCode = 'COW_HERRIOT_CODE',
  CowHerriotCodeSetAt = 'COW_HERRIOT_CODE_SET_AT',
  CowId = 'COW_ID',
  CowIdentifier = 'COW_IDENTIFIER',
  CowLamenessScore = 'COW_LAMENESS_SCORE',
  CowMotherDcId = 'COW_MOTHER_DC_ID',
  CowMotherId = 'COW_MOTHER_ID',
  CowMotherName = 'COW_MOTHER_NAME',
  CowMotherSelexId = 'COW_MOTHER_SELEX_ID',
  CowMotherUsdaNumber = 'COW_MOTHER_USDA_NUMBER',
  CowName = 'COW_NAME',
  CowNipplesCount = 'COW_NIPPLES_COUNT',
  CowNumberOnPreviousFarm = 'COW_NUMBER_ON_PREVIOUS_FARM',
  CowOldIdentifier = 'COW_OLD_IDENTIFIER',
  CowPenGroupId = 'COW_PEN_GROUP_ID',
  CowPenGroupName = 'COW_PEN_GROUP_NAME',
  CowPreviousPenGroupId = 'COW_PREVIOUS_PEN_GROUP_ID',
  CowPreviousState = 'COW_PREVIOUS_STATE',
  CowRegistrationNumber = 'COW_REGISTRATION_NUMBER',
  CowRetiredAt = 'COW_RETIRED_AT',
  CowRetirementReason = 'COW_RETIREMENT_REASON',
  CowSelexBreedId = 'COW_SELEX_BREED_ID',
  CowSelexId = 'COW_SELEX_ID',
  CowState = 'COW_STATE',
  CowTransponderNumber = 'COW_TRANSPONDER_NUMBER',
  CowUsdaNumber = 'COW_USDA_NUMBER',
  CurrentLactation = 'CURRENT_LACTATION',
  CurrentProtocolsEndDate = 'CURRENT_PROTOCOLS_END_DATE',
  DateMinusDate = 'DATE_MINUS_DATE',
  DateMinusDays = 'DATE_MINUS_DAYS',
  DateNoDrugsInMeat = 'DATE_NO_DRUGS_IN_MEAT',
  DateNoDrugsInMilk = 'DATE_NO_DRUGS_IN_MILK',
  DateOfBcs = 'DATE_OF_BCS',
  DateOfEvent = 'DATE_OF_EVENT',
  DateOfInjection = 'DATE_OF_INJECTION',
  DateOfProtocol = 'DATE_OF_PROTOCOL',
  DateOfWeighing = 'DATE_OF_WEIGHING',
  DatePlusDays = 'DATE_PLUS_DAYS',
  DaysInDry = 'DAYS_IN_DRY',
  DaysInMilk = 'DAYS_IN_MILK',
  DaysInPregnancy = 'DAYS_IN_PREGNANCY',
  DefaultEventName = 'DEFAULT_EVENT_NAME',
  DiseasesCount = 'DISEASES_COUNT',
  DiseaseComment = 'DISEASE_COMMENT',
  DiseaseDate = 'DISEASE_DATE',
  DiseaseId = 'DISEASE_ID',
  DiseaseName = 'DISEASE_NAME',
  DiseaseShouldSpecifyLimb = 'DISEASE_SHOULD_SPECIFY_LIMB',
  Dow = 'DOW',
  DryDate = 'DRY_DATE',
  EmployeeComment = 'EMPLOYEE_COMMENT',
  EmployeeFirstName = 'EMPLOYEE_FIRST_NAME',
  EmployeeId = 'EMPLOYEE_ID',
  EmployeeLastName = 'EMPLOYEE_LAST_NAME',
  EmployeeMiddleName = 'EMPLOYEE_MIDDLE_NAME',
  EmployeeNumber = 'EMPLOYEE_NUMBER',
  FloatDivFloat = 'FLOAT_DIV_FLOAT',
  FloatMinusFloat = 'FLOAT_MINUS_FLOAT',
  FloatMulFloat = 'FLOAT_MUL_FLOAT',
  FloatPlusFloat = 'FLOAT_PLUS_FLOAT',
  FloorNumeric = 'FLOOR_NUMERIC',
  GreatestDate = 'GREATEST_DATE',
  IdOfEvent = 'ID_OF_EVENT',
  InjectionsCount = 'INJECTIONS_COUNT',
  InjectionComment = 'INJECTION_COMMENT',
  InjectionCost = 'INJECTION_COST',
  InjectionDaysMeatWithholdAfterLastThreatment = 'INJECTION_DAYS_MEAT_WITHHOLD_AFTER_LAST_THREATMENT',
  InjectionDaysMilkWithholdAfterLastThreatment = 'INJECTION_DAYS_MILK_WITHHOLD_AFTER_LAST_THREATMENT',
  InjectionDcId = 'INJECTION_DC_ID',
  InjectionId = 'INJECTION_ID',
  InjectionInjectionsManufacturerId = 'INJECTION_INJECTIONS_MANUFACTURER_ID',
  InjectionName = 'INJECTION_NAME',
  InjectionSelexId = 'INJECTION_SELEX_ID',
  InjectionSupplier = 'INJECTION_SUPPLIER',
  InseminationsCount = 'INSEMINATIONS_COUNT',
  InseminationBullId = 'INSEMINATION_BULL_ID',
  InseminationComment = 'INSEMINATION_COMMENT',
  InseminationCowId = 'INSEMINATION_COW_ID',
  InseminationDate = 'INSEMINATION_DATE',
  InseminationDefaultEventId = 'INSEMINATION_DEFAULT_EVENT_ID',
  InseminationEmployeeId = 'INSEMINATION_EMPLOYEE_ID',
  InseminationHappenedAt = 'INSEMINATION_HAPPENED_AT',
  InseminationId = 'INSEMINATION_ID',
  InseminationMovedToGroupWithBullAt = 'INSEMINATION_MOVED_TO_GROUP_WITH_BULL_AT',
  InseminationResult = 'INSEMINATION_RESULT',
  InseminationResultAt = 'INSEMINATION_RESULT_AT',
  InseminationSchemeCode = 'INSEMINATION_SCHEME_CODE',
  InseminationSchemeComment = 'INSEMINATION_SCHEME_COMMENT',
  InseminationSchemeDescription = 'INSEMINATION_SCHEME_DESCRIPTION',
  InseminationSchemeId = 'INSEMINATION_SCHEME_ID',
  InseminationSchemeName = 'INSEMINATION_SCHEME_NAME',
  InseminationSemenDoseId = 'INSEMINATION_SEMEN_DOSE_ID',
  IntDivInt = 'INT_DIV_INT',
  IntMinusInt = 'INT_MINUS_INT',
  IntMulInt = 'INT_MUL_INT',
  IntPlusInt = 'INT_PLUS_INT',
  IsTreatmentRequired = 'IS_TREATMENT_REQUIRED',
  LactationGroup = 'LACTATION_GROUP',
  LactationHappenedAt = 'LACTATION_HAPPENED_AT',
  LactationState = 'LACTATION_STATE',
  LastInseminationId = 'LAST_INSEMINATION_ID',
  LateDryDate = 'LATE_DRY_DATE',
  LeastDate = 'LEAST_DATE',
  LnFloat = 'LN_FLOAT',
  LnInt = 'LN_INT',
  LnNumeric = 'LN_NUMERIC',
  LogFloat = 'LOG_FLOAT',
  LogInt = 'LOG_INT',
  LogNumeric = 'LOG_NUMERIC',
  MilkingDuration = 'MILKING_DURATION',
  MilkingHappenedAt = 'MILKING_HAPPENED_AT',
  MilkingMilkingParlorPenGroupId = 'MILKING_MILKING_PARLOR_PEN_GROUP_ID',
  MilkingMilkConductivity = 'MILKING_MILK_CONDUCTIVITY',
  MilkingPenGroupId = 'MILKING_PEN_GROUP_ID',
  MilkingStallNumber = 'MILKING_STALL_NUMBER',
  MilkingWeightKilograms = 'MILKING_WEIGHT_KILOGRAMS',
  MilkAtLactation = 'MILK_AT_LACTATION',
  MilkDailyDeviationFromExpectedKg = 'MILK_DAILY_DEVIATION_FROM_EXPECTED_KG',
  MilkDailyDeviationFromExpectedPercent = 'MILK_DAILY_DEVIATION_FROM_EXPECTED_PERCENT',
  MilkDailyDeviationFromWeeklyAverageKg = 'MILK_DAILY_DEVIATION_FROM_WEEKLY_AVERAGE_KG',
  MilkDailyExpectedKg = 'MILK_DAILY_EXPECTED_KG',
  MilkDailyKg = 'MILK_DAILY_KG',
  MilkLactationKg = 'MILK_LACTATION_KG',
  MilkLactationOnDayInMilkKg = 'MILK_LACTATION_ON_DAY_IN_MILK_KG',
  MilkWeeklyAverageKg = 'MILK_WEEKLY_AVERAGE_KG',
  MilkWeeklyAverageOnDayInMilkKg = 'MILK_WEEKLY_AVERAGE_ON_DAY_IN_MILK_KG',
  MonthFromDate = 'MONTH_FROM_DATE',
  NumericDivNumeric = 'NUMERIC_DIV_NUMERIC',
  NumericMinusNumeric = 'NUMERIC_MINUS_NUMERIC',
  NumericMulNumeric = 'NUMERIC_MUL_NUMERIC',
  NumericPlusNumeric = 'NUMERIC_PLUS_NUMERIC',
  PenGroupIdentifier = 'PEN_GROUP_IDENTIFIER',
  PenGroupIsArtificialInsemination = 'PEN_GROUP_IS_ARTIFICIAL_INSEMINATION',
  PenGroupIsCalf = 'PEN_GROUP_IS_CALF',
  PenGroupIsDry = 'PEN_GROUP_IS_DRY',
  PenGroupIsHospital = 'PEN_GROUP_IS_HOSPITAL',
  PenGroupIsMilking = 'PEN_GROUP_IS_MILKING',
  PenGroupIsUser = 'PEN_GROUP_IS_USER',
  PenGroupIsWithBull = 'PEN_GROUP_IS_WITH_BULL',
  PenGroupName = 'PEN_GROUP_NAME',
  PredictedMilk_305 = 'PREDICTED_MILK_305',
  PredictedMilk_305Me = 'PREDICTED_MILK_305_ME',
  PredictedMilkOnDate = 'PREDICTED_MILK_ON_DATE',
  ProtocolsCount = 'PROTOCOLS_COUNT',
  ProtocolComment = 'PROTOCOL_COMMENT',
  ProtocolDaysUntilCheckAfterLastThreatment = 'PROTOCOL_DAYS_UNTIL_CHECK_AFTER_LAST_THREATMENT',
  ProtocolDcId = 'PROTOCOL_DC_ID',
  ProtocolExpectedDaysOnProtocol = 'PROTOCOL_EXPECTED_DAYS_ON_PROTOCOL',
  ProtocolId = 'PROTOCOL_ID',
  ProtocolName = 'PROTOCOL_NAME',
  ProtocolPenGroupId = 'PROTOCOL_PEN_GROUP_ID',
  ProtocolSelexId = 'PROTOCOL_SELEX_ID',
  ProtocolTotalTreatmentCost = 'PROTOCOL_TOTAL_TREATMENT_COST',
  RoundFloat = 'ROUND_FLOAT',
  RoundNumeric = 'ROUND_NUMERIC',
  ServicePeriodDays = 'SERVICE_PERIOD_DAYS',
  SuccessfulInseminationDate = 'SUCCESSFUL_INSEMINATION_DATE',
  SuccessfulInseminationId = 'SUCCESSFUL_INSEMINATION_ID',
  TestMilkAcetoneMmolPerL = 'TEST_MILK_ACETONE_MMOL_PER_L',
  TestMilkAvgAcetoneMmolPerL = 'TEST_MILK_AVG_ACETONE_MMOL_PER_L',
  TestMilkAvgBhbMmolPerL = 'TEST_MILK_AVG_BHB_MMOL_PER_L',
  TestMilkAvgFatPercent = 'TEST_MILK_AVG_FAT_PERCENT',
  TestMilkAvgFpd = 'TEST_MILK_AVG_FPD',
  TestMilkAvgLactosePercent = 'TEST_MILK_AVG_LACTOSE_PERCENT',
  TestMilkAvgProteinPercent = 'TEST_MILK_AVG_PROTEIN_PERCENT',
  TestMilkAvgSccThousandsPerMl = 'TEST_MILK_AVG_SCC_THOUSANDS_PER_ML',
  TestMilkAvgSolidsPercent = 'TEST_MILK_AVG_SOLIDS_PERCENT',
  TestMilkAvgUreaMgPerDl = 'TEST_MILK_AVG_UREA_MG_PER_DL',
  TestMilkBhbMmolPerL = 'TEST_MILK_BHB_MMOL_PER_L',
  TestMilkDaysInMilk = 'TEST_MILK_DAYS_IN_MILK',
  TestMilkFatPercent = 'TEST_MILK_FAT_PERCENT',
  TestMilkFpd = 'TEST_MILK_FPD',
  TestMilkHappenedAt = 'TEST_MILK_HAPPENED_AT',
  TestMilkLactosePercent = 'TEST_MILK_LACTOSE_PERCENT',
  TestMilkMaxLnScc = 'TEST_MILK_MAX_LN_SCC',
  TestMilkPeakDayInMilk = 'TEST_MILK_PEAK_DAY_IN_MILK',
  TestMilkPeakWeight = 'TEST_MILK_PEAK_WEIGHT',
  TestMilkPenGroupId = 'TEST_MILK_PEN_GROUP_ID',
  TestMilkProteinPercent = 'TEST_MILK_PROTEIN_PERCENT',
  TestMilkSccThousandsPerMl = 'TEST_MILK_SCC_THOUSANDS_PER_ML',
  TestMilkSolidsPercent = 'TEST_MILK_SOLIDS_PERCENT',
  TestMilkSumFat = 'TEST_MILK_SUM_FAT',
  TestMilkSumProtein = 'TEST_MILK_SUM_PROTEIN',
  TestMilkSumWeight = 'TEST_MILK_SUM_WEIGHT',
  TestMilkUreaMgPerDl = 'TEST_MILK_UREA_MG_PER_DL',
  TestMilkWeight = 'TEST_MILK_WEIGHT',
  Today = 'TODAY',
  UserEventComment = 'USER_EVENT_COMMENT',
  UserEventDcId = 'USER_EVENT_DC_ID',
  UserEventId = 'USER_EVENT_ID',
  UserEventName = 'USER_EVENT_NAME',
  UserEventSelexId = 'USER_EVENT_SELEX_ID',
  ValueOfBcs = 'VALUE_OF_BCS',
  WeekAvgTestMilk = 'WEEK_AVG_TEST_MILK',
  WeekSumTestMilk = 'WEEK_SUM_TEST_MILK',
  WeightGain = 'WEIGHT_GAIN',
  WeightOfWeighing = 'WEIGHT_OF_WEIGHING'
}

export type InseminationConnection = {
  __typename?: 'InseminationConnection';
  pageInfo: PageInfo;
  edges: Array<InseminationEdge>;
  nodes: Array<Insemination>;
};

export type CustomMilkingReportEdge = {
  __typename?: 'CustomMilkingReportEdge';
  node: CustomMilkingReport;
  cursor: Scalars['String']['output'];
};

export type CustomReportSettingsValueInput = {
  formula: CustomReportValueFormulaKindEnum;
  view: CustomReportValueViewKindEnum;
  blueprintSourceFieldID: Scalars['ID']['input'];
};

export type CreateCustomMilkingReportInput = {
  name: Scalars['String']['input'];
  blueprintRoleIDs?: Array<Scalars['ID']['input']>;
};

export type CowDisease = CowEvent & {
  __typename?: 'CowDisease';
  happenedAt: Scalars['DateTime']['output'];
  comment: Scalars['String']['output'];
  canRollback: Scalars['Boolean']['output'];
  cow: Cow;
  lactation?: Maybe<Lactation>;
  id: Scalars['ID']['output'];
  company: Company;
  disease: Disease;
  limb?: Maybe<LimbEnum>;
};

export enum CustomReportChartKindEnum {
  Bar = 'BAR',
  StackedBar = 'STACKED_BAR',
  PercentBar = 'PERCENT_BAR',
  Pie = 'PIE',
  Scatter = 'SCATTER',
  Line = 'LINE'
}

export enum ReproductionHdrAndPrYAxisMetric {
  Hdr = 'HDR',
  Pr = 'PR',
  Cr = 'CR',
  NotPregnant = 'NOT_PREGNANT'
}

export type SetCustomMilkingReportSettingsInput = {
  blueprintID?: InputMaybe<Scalars['ID']['input']>;
  dataSource: CustomMilkingReportDataSource;
  since: Scalars['Date']['input'];
  till: Scalars['Date']['input'];
  mode: CustomMilkingReportXAxisMode;
  modeMaxCutoffValue?: InputMaybe<Scalars['Int']['input']>;
  step?: InputMaybe<CustomMilkingReportXAxisStep>;
  shouldCompareWithHistoricData: Scalars['Boolean']['input'];
  compareWithHistoricDataPeriod?: InputMaybe<CompareToPreviousPeriodMode>;
  yAxisFields: Array<CustomMilkingReportYAxisFieldInput>;
  filters: Array<CustomMilkingReportSettingsFiltersInput>;
  grouping?: InputMaybe<CustomMilkingReportSettingsGroupingInput>;
};

export type CowEventInterfaceConnection = {
  __typename?: 'CowEventInterfaceConnection';
  pageInfo: PageInfo;
  edges: Array<CowEventInterfaceEdge>;
  nodes: Array<CowEvent>;
};

export type ProtocolScheduledOperation = {
  __typename?: 'ProtocolScheduledOperation';
  day: Scalars['Int']['output'];
  comment: Scalars['String']['output'];
  operation: Operation;
};

export type LivestockCalvings = {
  __typename?: 'LivestockCalvings';
  firstLactation: Scalars['Int']['output'];
  otherLactations: Scalars['Int']['output'];
  heifers: Scalars['Int']['output'];
  newbornHeifers: Scalars['Int']['output'];
  newbornBulls: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum CowFieldsEnum {
  PenGroupDcId = 'PEN_GROUP_DC_ID',
  TechDcId = 'TECH_DC_ID',
  OldDcId = 'OLD_DC_ID',
  DateOfBirth = 'DATE_OF_BIRTH',
  BirthWeightKilograms = 'BIRTH_WEIGHT_KILOGRAMS',
  Rc = 'RC',
  LactationNumber = 'LACTATION_NUMBER',
  Name = 'NAME',
  UsdaNumber = 'USDA_NUMBER',
  RegistrationNumber = 'REGISTRATION_NUMBER',
  Breed = 'BREED',
  MotherUsdaNumber = 'MOTHER_USDA_NUMBER',
  FatherNumber = 'FATHER_NUMBER',
  CompNumber = 'COMP_NUMBER',
  RetiredAt = 'RETIRED_AT',
  HerdEntryDate = 'HERD_ENTRY_DATE',
  LamenessScore = 'LAMENESS_SCORE',
  ElectronicTagNumber = 'ELECTRONIC_TAG_NUMBER',
  TransponderNumber = 'TRANSPONDER_NUMBER',
  LactationHappenedAt = 'LACTATION_HAPPENED_AT',
  LastInseminatedAt = 'LAST_INSEMINATED_AT',
  SuccessfullyInseminatedAt = 'SUCCESSFULLY_INSEMINATED_AT',
  DryAt = 'DRY_AT',
  Note = 'NOTE',
  Rem = 'REM',
  ArchivedAt = 'ARCHIVED_AT'
}

export type CalvingTypeEdge = {
  __typename?: 'CalvingTypeEdge';
  node: Calving;
  cursor: Scalars['String']['output'];
};

export type CustomMilkingReportConnection = {
  __typename?: 'CustomMilkingReportConnection';
  pageInfo: PageInfo;
  edges: Array<CustomMilkingReportEdge>;
  nodes: Array<CustomMilkingReport>;
};

export type CreateBlueprintCycleInputInput = {
  blueprintID: Scalars['ID']['input'];
};

export type CreateBlueprintFilterInput = {
  valueKind: NodeValueKindEnum;
  value: FilterValueInput;
  compareOperator: CompareOperator;
  blueprintSourceFieldID: Scalars['ID']['input'];
  blueprintFilterGroupID: Scalars['ID']['input'];
};

export type CustomReportChartInterface = {
  xAxisLabels: Array<Maybe<CustomReportBlueprintSourceFieldValue>>;
};

export type MonitorLaunchCalculatingValue = MonitorLaunchValue & {
  __typename?: 'MonitorLaunchCalculatingValue';
  happenedAt: Scalars['DateTime']['output'];
  monitorLaunch: MonitorLaunch;
};

export type CustomMilkingReportDatasetHistoricLactationNumber = CustomMilkingReportDatasetInterface & {
  __typename?: 'CustomMilkingReportDatasetHistoricLactationNumber';
  field: CustomMilkingReportYAxisField;
  lactationNumber?: Maybe<Scalars['Int']['output']>;
};

export enum ExportProvidersEnum {
  Selex = 'SELEX'
}

export type ProcessRawMilkingsInput = {
  farmID: Scalars['ID']['input'];
  companyID: Scalars['ID']['input'];
  milkingDates: Array<Scalars['Date']['input']>;
};

export type BodyConditionScore = {
  __typename?: 'BodyConditionScore';
  id: Scalars['ID']['output'];
  happenedAt: Scalars['DateTime']['output'];
  bodyConditionScore: Scalars['Float']['output'];
};

export type ReproductionCrRowsByStudCode = ReproductionCrDetailedRowsByEntity & {
  __typename?: 'ReproductionCrRowsByStudCode';
  hashID: Scalars['String']['output'];
  total?: Maybe<ReproductionCrRowsRow>;
  other?: Maybe<ReproductionCrRowsRow>;
  rows: Array<ReproductionCrRowsRow>;
  rowNames: Array<Maybe<SemenSupplier>>;
};

export type ReproductionHdrAndPrReportSettings = {
  __typename?: 'ReproductionHdrAndPrReportSettings';
  period: DatePeriod;
  femaleAnimalKind: FemaleAnimalKind;
  xAxisMode: ReproductionHdrAndPrXAxisMode;
  yAxisMode: ReproductionYAxisMode;
  yAxisMetrics: Array<ReproductionHdrAndPrYAxisMetric>;
  yAxisShouldCompareToPreviousPeriod: Scalars['Boolean']['output'];
  yAxisCompareToPreviousPeriodMode?: Maybe<CompareToPreviousPeriodMode>;
  yAxisCompareToPreviousPeriodChartKind?: Maybe<ReproductionCrReportChartKind>;
  lactationGroupNumbers?: Maybe<Array<LactationGroupNumber>>;
  voluntaryWaitingPeriodDays: Scalars['Int']['output'];
  farm?: Maybe<Farm>;
  cows?: Maybe<Array<Cow>>;
};

export type SomaticCellsReportSettingsHistoricValue = SomaticCellsReportSettingsHistoricValueLactationNumbers | SomaticCellsReportSettingsHistoricValueLactationGroupNumbers | SomaticCellsReportSettingsHistoricValuePenGroups;

export type CustomReportPivotTableDirty = CustomReportPivotTableInterface & {
  __typename?: 'CustomReportPivotTableDirty';
  columns: Array<CustomReportColumnInterface>;
  rows: Array<CustomReportRowInterface>;
};

export enum CustomMilkingReportDataSource {
  TestMilkings = 'TEST_MILKINGS',
  MilkingParlors = 'MILKING_PARLORS'
}

export enum CustomMilkingReportXAxisMode {
  ByDate = 'BY_DATE',
  ByDayInMilk = 'BY_DAY_IN_MILK',
  TestMilkingNumber = 'TEST_MILKING_NUMBER'
}

export type CustomMilkingReportDatasetPeriodComparison = CustomMilkingReportDatasetInterface & {
  __typename?: 'CustomMilkingReportDatasetPeriodComparison';
  field: CustomMilkingReportYAxisField;
  isActual: Scalars['Boolean']['output'];
};

export type CowFileInput = {
  happenedAt?: InputMaybe<Scalars['DateTime']['input']>;
  file: Scalars['Upload']['input'];
  farmID?: InputMaybe<Scalars['ID']['input']>;
};

export type MlkParlorGeneralReportMilk = {
  __typename?: 'MlkParlorGeneralReportMilk';
  total: Scalars['Int']['output'];
  perHour: Scalars['Int']['output'];
  perCows: Scalars['Float']['output'];
  avgFlow: Scalars['Float']['output'];
};

export type CustomReportChartFieldAxis = {
  __typename?: 'CustomReportChartFieldAxis';
  blueprintSourceField: SourceField;
};

export type CustomReportChartYAxis = CustomReportChartAggValueAxis | CustomReportChartFieldValueAxis;

export enum CustomMilkingReportYAxisFieldKind {
  MilkWeightKilogramsPerCow = 'MILK_WEIGHT_KILOGRAMS_PER_COW',
  CowsCount = 'COWS_COUNT',
  MilkWeightKilogramsPerHerd = 'MILK_WEIGHT_KILOGRAMS_PER_HERD',
  AverageDayInMilkInHerd = 'AVERAGE_DAY_IN_MILK_IN_HERD',
  FatPercent = 'FAT_PERCENT',
  ProteinPercent = 'PROTEIN_PERCENT',
  FatCorrectedMilkKilograms = 'FAT_CORRECTED_MILK_KILOGRAMS',
  SccThousands = 'SCC_THOUSANDS',
  LgSccThousands = 'LG_SCC_THOUSANDS',
  MunMlPerDl = 'MUN_ML_PER_DL'
}

export enum EventKindEnum {
  Default = 'DEFAULT',
  Protocol = 'PROTOCOL',
  Disease = 'DISEASE',
  Injection = 'INJECTION',
  User = 'USER',
  InseminationScheme = 'INSEMINATION_SCHEME'
}

export type InseminationSchemeEdge = {
  __typename?: 'InseminationSchemeEdge';
  node: InseminationScheme;
  cursor: Scalars['String']['output'];
};

export type EmployeeEdge = {
  __typename?: 'EmployeeEdge';
  node: Employee;
  cursor: Scalars['String']['output'];
};

export type MonitorLaunchErrorValue = MonitorLaunchValue & {
  __typename?: 'MonitorLaunchErrorValue';
  happenedAt: Scalars['DateTime']['output'];
  monitorLaunch: MonitorLaunch;
  message: Scalars['String']['output'];
};

export type BlueprintLaunchDataRow = {
  __typename?: 'BlueprintLaunchDataRow';
  row: Scalars['JSON']['output'];
};

export type DcImportFileInput = {
  file: Scalars['Upload']['input'];
  farmID: Scalars['ID']['input'];
  companyID?: InputMaybe<Scalars['ID']['input']>;
};

export enum MilkingParlorKindEnum {
  Carousel = 'CAROUSEL',
  Parallel = 'PARALLEL',
  Robot = 'ROBOT'
}

export type ParlorStallEntryMilkingType = {
  __typename?: 'ParlorStallEntryMilkingType';
  weightKilograms: Scalars['Float']['output'];
  deviationWeightKilograms: Scalars['Float']['output'];
  flowRate: Scalars['Float']['output'];
  happenedAt: Scalars['DateTime']['output'];
  durationSec: Scalars['Int']['output'];
  endedAt: Scalars['DateTime']['output'];
  mistakes: Array<ParlorStallEntryMistakeType>;
  cow?: Maybe<Cow>;
  penGroup?: Maybe<PenGroup>;
};

export type HerriotIntegrationEntityEdge = {
  __typename?: 'HerriotIntegrationEntityEdge';
  node: HerriotIntegrationEntity;
  cursor: Scalars['String']['output'];
};

export type ReproductionCrRowByDowsValue = {
  __typename?: 'ReproductionCrRowByDowsValue';
  dow?: Maybe<Dow>;
};

export type CustomReportBlueprintSourceFieldValue = {
  __typename?: 'CustomReportBlueprintSourceFieldValue';
  intValue?: Maybe<Scalars['Int']['output']>;
  floatValue?: Maybe<Scalars['Float']['output']>;
  strValue?: Maybe<Scalars['String']['output']>;
  datetimeValue?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomMilkingReportSettingsFilters = CustomMilkingReportSettingsActualFilter | CustomMilkingReportSettingsHistoricFilter;

export type CreateMilkingParlorIntervalInput = {
  penGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  milkingParlorID: Scalars['ID']['input'];
  intervalStart: Scalars['Time']['input'];
  intervalEnd: Scalars['Time']['input'];
};

export type Bull = {
  __typename?: 'Bull';
  id: Scalars['ID']['output'];
  retirementReason?: Maybe<BullRetirementReason>;
  company: Company;
  farm: Farm;
  shouldBeMigratedOnDelete: Scalars['Boolean']['output'];
  comment: Scalars['String']['output'];
  name: Scalars['String']['output'];
  state: BullState;
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>;
  usdaNumber: Scalars['String']['output'];
  registrationNumber: Scalars['String']['output'];
  breed: Scalars['String']['output'];
  category: Scalars['String']['output'];
  studCode: Scalars['String']['output'];
  motherDcID?: Maybe<Scalars['Int']['output']>;
  motherSelexID?: Maybe<Scalars['String']['output']>;
  motherName: Scalars['String']['output'];
  motherUsdaNumber: Scalars['String']['output'];
  fatherDcID?: Maybe<Scalars['Int']['output']>;
  fatherSelexID?: Maybe<Scalars['String']['output']>;
  fatherName: Scalars['String']['output'];
  fatherUsdaNumber: Scalars['String']['output'];
  numberOnPreviousFarm: Scalars['String']['output'];
  retiredAt?: Maybe<Scalars['DateTime']['output']>;
  dcID?: Maybe<Scalars['Int']['output']>;
  selexID?: Maybe<Scalars['String']['output']>;
};

export type BlueprintInput = {
  __typename?: 'BlueprintInput';
  id: Scalars['ID']['output'];
  company: Company;
  cycleInput?: Maybe<BlueprintCycleInput>;
  valueKinds: Array<ValueKindEnum>;
  name: Scalars['String']['output'];
  prompt: Scalars['String']['output'];
};

export type BlueprintSpecialInput = {
  __typename?: 'BlueprintSpecialInput';
  name: Scalars['String']['output'];
  prompt: Scalars['String']['output'];
  action: BlueprintAction;
};

export type MilkingParlorIntervalEdge = {
  __typename?: 'MilkingParlorIntervalEdge';
  node: MilkingParlorInterval;
  cursor: Scalars['String']['output'];
};

export type CalendarRowTypeConnection = {
  __typename?: 'CalendarRowTypeConnection';
  pageInfo: PageInfo;
  edges: Array<CalendarRowTypeEdge>;
  nodes: Array<CalendarRowType>;
};

export type MilkingParlorGeneralReportRowEntry = {
  __typename?: 'MilkingParlorGeneralReportRowEntry';
  farm: Farm;
  penGroup: PenGroup;
  milkingParlor: MilkingParlor;
  cows: MlkParlorGeneralReportCows;
  milk: MlkParlorGeneralReportMilk;
  milkingTime: MlkParlorGeneralReportMilkingTime;
  notIdentifiedCount: Scalars['Int']['output'];
};

export enum CustomReportChartAggFormulaKindEnum {
  Average = 'AVERAGE',
  Count = 'COUNT',
  Max = 'MAX',
  Median = 'MEDIAN',
  Min = 'MIN',
  Sum = 'SUM'
}

export type CustomReportHeaderColumn = CustomReportColumnInterface & {
  __typename?: 'CustomReportHeaderColumn';
  blueprintSourceField: SourceField;
  blueprintSourceFieldValues: Array<Maybe<CustomReportBlueprintSourceFieldValue>>;
};

export type UpdateProtocolInputV2 = {
  name?: InputMaybe<Scalars['String']['input']>;
  processKind?: InputMaybe<ProcessKindEnum>;
  availability?: InputMaybe<Array<AnimalKindEnum>>;
  penGroupID?: InputMaybe<Scalars['ID']['input']>;
  totalTreatmentCost?: InputMaybe<Scalars['Float']['input']>;
  durationDays?: InputMaybe<Scalars['Int']['input']>;
  injectionsSchedule?: InputMaybe<Array<ProtocolScheduledInjectionInput>>;
  operationsSchedule?: InputMaybe<Array<ProtocolScheduledOperationInput>>;
};

export type TokenResponse = {
  __typename?: 'TokenResponse';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export type CowEventAtDayInMilk = {
  __typename?: 'CowEventAtDayInMilk';
  event: CowEvent;
  daysInMilk: Scalars['Int']['output'];
};

export type EmployeeConnection = {
  __typename?: 'EmployeeConnection';
  pageInfo: PageInfo;
  edges: Array<EmployeeEdge>;
  nodes: Array<Employee>;
};

export type ReproductionHdrAndPrReport = {
  __typename?: 'ReproductionHdrAndPrReport';
  id: Scalars['ID']['output'];
  settings: ReproductionHdrAndPrReportSettings;
  roles: Array<BlueprintRole>;
  calculatedReport: ReproductionHdrAndPrCalculatedReportInterface;
  name: Scalars['String']['output'];
};


export type ReproductionHdrAndPrReportCalculatedReportArgs = {
  invalidateCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateCalvingActionInput = {
  __typename?: 'CreateCalvingActionInput';
  comment: Scalars['String']['output'];
  cowID: Scalars['ID']['output'];
  penGroupID?: Maybe<Scalars['ID']['output']>;
  difficultyScore?: Maybe<Scalars['Int']['output']>;
  calves: Array<CreateCalvingActionCalfInputGql>;
};

export type PenGroup = {
  __typename?: 'PenGroup';
  id: Scalars['ID']['output'];
  company: Company;
  farm: Farm;
  occupied: Scalars['Int']['output'];
  occupiedPercent?: Maybe<Scalars['Float']['output']>;
  remaining?: Maybe<Scalars['Int']['output']>;
  cows: Array<Cow>;
  name: Scalars['String']['output'];
  identifier: Scalars['Identifier']['output'];
  isMilking: Scalars['Boolean']['output'];
  isDry: Scalars['Boolean']['output'];
  isHospital: Scalars['Boolean']['output'];
  isCalf: Scalars['Boolean']['output'];
  isArtificialInsemination: Scalars['Boolean']['output'];
  isWithBull: Scalars['Boolean']['output'];
  isUser: Scalars['Boolean']['output'];
  capacity?: Maybe<Scalars['Int']['output']>;
};


export type PenGroupCowsArgs = {
  vitalityFilter?: VitalityFilter;
};

export type MonitorLaunchResultValue = MonitorLaunchValue & {
  __typename?: 'MonitorLaunchResultValue';
  happenedAt: Scalars['DateTime']['output'];
  monitorLaunch: MonitorLaunch;
  value: Scalars['Float']['output'];
};

export type CreateDiseaseInput = {
  name: Scalars['String']['input'];
  protocolIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  shouldSpecifyLimb?: Scalars['Boolean']['input'];
};

export type IntegratorCompanyEdge = {
  __typename?: 'IntegratorCompanyEdge';
  node: Company;
  cursor: Scalars['String']['output'];
};

export type LivestockLiveMonthsPeriodsTotal = {
  __typename?: 'LivestockLiveMonthsPeriodsTotal';
  zeroToTwoMonth: Scalars['Int']['output'];
  threeToSixMonth: Scalars['Int']['output'];
  sevenToTwelveMonth: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CustomReportBlueprintLaunchResultRow = {
  __typename?: 'CustomReportBlueprintLaunchResultRow';
  values: Array<CustomReportBlueprintSourceFieldValue>;
};

export type ReproductionCrCrossTableSubTable = {
  __typename?: 'ReproductionCrCrossTableSubTable';
  rows: Array<Array<Maybe<Scalars['Int']['output']>>>;
  total: Array<Maybe<Scalars['Int']['output']>>;
  other?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  rowNames: Array<Maybe<ReproductionCrValue>>;
  columnNames: Array<Maybe<ReproductionCrValue>>;
};

export type CustomMilkingReportSettingsHistoricValuePenGroups = {
  __typename?: 'CustomMilkingReportSettingsHistoricValuePenGroups';
  penGroups: Array<PenGroup>;
};

export type FarmEdge = {
  __typename?: 'FarmEdge';
  node: Farm;
  cursor: Scalars['String']['output'];
};

export type CreateProtocolInputV2 = {
  name: Scalars['String']['input'];
  processKind: ProcessKindEnum;
  availability: Array<AnimalKindEnum>;
  penGroupID?: InputMaybe<Scalars['ID']['input']>;
  totalTreatmentCost?: InputMaybe<Scalars['Float']['input']>;
  durationDays: Scalars['Int']['input'];
  injectionsSchedule?: Array<ProtocolScheduledInjectionInput>;
  operationsSchedule?: Array<ProtocolScheduledOperationInput>;
};

export type SetReproductionTargetsInput = {
  hdrCowsTargetPercent: Scalars['Int']['input'];
  hdrCowsDeviationThresholdPercent: Scalars['Int']['input'];
  hdrHeifersTargetPercent: Scalars['Int']['input'];
  hdrHeifersDeviationThresholdPercent: Scalars['Int']['input'];
  prCowsTargetPercent: Scalars['Int']['input'];
  prCowsDeviationThresholdPercent: Scalars['Int']['input'];
  prHeifersTargetPercent: Scalars['Int']['input'];
  prHeifersDeviationThresholdPercent: Scalars['Int']['input'];
  crCowsTargetPercent: Scalars['Int']['input'];
  crCowsDeviationThresholdPercent: Scalars['Int']['input'];
  crHeifersTargetPercent: Scalars['Int']['input'];
  crHeifersDeviationThresholdPercent: Scalars['Int']['input'];
};

export type CustomReportChartSettings = {
  __typename?: 'CustomReportChartSettings';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  kind: CustomReportChartKindEnum;
  xAxis?: Maybe<CustomReportChartXAxis>;
  yAxes: Array<CustomReportChartYAxis>;
};

export type CustomReportSettingsSortByInput = {
  kind: CustomReportSortByKindEnum;
  sortingValue?: InputMaybe<CustomReportSettingsSortByValueInput>;
};

export type CowLactationEdge = {
  __typename?: 'CowLactationEdge';
  node: Lactation;
  cursor: Scalars['String']['output'];
  lactationNumber: Scalars['Int']['output'];
};

export type BlueprintRunSettings = {
  __typename?: 'BlueprintRunSettings';
  dows: Array<Array<Dow>>;
  runType: RunType;
  interval?: Maybe<ScheduleInterval>;
  repeatInterval: Scalars['Int']['output'];
  startOn?: Maybe<Scalars['DateTime']['output']>;
  autostartOn?: Maybe<Scalars['Time']['output']>;
};

export enum RunType {
  Schedule = 'SCHEDULE',
  Incident = 'INCIDENT',
  Master = 'MASTER'
}

export type SourceSection = {
  __typename?: 'SourceSection';
  id: Scalars['ID']['output'];
  verboseName: Scalars['String']['output'];
  company: Company;
  fields: Array<SourceField>;
  kind: SourceSectionKindEnum;
};

export type BlueprintValue = Bull | Calving | Cow | Disease | Employee | Farm | Injection | Insemination | InseminationScheme | PenGroup | Protocol | SemenDose | Operation | StrHardcodedValue | IntHardcodedValue | FloatHardcodedValue | DatetimeHardcodedValue | DateHardcodedValue | JsonHardcodedValue | SourceField | BlueprintInput;

export type ProtocolConnection = {
  __typename?: 'ProtocolConnection';
  pageInfo: PageInfo;
  edges: Array<ProtocolEdge>;
  nodes: Array<Protocol>;
};

export type RawMilkImportFileInput = {
  file: Scalars['Upload']['input'];
  farmID: Scalars['ID']['input'];
  parlor: MilkingParlorManufacturerEnum;
  companyID?: InputMaybe<Scalars['ID']['input']>;
  milkingParlorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type Disease = Event & {
  __typename?: 'Disease';
  name: Scalars['String']['output'];
  kind: EventKindEnum;
  id: Scalars['ID']['output'];
  company: Company;
  protocols: Array<Protocol>;
  additionalProtocols: Array<Protocol>;
  mainProtocol?: Maybe<Protocol>;
  shouldSpecifyLimb: Scalars['Boolean']['output'];
  isDefault: Scalars['Boolean']['output'];
};

export type MilkingParlorConnection = {
  __typename?: 'MilkingParlorConnection';
  pageInfo: PageInfo;
  edges: Array<MilkingParlorEdge>;
  nodes: Array<MilkingParlor>;
};

export type UpdateCustomMilkingReportInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  blueprintRoleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ReproductionCrCalculatedComparisonReportHistoricPeriod = {
  __typename?: 'ReproductionCrCalculatedComparisonReportHistoricPeriod';
  since: Scalars['Date']['output'];
  till: Scalars['Date']['output'];
};

export type CustomReportChartValue = {
  __typename?: 'CustomReportChartValue';
  value?: Maybe<Scalars['Float']['output']>;
};

export enum InseminationResult {
  Open = 'OPEN',
  Preg = 'PREG',
  Rebred = 'REBRED',
  Abort = 'ABORT',
  Estimate = 'ESTIMATE',
  Conceive = 'CONCEIVE',
  Null = 'NULL'
}

export type MonitorGroupConnection = {
  __typename?: 'MonitorGroupConnection';
  pageInfo: PageInfo;
  edges: Array<MonitorGroupEdge>;
  nodes: Array<MonitorGroup>;
};

export type MilkingParlorEdge = {
  __typename?: 'MilkingParlorEdge';
  node: MilkingParlor;
  cursor: Scalars['String']['output'];
};

export type MilkingParlorMeterFunctionReportRowEntry = MilkingParlorMeterFunctionReportEntry & {
  __typename?: 'MilkingParlorMeterFunctionReportRowEntry';
  cowsCount?: Maybe<Scalars['Int']['output']>;
  milkDeviationFromExpectedKilograms?: Maybe<Scalars['Float']['output']>;
  milkWeightKilograms?: Maybe<Scalars['Float']['output']>;
  durationSeconds?: Maybe<Scalars['Float']['output']>;
  flowRate?: Maybe<Scalars['Float']['output']>;
  conductivity?: Maybe<Scalars['Float']['output']>;
  avgPeakFlowRate?: Maybe<Scalars['Float']['output']>;
  fallCount?: Maybe<Scalars['Int']['output']>;
  manualModeCount?: Maybe<Scalars['Int']['output']>;
  manualDetachCount?: Maybe<Scalars['Int']['output']>;
  stallNumber: Scalars['Int']['output'];
  notIdentifiedCount?: Maybe<Scalars['Int']['output']>;
};

export type DiseaseConnection = {
  __typename?: 'DiseaseConnection';
  pageInfo: PageInfo;
  edges: Array<DiseaseEdge>;
  nodes: Array<Disease>;
};

export enum CalculationMethodEnum {
  Total = 'TOTAL',
  Average = 'AVERAGE',
  Percent = 'PERCENT'
}

export type MilkingParlorMilkingMistakesReportGroupedRowEntry = {
  __typename?: 'MilkingParlorMilkingMistakesReportGroupedRowEntry';
  reattachCount: Scalars['Int']['output'];
  noMilkCount: Scalars['Int']['output'];
  enteredTwiceCount: Scalars['Int']['output'];
  noLetdownCount: Scalars['Int']['output'];
  earlyFalloffCount: Scalars['Int']['output'];
  lateRehangCount: Scalars['Int']['output'];
  manualDetachCount: Scalars['Int']['output'];
  manualModeCount: Scalars['Int']['output'];
  notIdentifiedCount: Scalars['Int']['output'];
  dryCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type UpdateOperationInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type DateHardcodedValue = {
  __typename?: 'DateHardcodedValue';
  value: Scalars['Date']['output'];
};

export enum ConceptionRateParameterEnum {
  Bull = 'BULL',
  Date = 'DATE',
  Employee = 'EMPLOYEE',
  InseminationScheme = 'INSEMINATION_SCHEME',
  InseminationNumber = 'INSEMINATION_NUMBER',
  Stud = 'STUD',
  IntervalBetweenInseminations = 'INTERVAL_BETWEEN_INSEMINATIONS',
  DayOfWeek = 'DAY_OF_WEEK',
  AnimalCycle = 'ANIMAL_CYCLE',
  BullBreed = 'BULL_BREED'
}

export enum ImportProvidersEnum {
  Selex = 'SELEX',
  Dnc = 'DNC'
}

export type CreateMonitorGroupInput = {
  name: Scalars['String']['input'];
};

export type BlueprintLaunchResultTable = {
  __typename?: 'BlueprintLaunchResultTable';
  dataRowColumns: Array<Scalars['String']['output']>;
  dataRowColumnKinds: Array<Scalars['String']['output']>;
  rows: Array<BlueprintLaunchRow>;
  rowsCount: Scalars['Int']['output'];
};

export type BlueprintViewKindSettingsInput = {
  tableSettings?: InputMaybe<BlueprintViewTableSettingsInput>;
  graphSettings?: InputMaybe<BlueprintViewGraphSettingsInput>;
};

export type UpdateInseminationSchemeInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
};

export type CowFieldValueInput = {
  intValue?: InputMaybe<Scalars['Int']['input']>;
  floatValue?: InputMaybe<Scalars['Float']['input']>;
  datetimeValue?: InputMaybe<Scalars['DateTime']['input']>;
  idValue?: InputMaybe<Scalars['ID']['input']>;
  strValue?: InputMaybe<Scalars['String']['input']>;
};

export type MonitorSchedule = {
  __typename?: 'MonitorSchedule';
  interval: MonitorScheduleInterval;
  startOn: Scalars['DateTime']['output'];
  dows?: Maybe<Array<Array<Dow>>>;
};

export type SourceArgumentType = {
  __typename?: 'SourceArgumentType';
  name: Scalars['String']['output'];
  verboseName: Scalars['String']['output'];
  kind: ValueKindEnum;
  isRequired: Scalars['Boolean']['output'];
};

export type InjectionEdge = {
  __typename?: 'InjectionEdge';
  node: Injection;
  cursor: Scalars['String']['output'];
};

export type MilkingParlorMixedMilkingReportRowEntry = {
  __typename?: 'MilkingParlorMixedMilkingReportRowEntry';
  stallNumber: Scalars['String']['output'];
  attachTime: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  farm: Farm;
  expectedPenGroup: PenGroup;
  actualPenGroup: PenGroup;
  cow: Cow;
};

export type CreateCompanyInput = {
  name: Scalars['String']['input'];
  licensedBy: Scalars['DateTime']['input'];
  tzIdentifier?: Scalars['String']['input'];
};

export type BlueprintViewSettings = {
  __typename?: 'BlueprintViewSettings';
  kind?: Maybe<ViewKindEnum>;
  kindSettings?: Maybe<BlueprintViewKindSettings>;
};

export type MilkingParlor = {
  __typename?: 'MilkingParlor';
  id: Scalars['ID']['output'];
  farm: Farm;
  capacity: Scalars['Int']['output'];
  penGroups: Array<PenGroup>;
  intervals: Array<MilkingParlorInterval>;
  manufacturer: MilkingParlorManufacturerEnum;
  kind: MilkingParlorKindEnum;
  name: Scalars['String']['output'];
  capacityStart: Scalars['Int']['output'];
  capacityEnd: Scalars['Int']['output'];
  sortingGatesCount: Scalars['Int']['output'];
  connectionInfo: Scalars['String']['output'];
  tagField: ParlorExportStatusFileTagFieldEnum;
  regNumField: RegNumFieldEnum;
};

export type SemenSupplier = {
  __typename?: 'SemenSupplier';
  name: Scalars['String']['output'];
};

export type CowInPenGroup = {
  __typename?: 'CowInPenGroup';
  movedToPenGroupAt: Scalars['DateTime']['output'];
  movedFromPenGroupAt?: Maybe<Scalars['DateTime']['output']>;
  movedToPenGroupAtDaysInMilk: Scalars['Int']['output'];
  movedFromPenGroupAtDaysInMilk?: Maybe<Scalars['Int']['output']>;
  penGroup: PenGroup;
};

export type UpdateBullInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  usdaNumber?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  breed?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  calvingID?: InputMaybe<Scalars['ID']['input']>;
  farmID?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<BullState>;
  penGroupID?: InputMaybe<Scalars['ID']['input']>;
  motherID?: InputMaybe<Scalars['ID']['input']>;
  motherDcID?: InputMaybe<Scalars['Int']['input']>;
  motherSelexID?: InputMaybe<Scalars['String']['input']>;
  motherName?: InputMaybe<Scalars['String']['input']>;
  motherUsdaNumber?: InputMaybe<Scalars['String']['input']>;
  fatherID?: InputMaybe<Scalars['ID']['input']>;
  fatherDcID?: InputMaybe<Scalars['Int']['input']>;
  fatherSelexID?: InputMaybe<Scalars['String']['input']>;
  fatherName?: InputMaybe<Scalars['String']['input']>;
  fatherNumber?: InputMaybe<Scalars['String']['input']>;
  fatherUsdaNumber?: InputMaybe<Scalars['String']['input']>;
  numberOnPreviousFarm?: InputMaybe<Scalars['String']['input']>;
  retirementReason?: InputMaybe<BullRetirementReason>;
  retiredAt?: InputMaybe<Scalars['DateTime']['input']>;
  dcID?: InputMaybe<Scalars['Int']['input']>;
  selexID?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
};

export type CustomReportRow = CustomReportRowInterface & {
  __typename?: 'CustomReportRow';
  blueprintSourceFieldValue?: Maybe<CustomReportBlueprintSourceFieldValue>;
  values: Array<Maybe<CustomReportRowValue>>;
};

export enum CustomReportOrderEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SetDiseaseProtocolsInput = {
  diseaseID: Scalars['ID']['input'];
  mainProtocolID: Scalars['ID']['input'];
  additionalProtocolIDs?: Array<Scalars['ID']['input']>;
};

export type SetHerriotIntegrationSettingsInput = {
  apiKey: Scalars['String']['input'];
  apiLogin: Scalars['String']['input'];
  apiPassword: Scalars['String']['input'];
  issuerID: Scalars['UUID']['input'];
  breedingValueType: BreedingValueTypeEnum;
  keepingPurposeGuid: Scalars['UUID']['input'];
  keepingTypeGuid: Scalars['UUID']['input'];
  markingAttachmentLocationGuid: Scalars['UUID']['input'];
  markingMeans: MarkingMeanEnum;
  supervisedObjectGuid: Scalars['UUID']['input'];
  initiatorLogin: Scalars['String']['input'];
};

export type UpdateReproductionCrReportInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  roleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GenealogyCow = {
  __typename?: 'GenealogyCow';
  relationKind: GenealogyRelationKind;
  registrationNumber: Scalars['String']['output'];
  name: Scalars['String']['output'];
  breed: Scalars['String']['output'];
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>;
  weightKilograms?: Maybe<Scalars['Float']['output']>;
  overallMilkWeightKilograms?: Maybe<Scalars['Float']['output']>;
  overallMilkFatPercent?: Maybe<Scalars['Float']['output']>;
  overallMilkProteinPercent?: Maybe<Scalars['Float']['output']>;
  bestLactationNumber?: Maybe<Scalars['Int']['output']>;
  bestLactationMilkWeightKilograms?: Maybe<Scalars['Float']['output']>;
  bestLactationMilkFatPercent?: Maybe<Scalars['Float']['output']>;
  bestLactationMilkProteinPercent?: Maybe<Scalars['Float']['output']>;
  animal?: Maybe<CowBull>;
};

export type StrHardcodedValue = {
  __typename?: 'StrHardcodedValue';
  value: Scalars['String']['output'];
};

export enum RegNumFieldEnum {
  UsdaNumber = 'USDA_NUMBER',
  RegistrationNumber = 'REGISTRATION_NUMBER',
  EarTagNumber = 'EAR_TAG_NUMBER',
  CollarNumber = 'COLLAR_NUMBER'
}

export type MilkingParlorFlowRateReportEntry = {
  cowStallPerHour?: Maybe<Scalars['Float']['output']>;
  flowRate0To15Sec?: Maybe<Scalars['Float']['output']>;
  flowRate15To30Sec?: Maybe<Scalars['Float']['output']>;
  flowRate30To60Sec?: Maybe<Scalars['Float']['output']>;
  peakFlowRate?: Maybe<Scalars['Float']['output']>;
  milkWeight0To120Sec?: Maybe<Scalars['Float']['output']>;
  milkWeightPercent0To120Sec?: Maybe<Scalars['Float']['output']>;
};

export type JsonHardcodedValue = {
  __typename?: 'JSONHardcodedValue';
  value: Scalars['JSON']['output'];
};

export enum ViewGraphKindEnum {
  Pie = 'PIE',
  VerticalBars = 'VERTICAL_BARS',
  HorizontalBars = 'HORIZONTAL_BARS',
  RomanCandles = 'ROMAN_CANDLES'
}

export type CustomMilkingReportDatasetGroupingActual = CustomMilkingReportDatasetInterface & {
  __typename?: 'CustomMilkingReportDatasetGroupingActual';
  field: CustomMilkingReportYAxisField;
  valueKind: ValueKindEnum;
  fieldKind: SourceFieldKindEnum;
  value?: Maybe<SourceFieldValue>;
};

export type ReproductionHdrAndPrMainRows = {
  __typename?: 'ReproductionHdrAndPrMainRows';
  hdrMetric: ReproductionMetric;
  prMetric: ReproductionMetric;
  crMetric: ReproductionMetric;
  rows: Array<ReproductionHdrAndPrRowByDate>;
};

export type CustomReportSettingsRowInput = {
  order: CustomReportOrderEnum;
  sortBy: CustomReportSettingsSortByInput;
  withTotal: Scalars['Boolean']['input'];
  blueprintSourceFieldID: Scalars['ID']['input'];
};

export type IdentifierMappingInput = {
  originIdentifier: Scalars['Int']['input'];
  targetIdentifier: Scalars['Int']['input'];
};

export type TestMilkingUpload = {
  __typename?: 'TestMilkingUpload';
  id: Scalars['ID']['output'];
  testMilkings: Array<TestMilking>;
  cowsCount: Scalars['Int']['output'];
  hasUploadIssues: Scalars['Boolean']['output'];
  happenedAt: Scalars['Date']['output'];
};

export type CustomMilkingReportDatasetInterface = {
  field: CustomMilkingReportYAxisField;
};

export type CreateBullInput = {
  comment?: Scalars['String']['input'];
  name?: Scalars['String']['input'];
  usdaNumber?: Scalars['String']['input'];
  registrationNumber?: Scalars['String']['input'];
  breed?: Scalars['String']['input'];
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  calvingID?: InputMaybe<Scalars['ID']['input']>;
  farmID: Scalars['ID']['input'];
  state?: BullState;
  penGroupID?: InputMaybe<Scalars['ID']['input']>;
  motherID?: InputMaybe<Scalars['ID']['input']>;
  motherDcID?: InputMaybe<Scalars['Int']['input']>;
  motherSelexID?: InputMaybe<Scalars['String']['input']>;
  motherName?: Scalars['String']['input'];
  motherUsdaNumber?: Scalars['String']['input'];
  fatherID?: InputMaybe<Scalars['ID']['input']>;
  fatherDcID?: InputMaybe<Scalars['Int']['input']>;
  fatherSelexID?: InputMaybe<Scalars['String']['input']>;
  fatherName?: Scalars['String']['input'];
  fatherNumber?: Scalars['String']['input'];
  fatherUsdaNumber?: Scalars['String']['input'];
  numberOnPreviousFarm?: Scalars['String']['input'];
  retirementReason?: InputMaybe<BullRetirementReason>;
  retiredAt?: InputMaybe<Scalars['DateTime']['input']>;
  dcID?: InputMaybe<Scalars['Int']['input']>;
  selexID?: InputMaybe<Scalars['String']['input']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
  endCursor?: Maybe<Scalars['String']['output']>;
};

export type CowMilkParlorStatsRow = {
  __typename?: 'CowMilkParlorStatsRow';
  name: Scalars['String']['output'];
  expectedWeightKilograms: Scalars['Float']['output'];
  deviationWeightKilograms: Scalars['Float']['output'];
  averageWeightKilograms: Scalars['Float']['output'];
  dates: Array<CowMilkParlorStatsOnDate>;
};

export type SortingGate = {
  __typename?: 'SortingGate';
  number: Scalars['Int']['output'];
  value: Scalars['Int']['output'];
};

export type CustomMilkingReportSettingsHistoricValueLactationNumbers = {
  __typename?: 'CustomMilkingReportSettingsHistoricValueLactationNumbers';
  lactationNumbers?: Maybe<Array<Scalars['Int']['output']>>;
};

export type CustomMilkingReportSettingsGroupingActual = {
  __typename?: 'CustomMilkingReportSettingsGroupingActual';
  masterBlueprintSourceField?: Maybe<SourceField>;
};

export type SetBlueprintViewSettingsInput = {
  kind: ViewKindEnum;
  kindSettings?: InputMaybe<BlueprintViewKindSettingsInput>;
};

export type UpdatePenGroupInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  identifier: Scalars['Identifier']['input'];
  isMilking?: InputMaybe<Scalars['Boolean']['input']>;
  isDry?: InputMaybe<Scalars['Boolean']['input']>;
  isHospital?: InputMaybe<Scalars['Boolean']['input']>;
  isCalf?: InputMaybe<Scalars['Boolean']['input']>;
  isArtificialInsemination?: InputMaybe<Scalars['Boolean']['input']>;
  isWithBull?: InputMaybe<Scalars['Boolean']['input']>;
  isUser?: InputMaybe<Scalars['Boolean']['input']>;
  capacity?: InputMaybe<Scalars['Int']['input']>;
  dcID?: InputMaybe<Scalars['Int']['input']>;
  selexID?: InputMaybe<Scalars['String']['input']>;
  farmID?: InputMaybe<Scalars['ID']['input']>;
};

export type SemenDose = {
  __typename?: 'SemenDose';
  id: Scalars['ID']['output'];
  company: Company;
  bull: Bull;
  batchNumber: Scalars['String']['output'];
  dosesCount: Scalars['Int']['output'];
  studCode: Scalars['String']['output'];
  deliveryDate?: Maybe<Scalars['Date']['output']>;
};

export type SourceFieldType = {
  __typename?: 'SourceFieldType';
  kind: SourceFieldKindEnum;
  verboseName: Scalars['String']['output'];
  arguments: Array<SourceArgumentType>;
  returnValueKind: ValueKindEnum;
};

export type EventInterfaceConnection = {
  __typename?: 'EventInterfaceConnection';
  pageInfo: PageInfo;
  edges: Array<EventInterfaceEdge>;
  nodes: Array<Event>;
};

export type CreateBlueprintRunSettingsInput = {
  runType: RunType;
  interval?: InputMaybe<ScheduleInterval>;
  repeatInterval?: InputMaybe<Scalars['Int']['input']>;
  startOn?: InputMaybe<Scalars['DateTime']['input']>;
  autostartOn?: InputMaybe<Scalars['Time']['input']>;
  dows?: InputMaybe<Array<Array<Dow>>>;
};

export type CustomReportRowValue = {
  __typename?: 'CustomReportRowValue';
  intValue?: Maybe<Scalars['Int']['output']>;
  floatValue?: Maybe<Scalars['Float']['output']>;
  strValue?: Maybe<Scalars['String']['output']>;
  datetimeValue?: Maybe<Scalars['DateTime']['output']>;
};

export enum NodeValueKindEnum {
  Hardcode = 'HARDCODE',
  Variable = 'VARIABLE'
}

export type MilkingParlorMeterFunctionReportEntry = {
  cowsCount?: Maybe<Scalars['Int']['output']>;
  milkDeviationFromExpectedKilograms?: Maybe<Scalars['Float']['output']>;
  milkWeightKilograms?: Maybe<Scalars['Float']['output']>;
  durationSeconds?: Maybe<Scalars['Float']['output']>;
  flowRate?: Maybe<Scalars['Float']['output']>;
  conductivity?: Maybe<Scalars['Float']['output']>;
  avgPeakFlowRate?: Maybe<Scalars['Float']['output']>;
  fallCount?: Maybe<Scalars['Int']['output']>;
  manualModeCount?: Maybe<Scalars['Int']['output']>;
  manualDetachCount?: Maybe<Scalars['Int']['output']>;
};

export type ReproductionHdrAndPrCalculatedReportEmpty = ReproductionHdrAndPrCalculatedReportInterface & {
  __typename?: 'ReproductionHdrAndPrCalculatedReportEmpty';
  cacheCreatedAt: Scalars['DateTime']['output'];
};

export type SomaticCellsReportSettingsHistoricValuePenGroups = {
  __typename?: 'SomaticCellsReportSettingsHistoricValuePenGroups';
  penGroups: Array<PenGroup>;
};

export enum CustomMilkingReportXAxisStep {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH'
}

export type ReproductionCrRowsByBull = ReproductionCrDetailedRowsByEntity & {
  __typename?: 'ReproductionCrRowsByBull';
  hashID: Scalars['String']['output'];
  total?: Maybe<ReproductionCrRowsRow>;
  other?: Maybe<ReproductionCrRowsRow>;
  rows: Array<ReproductionCrRowsRow>;
  rowNames: Array<Maybe<Bull>>;
};

export type Query = {
  __typename?: 'Query';
  /** @deprecated use paginated version with ids filter */
  cowDisease?: Maybe<CowDisease>;
  /** @deprecated use paginated version with ids filter */
  cowDefaultEvent?: Maybe<CowDefaultEvent>;
  /** @deprecated use paginated version with ids filter */
  cowInjection?: Maybe<CowInjection>;
  cowPlannedInjections: CowPlannedInjectionsConnection;
  /** @deprecated use paginated version with ids filter */
  cowProtocol?: Maybe<CowProtocol>;
  /** @deprecated use paginated version with ids filter */
  blueprint?: Maybe<Blueprint>;
  blueprints: BlueprintConnection;
  /** @deprecated use paginated version with ids filter */
  blueprintSourceSection?: Maybe<SourceSection>;
  /** @deprecated use paginated version with ids filter */
  blueprintRole?: Maybe<BlueprintRole>;
  /** @deprecated use just "roles" */
  blueprintRoles: BlueprintRoleConnection;
  roles: BlueprintRoleConnection;
  blueprintSourceFields: SourceFieldConnection;
  blueprintSourceSectionTypes: Array<SourceSectionType>;
  blueprintActionTypes: Array<ActionType>;
  bulls: BullConnection;
  calendarEntries: CalendarRowTypeConnection;
  /** @deprecated use paginated version with ids filter */
  calving?: Maybe<Calving>;
  calvings: CalvingConnection;
  /** @deprecated use paginated version with ids filter */
  calvingResult?: Maybe<CalvingResult>;
  /** @deprecated use paginated version with ids filter */
  cow?: Maybe<Cow>;
  cows: CowConnection;
  defaultEvent: DefaultEvent;
  diseases: DiseaseConnection;
  /**
   * Ручка для получения "событий".
   *
   * Событием называются следующие сущности:
   * - Стандартное (DefaultEventEnum)
   * - Протокол (Protocol)
   * - Инъекция (Injection)
   * - Заболевание (Disease)
   *
   * Существуют стандартные события (не путать с событиями "Стандартное"),
   * которые доступны всем компаниям. Чтобы их получить, необходимо передать
   * флаг withDefault = true.
   *
   * Схема осеменения (InsemintionScheme) событием не является. Но возвращается
   * данной ручкой, потому что так задумано по дизайну.
   *
   * Фильтр cowIDs работает только не по стандартным событиям. То есть стандартные
   * события (не путать с событиями "Стандартное") будут возвращаться независимо
   * от указания этого фильтра.
   *
   */
  events: EventInterfaceConnection;
  /** @deprecated use paginated version with ids filter */
  insemination?: Maybe<Insemination>;
  inseminations: InseminationConnection;
  injections: InjectionConnection;
  injectionsManufacturers: InjectionsManufacturerConnection;
  /** @deprecated use paginated version with ids filter */
  lactation?: Maybe<Lactation>;
  milkParlorStats: Array<CowMilkParlorStatsRow>;
  /** @deprecated use paginated version with ids filter */
  penGroup?: Maybe<PenGroup>;
  penGroups: PenGroupConnection;
  protocols: ProtocolConnection;
  /** @deprecated use paginated version with ids filter */
  protocolInjection?: Maybe<ProtocolInjection>;
  /** @deprecated use paginated version with ids filter */
  semenDose?: Maybe<SemenDose>;
  semenDoses: SemenDoseConnection;
  testMilkings: TestMilkingConnection;
  testMilkingUploads: TestMilkingUploadConnection;
  /** @deprecated use paginated version with ids filter */
  weighing?: Maybe<Weighing>;
  weighings: Array<Weighing>;
  operations: OperationConnection;
  /** @deprecated use paginated version with ids filter */
  inseminationScheme?: Maybe<InseminationScheme>;
  inseminationSchemes: InseminationSchemeConnection;
  /** @deprecated use paginated version with ids filter */
  employee?: Maybe<Employee>;
  employees: EmployeeConnection;
  cowEvents: CowEventInterfaceConnection;
  blueprintLaunches: BlueprintLaunchConnection;
  /** @deprecated use paginated version with ids filter */
  monitorGroup?: Maybe<MonitorGroup>;
  monitorGroups: MonitorGroupConnection;
  monitor: MonitorConnection;
  /** @deprecated use paginated version with ids filter */
  livestockForecastSetting?: Maybe<LivestockForecastSetting>;
  livestockForecastSettings: Array<LivestockForecastSetting>;
  livestockForecastPeriods: Array<LivestockForecastPeriod>;
  livestockForecastMonths: Array<LivestockForecastMonth>;
  milkingParlors: MilkingParlorConnection;
  milkingParlorIntervals: MilkingParlorIntervalConnection;
  milkingParlorGeneralReportRows: Array<MilkingParlorGeneralReportRow>;
  milkingParlorMixedMilkingReportRows: Array<MilkingParlorMixedMilkingReportRowEntry>;
  milkingParlorFlowRateReportRows: Array<MilkingParlorFlowRateReportEntry>;
  milkingParlorScheduleReportEntries: Array<MilkingParlorScheduleReportStallEntry>;
  milkingParlorMeterFunctionReportRows: Array<MilkingParlorMeterFunctionReportEntry>;
  milkingParlorMilkingMistakesReportRows: Array<MilkingParlorMilkingMistakesReportRow>;
  cutCodes: CutCodeConnection;
  customReports: CustomReportConnection;
  customReportBlueprintLaunchResult: CustomReportBlueprintLaunchResult;
  cowsCopyKeysForActivation: Array<CowsCopyKey>;
  cowsCopyKeys: Array<CowsCopyKey>;
  herriotIntegrationEntities: HerriotIntegrationEntityConnection;
  customMilkingReports: CustomMilkingReportConnection;
  reproductionInseminations: Array<Insemination>;
  reproductionCrReports: ReproductionCrReportConnection;
  reproductionHdrAndPrReports: ReproductionHdrAndPrReportConnection;
  somaticCellsReport: SomaticCellsReport;
  reproductionMainChart: ReproductionHdrAndPrMainRows;
  reproductionMainCrChart: ReproductionCrDetailedRowsByEntity;
  exportJobs: ExportJobConnection;
  actionPayloadType?: Maybe<ActionPayloadType>;
  company?: Maybe<Company>;
  companies: CompanyConnection;
  farm?: Maybe<Farm>;
  farms: FarmConnection;
  integrator?: Maybe<Integrator>;
  integrators?: Maybe<IntegratorConnection>;
  user?: Maybe<User>;
  users: UserConnection;
  myUser: User;
};


export type QueryCowDiseaseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCowDefaultEventArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCowInjectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCowProtocolArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBlueprintArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBlueprintsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  roleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  canBeUsedInMonitor?: InputMaybe<Scalars['Boolean']['input']>;
  canBeUsedInCustomReport?: InputMaybe<Scalars['Boolean']['input']>;
  canBeUsedAsCowsFilter?: InputMaybe<Scalars['Boolean']['input']>;
  runType?: InputMaybe<RunType>;
  search?: InputMaybe<Scalars['String']['input']>;
  viewKind?: InputMaybe<ViewKindEnum>;
};


export type QueryBlueprintSourceSectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBlueprintRoleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBlueprintRolesArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  excludeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryRolesArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  excludeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryBlueprintSourceFieldsArgs = {
  valueKind?: InputMaybe<ValueKindEnum>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  blueprintIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  onlyMaster?: Scalars['Boolean']['input'];
};


export type QueryBlueprintSourceSectionTypesArgs = {
  kind?: InputMaybe<SourceSectionKindEnum>;
};


export type QueryBullsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  excludeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryCalendarEntriesArgs = {
  since?: InputMaybe<Scalars['Date']['input']>;
  till?: InputMaybe<Scalars['Date']['input']>;
  roleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  viewKind?: InputMaybe<ViewKindEnum>;
};


export type QueryCalvingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCalvingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCalvingResultArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCowArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  vitalityFilter?: VitalityFilter;
  identifiers?: InputMaybe<Array<Scalars['Identifier']['input']>>;
  penGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  farmIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryDefaultEventArgs = {
  kind: DefaultEventEnum;
};


export type QueryDiseasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  shouldSpecifyLimb?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  kinds?: InputMaybe<Array<EventKindEnum>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<EventsSort>;
  ids?: InputMaybe<Array<EventIdInput>>;
  withDefault?: Scalars['Boolean']['input'];
  cowIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryInseminationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInseminationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  since?: InputMaybe<Scalars['Date']['input']>;
  till?: InputMaybe<Scalars['Date']['input']>;
  farmIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  cowIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  category?: InputMaybe<FemaleAnimalKind>;
};


export type QueryInjectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryInjectionsManufacturersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryLactationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMilkParlorStatsArgs = {
  cowID: Scalars['ID']['input'];
  timeFrameDays?: Scalars['Int']['input'];
};


export type QueryPenGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPenGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  isMilking?: InputMaybe<Scalars['Boolean']['input']>;
  isDry?: InputMaybe<Scalars['Boolean']['input']>;
  isHospital?: InputMaybe<Scalars['Boolean']['input']>;
  isCalf?: InputMaybe<Scalars['Boolean']['input']>;
  isArtificialInsemination?: InputMaybe<Scalars['Boolean']['input']>;
  isWithBull?: InputMaybe<Scalars['Boolean']['input']>;
  isUser?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryProtocolsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryProtocolInjectionArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySemenDoseArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySemenDosesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTestMilkingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  testMilkingUploadIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryTestMilkingUploadsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWeighingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOperationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryInseminationSchemeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInseminationSchemesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryEmployeeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEmployeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCowEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  since?: InputMaybe<Scalars['Date']['input']>;
  till?: InputMaybe<Scalars['Date']['input']>;
  kinds?: InputMaybe<Array<EventKindEnum>>;
  cowIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  blueprintLaunchID?: InputMaybe<Scalars['ID']['input']>;
  eventIDs?: InputMaybe<Array<EventIdInput>>;
};


export type QueryBlueprintLaunchesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  since?: InputMaybe<Scalars['Date']['input']>;
  till?: InputMaybe<Scalars['Date']['input']>;
};


export type QueryMonitorGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMonitorGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMonitorArgs = {
  since?: InputMaybe<Scalars['Date']['input']>;
  till?: InputMaybe<Scalars['Date']['input']>;
};


export type QueryLivestockForecastSettingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLivestockForecastSettingsArgs = {
  farmIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryLivestockForecastPeriodsArgs = {
  farmIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryLivestockForecastMonthsArgs = {
  farmIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryMilkingParlorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  farmIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryMilkingParlorIntervalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryMilkingParlorGeneralReportRowsArgs = {
  milkingDate: Scalars['Date']['input'];
  farmIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  milkingParlorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  milkingNumbers?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type QueryMilkingParlorMixedMilkingReportRowsArgs = {
  milkingDate: Scalars['Date']['input'];
  farmIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  milkingParlorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  milkingNumbers?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type QueryMilkingParlorFlowRateReportRowsArgs = {
  milkingDate: Scalars['Date']['input'];
  farmIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  milkingParlorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  milkingNumbers?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type QueryMilkingParlorScheduleReportEntriesArgs = {
  milkingDate: Scalars['Date']['input'];
  farmIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  milkingParlorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  milkingNumbers?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type QueryMilkingParlorMeterFunctionReportRowsArgs = {
  milkingDate: Scalars['Date']['input'];
  farmIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  milkingParlorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  milkingNumbers?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type QueryMilkingParlorMilkingMistakesReportRowsArgs = {
  milkingDate: Scalars['Date']['input'];
  farmIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  milkingParlorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  milkingNumbers?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type QueryCutCodesArgs = {
  milkingParlorIntervalID: Scalars['ID']['input'];
  penGroupID: Scalars['ID']['input'];
  date: Scalars['Date']['input'];
};


export type QueryCustomReportsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  roleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryCustomReportBlueprintLaunchResultArgs = {
  id: Scalars['ID']['input'];
  hashID: Scalars['String']['input'];
  filters: Array<CustomReportBlueprintLaunchResultFilterInput>;
};


export type QueryCowsCopyKeysForActivationArgs = {
  keys: Array<Scalars['String']['input']>;
};


export type QueryCowsCopyKeysArgs = {
  reason?: InputMaybe<CowsCopyReasonEnum>;
  isActivated?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryHerriotIntegrationEntitiesArgs = {
  kinds?: InputMaybe<Array<HerriotIntegrationEntityKindEnum>>;
};


export type QueryCustomMilkingReportsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  roleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryReproductionInseminationsArgs = {
  hashID: Scalars['String']['input'];
};


export type QueryReproductionCrReportsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  roleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryReproductionHdrAndPrReportsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  roleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryReproductionMainChartArgs = {
  farmID: Scalars['ID']['input'];
  since: Scalars['Date']['input'];
  till: Scalars['Date']['input'];
  femaleAnimalKind?: FemaleAnimalKind;
};


export type QueryReproductionMainCrChartArgs = {
  farmID: Scalars['ID']['input'];
  since: Scalars['Date']['input'];
  till: Scalars['Date']['input'];
  femaleAnimalKind?: FemaleAnimalKind;
  groupBy?: ConceptionRateParameterEnum;
  xAxisStep?: InputMaybe<ReproductionCrDateStep>;
};


export type QueryExportJobsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompaniesArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryFarmArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFarmsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  companyID?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryIntegratorArgs = {
  id: Scalars['ID']['input'];
};


export type QueryIntegratorsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type DatePeriod = {
  __typename?: 'DatePeriod';
  interval: DatePeriodInterval;
  daysFromToday?: Maybe<Scalars['Int']['output']>;
};

export type CreateBlueprintRoleInput = {
  companyID: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  color: BlueprintRoleColor;
};

export type UpdateBlueprintFilterGroupInput = {
  logicOperator?: InputMaybe<LogicOperator>;
};

export type CustomReportChartXAxisInput = {
  blueprintSourceFieldID?: InputMaybe<Scalars['ID']['input']>;
  valueKey?: InputMaybe<CustomReportSettingsValueInput>;
};

export type ProtocolDiseaseEdge = {
  __typename?: 'ProtocolDiseaseEdge';
  node: Disease;
  cursor: Scalars['String']['output'];
  isMain: Scalars['Boolean']['output'];
};

export type Weighing = {
  __typename?: 'Weighing';
  id: Scalars['ID']['output'];
  company: Company;
  cow: Cow;
  happenedAt: Scalars['DateTime']['output'];
  weightKilograms: Scalars['Float']['output'];
};

export type MilkingParlorMilkingMistakesReportRowEntry = {
  __typename?: 'MilkingParlorMilkingMistakesReportRowEntry';
  farm: Farm;
  penGroup: PenGroup;
  milkingParlor: MilkingParlor;
  reattachCount: Scalars['Int']['output'];
  noMilkCount: Scalars['Int']['output'];
  enteredTwiceCount: Scalars['Int']['output'];
  noLetdownCount: Scalars['Int']['output'];
  earlyFalloffCount: Scalars['Int']['output'];
  lateRehangCount: Scalars['Int']['output'];
  manualDetachCount: Scalars['Int']['output'];
  manualModeCount: Scalars['Int']['output'];
  notIdentifiedCount: Scalars['Int']['output'];
  dryCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ReproductionCrReportEdge = {
  __typename?: 'ReproductionCrReportEdge';
  node: ReproductionCrReport;
  cursor: Scalars['String']['output'];
};

export type CustomReportConnection = {
  __typename?: 'CustomReportConnection';
  pageInfo: PageInfo;
  edges: Array<CustomReportEdge>;
  nodes: Array<CustomReport>;
};

export type ReproductionCrRowsByAnimalCycle = ReproductionCrDetailedRowsByEntity & {
  __typename?: 'ReproductionCrRowsByAnimalCycle';
  hashID: Scalars['String']['output'];
  total?: Maybe<ReproductionCrRowsRow>;
  other?: Maybe<ReproductionCrRowsRow>;
  rows: Array<ReproductionCrRowsRow>;
  rowNames: Array<Maybe<ReproductionCrRowByAnimalCycleValue>>;
};

export type DatePeriodIntervalInput = {
  since: Scalars['Date']['input'];
  till: Scalars['Date']['input'];
};

export type DairyPlanCutCodesExportFileInput = {
  farmID: Scalars['ID']['input'];
  companyID?: InputMaybe<Scalars['ID']['input']>;
  onTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CompanyFarmConnection = {
  __typename?: 'CompanyFarmConnection';
  edges: Array<CompanyFarmEdge>;
  nodes: Array<Farm>;
  pageInfo: BeriaPageInfo;
};

export type CompanyUserEdge = {
  __typename?: 'CompanyUserEdge';
  node: User;
  cursor: Scalars['String']['output'];
  farmsInCompany: Array<Farm>;
};

export type CalvingTypeConnection = {
  __typename?: 'CalvingTypeConnection';
  pageInfo: PageInfo;
  edges: Array<CalvingTypeEdge>;
  nodes: Array<Calving>;
};

export type CalvingCount = {
  __typename?: 'CalvingCount';
  heifers: Scalars['Int']['output'];
  cows: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CreateFarmInput = {
  name: Scalars['String']['input'];
  number?: Scalars['String']['input'];
  companyID: Scalars['ID']['input'];
};

export enum LactationGraphGroupByEnum {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH'
}

export enum GenealogyRelationKind {
  Mother = 'MOTHER',
  Father = 'FATHER',
  MotherOfMother = 'MOTHER_OF_MOTHER',
  FatherOfMother = 'FATHER_OF_MOTHER',
  MotherOfFather = 'MOTHER_OF_FATHER',
  FatherOfFather = 'FATHER_OF_FATHER'
}

export type Breed = {
  __typename?: 'Breed';
  name: Scalars['String']['output'];
};

export type CreateInseminationSchemeInput = {
  comment?: Scalars['String']['input'];
  name?: Scalars['String']['input'];
  description?: Scalars['String']['input'];
  code?: Scalars['String']['input'];
};

export type TestMilking = {
  __typename?: 'TestMilking';
  id: Scalars['ID']['output'];
  company: Company;
  cow: Cow;
  daysInMilk: Scalars['Int']['output'];
  penGroup?: Maybe<PenGroup>;
  happenedAt: Scalars['Date']['output'];
  weightKilograms: Scalars['Float']['output'];
  fatPercent?: Maybe<Scalars['Float']['output']>;
  proteinPercent?: Maybe<Scalars['Float']['output']>;
  lactosePercent?: Maybe<Scalars['Float']['output']>;
  solidsPercent?: Maybe<Scalars['Float']['output']>;
  skimmedSolidsPercent?: Maybe<Scalars['Float']['output']>;
  fpdThousandthsCelsius?: Maybe<Scalars['Float']['output']>;
  testDoseWeightKilograms?: Maybe<Scalars['Float']['output']>;
  weightKilogramsPerMonth?: Maybe<Scalars['Float']['output']>;
  fatKilogramsPerMonth?: Maybe<Scalars['Float']['output']>;
  proteinKilogramsPerMonth?: Maybe<Scalars['Float']['output']>;
  ureaMgPerDl?: Maybe<Scalars['Float']['output']>;
  acetoneMmolPerL?: Maybe<Scalars['Float']['output']>;
  bhbMmolPerL?: Maybe<Scalars['Float']['output']>;
  sccThousandsPerMl?: Maybe<Scalars['Float']['output']>;
};

export type CutCodeEdge = {
  __typename?: 'CutCodeEdge';
  node: CutCode;
  cursor: Scalars['String']['output'];
};

export type CreateBlueprintActionInput = {
  blueprintID: Scalars['ID']['input'];
  blueprintFilterGroupID?: InputMaybe<Scalars['ID']['input']>;
  kind: ActionKindEnum;
};

export type ReproductionCrDateValue = {
  __typename?: 'ReproductionCrDateValue';
  since?: Maybe<Scalars['Date']['output']>;
  till?: Maybe<Scalars['Date']['output']>;
};

export type SomaticCellsReportSettings = {
  __typename?: 'SomaticCellsReportSettings';
  since?: Maybe<Scalars['Date']['output']>;
  till?: Maybe<Scalars['Date']['output']>;
  sccThousandsPerMl?: Maybe<Scalars['Float']['output']>;
  filters: Array<SomaticCellsReportSettingsHistoricFilter>;
  blueprint?: Maybe<Blueprint>;
};

export type CowCalculatedField = CowField & {
  __typename?: 'CowCalculatedField';
  valueKind: ValueKindEnum;
  value?: Maybe<BlueprintValue>;
  isPinned: Scalars['Boolean']['output'];
  verboseName: Scalars['String']['output'];
  kind: SourceFieldKindEnum;
  section: SourceSectionType;
  blueprintSourceField: SourceField;
};

export type FloatHardcodedValue = {
  __typename?: 'FloatHardcodedValue';
  value: Scalars['Float']['output'];
};

export type CowProtocolInjection = {
  __typename?: 'CowProtocolInjection';
  id: Scalars['ID']['output'];
  injection?: Maybe<Injection>;
  protocolDayNumber: Scalars['Int']['output'];
  injectionName: Scalars['String']['output'];
  daysMilkWithholdAfterLastThreatment: Scalars['Int']['output'];
  daysMeatWithholdAfterLastThreatment: Scalars['Int']['output'];
  injectionCost?: Maybe<Scalars['Float']['output']>;
};

export type BullEdge = {
  __typename?: 'BullEdge';
  node: Bull;
  cursor: Scalars['String']['output'];
};

export type CalvingConnection = {
  __typename?: 'CalvingConnection';
  pageInfo: PageInfo;
  edges: Array<CalvingEdge>;
  nodes: Array<Calving>;
};

export type CustomMilkingReportSettingsHistoricFilter = {
  __typename?: 'CustomMilkingReportSettingsHistoricFilter';
  kind?: Maybe<MilkingHistoricFilterKind>;
  value?: Maybe<CustomMilkingReportSettingsHistoricValue>;
};

export enum MilkingHistoricFilterKind {
  Lactation = 'LACTATION',
  LactationGroup = 'LACTATION_GROUP',
  PenGroup = 'PEN_GROUP'
}

export type CreateCowsCopyKeyInput = {
  reason: CowsCopyReasonEnum;
  departDate: Scalars['Date']['input'];
  toCompanyInn?: InputMaybe<Scalars['String']['input']>;
  toCompanyID?: InputMaybe<Scalars['ID']['input']>;
  toCompanyName?: InputMaybe<Scalars['String']['input']>;
  cowIDs: Array<Scalars['ID']['input']>;
  diseaseIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  operationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type CustomReportColumnInterface = {
  blueprintSourceField: SourceField;
};

export type IntegratorCompanyConnection = {
  __typename?: 'IntegratorCompanyConnection';
  edges: Array<IntegratorCompanyEdge>;
  nodes: Array<Company>;
  pageInfo: BeriaPageInfo;
};

export type CustomMilkingReport = {
  __typename?: 'CustomMilkingReport';
  id: Scalars['ID']['output'];
  company: Company;
  settings: CustomMilkingReportSettings;
  /** @deprecated use just "roles" */
  blueprintRoles: Array<BlueprintRole>;
  roles: Array<BlueprintRole>;
  calculatedReport: CustomMilkingReportChartInterface;
  name: Scalars['String']['output'];
};


export type CustomMilkingReportCalculatedReportArgs = {
  invalidateCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SomaticCellsReportSettingsHistoricValueLactationGroupNumbers = {
  __typename?: 'SomaticCellsReportSettingsHistoricValueLactationGroupNumbers';
  lactationGroupNumbers?: Maybe<Array<LactationGroupNumber>>;
};

export type UpdateProtocolInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  penGroupID?: InputMaybe<Scalars['ID']['input']>;
  expectedDaysOnProtocol?: InputMaybe<Scalars['Int']['input']>;
  daysUntilCheckAfterLastThreatment?: InputMaybe<Scalars['Int']['input']>;
  totalTreatmentCost?: InputMaybe<Scalars['Float']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
};

export type ReproductionCrValue = ReproductionCrDateValue | Bull | Employee | InseminationScheme | ReproductionCrInseminationNumberValue | SemenSupplier | ReproductionCrIntervalBetweenInseminationsValue | ReproductionCrRowByDowsValue | ReproductionCrRowByAnimalCycleValue | Breed;

export enum BullState {
  Bull = 'BULL',
  Sold = 'SOLD',
  Dnb = 'DNB',
  Dead = 'DEAD'
}

export enum LogicOperator {
  And = 'AND',
  Or = 'OR'
}

export type HerriotIntegrationEntityConnection = {
  __typename?: 'HerriotIntegrationEntityConnection';
  pageInfo: PageInfo;
  edges: Array<HerriotIntegrationEntityEdge>;
  nodes: Array<HerriotIntegrationEntity>;
};

export type CustomMilkingReportSettingsGrouping = CustomMilkingReportSettingsGroupingActual | CustomMilkingReportSettingsGroupingHistoric;

export type MilkingParlorMeterFunctionReportAverageRowEntry = MilkingParlorMeterFunctionReportEntry & {
  __typename?: 'MilkingParlorMeterFunctionReportAverageRowEntry';
  cowsCount?: Maybe<Scalars['Int']['output']>;
  milkDeviationFromExpectedKilograms?: Maybe<Scalars['Float']['output']>;
  milkWeightKilograms?: Maybe<Scalars['Float']['output']>;
  durationSeconds?: Maybe<Scalars['Float']['output']>;
  flowRate?: Maybe<Scalars['Float']['output']>;
  conductivity?: Maybe<Scalars['Float']['output']>;
  avgPeakFlowRate?: Maybe<Scalars['Float']['output']>;
  fallCount?: Maybe<Scalars['Int']['output']>;
  manualModeCount?: Maybe<Scalars['Int']['output']>;
  manualDetachCount?: Maybe<Scalars['Int']['output']>;
};

export type UpdateBlueprintFilterInput = {
  valueKind?: InputMaybe<NodeValueKindEnum>;
  value?: InputMaybe<FilterValueInput>;
  compareOperator?: InputMaybe<CompareOperator>;
  blueprintSourceFieldID?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateSemenDoseInput = {
  batchNumber?: InputMaybe<Scalars['String']['input']>;
  dosesCount?: InputMaybe<Scalars['Int']['input']>;
  studCode?: InputMaybe<Scalars['String']['input']>;
  deliveryDate?: InputMaybe<Scalars['Date']['input']>;
  bullID?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateProtocolInjectionInput = {
  comment?: Scalars['String']['input'];
  protocolID: Scalars['ID']['input'];
  injectionID: Scalars['ID']['input'];
  dayNumber: Scalars['Int']['input'];
};

export type CustomMilkingReportYAxisFieldInput = {
  kind: CustomMilkingReportYAxisFieldKind;
  groupBy?: InputMaybe<CustomMilkingReportYAxisFieldGrouping>;
  withRightScale?: Scalars['Boolean']['input'];
};

export type MonitorGroupEdge = {
  __typename?: 'MonitorGroupEdge';
  node: MonitorGroup;
  cursor: Scalars['String']['output'];
};

export type MlkParlorGeneralReportCows = {
  __typename?: 'MlkParlorGeneralReportCows';
  total: Scalars['Int']['output'];
  perHour: Scalars['Float']['output'];
};

export enum CustomMilkingReportYAxisFieldGrouping {
  Sum = 'SUM',
  Mean = 'MEAN',
  Count = 'COUNT',
  Max = 'MAX',
  Min = 'MIN',
  Median = 'MEDIAN'
}

export type CustomMilkingReportChartEmpty = CustomMilkingReportChartInterface & {
  __typename?: 'CustomMilkingReportChartEmpty';
  xAxisLabels: CustomMilkingReportChartXAxisLabels;
};

export type InjectionsManufacturerEdge = {
  __typename?: 'InjectionsManufacturerEdge';
  node: InjectionsManufacturer;
  cursor: Scalars['String']['output'];
};

export type TestMilkingUploadConnection = {
  __typename?: 'TestMilkingUploadConnection';
  pageInfo: PageInfo;
  edges: Array<TestMilkingUploadEdge>;
  nodes: Array<TestMilkingUpload>;
};

export type CustomReportSettingsSortBySortingValue = {
  __typename?: 'CustomReportSettingsSortBySortingValue';
  valueKey: CustomReportSettingsValue;
  blueprintSourceFieldValue?: Maybe<CustomReportBlueprintSourceFieldValue>;
};

export type ReproductionCrRowByAnimalCycleValue = {
  __typename?: 'ReproductionCrRowByAnimalCycleValue';
  periodStart?: Maybe<Scalars['Int']['output']>;
  periodEnd?: Maybe<Scalars['Int']['output']>;
};

export type IntHardcodedValue = {
  __typename?: 'IntHardcodedValue';
  value: Scalars['Int']['output'];
};

export type CreateInjectionInput = {
  name: Scalars['String']['input'];
  daysMilkWithholdAfterLastThreatment: Scalars['Int']['input'];
  daysMeatWithholdAfterLastThreatment: Scalars['Int']['input'];
  cost?: InputMaybe<Scalars['Float']['input']>;
  supplier?: InputMaybe<Scalars['String']['input']>;
  injectionsManufacturerID?: InputMaybe<Scalars['ID']['input']>;
  comment?: Scalars['String']['input'];
};

export type CreateInseminationInput = {
  happenedAt?: InputMaybe<Scalars['DateTime']['input']>;
  comment?: Scalars['String']['input'];
  movedToGroupWithBullAt?: InputMaybe<Scalars['DateTime']['input']>;
  result?: InseminationResult;
  cowID: Scalars['ID']['input'];
  bullID: Scalars['ID']['input'];
  semenDoseID?: InputMaybe<Scalars['ID']['input']>;
  schemeID?: InputMaybe<Scalars['ID']['input']>;
  employeeID?: InputMaybe<Scalars['ID']['input']>;
};

export type BlueprintLaunchCycleInput = {
  cycleInputID: Scalars['ID']['input'];
  inputs: Array<BlueprintLaunchInput>;
};

export type MilkingParlorIntervalPenGroupEdge = {
  __typename?: 'MilkingParlorIntervalPenGroupEdge';
  milkingNumber?: Maybe<Scalars['Int']['output']>;
  cursor: Scalars['String']['output'];
  node: PenGroup;
};

export type CustomReportSettingsValue = {
  __typename?: 'CustomReportSettingsValue';
  blueprintSourceField: SourceField;
  formula: CustomReportValueFormulaKindEnum;
  view: CustomReportValueViewKindEnum;
};

export type ReproductionHdrAndPrRowByDate = ReproductionHdrAndPrRow & {
  __typename?: 'ReproductionHdrAndPrRowByDate';
  hashID: Scalars['String']['output'];
  fitForInsemination?: Maybe<Scalars['Int']['output']>;
  inseminated?: Maybe<Scalars['Int']['output']>;
  hdr?: Maybe<Scalars['Int']['output']>;
  fitForPregnancy?: Maybe<Scalars['Int']['output']>;
  pregnant?: Maybe<Scalars['Int']['output']>;
  pr?: Maybe<Scalars['Int']['output']>;
  notPregnant?: Maybe<Scalars['Int']['output']>;
  notPregnantPercent?: Maybe<Scalars['Int']['output']>;
  cr?: Maybe<Scalars['Int']['output']>;
  abortions?: Maybe<Scalars['Int']['output']>;
  date: Scalars['Date']['output'];
};

export type CreateFreshEventActionInput = {
  __typename?: 'CreateFreshEventActionInput';
  happenedAt?: Maybe<Scalars['DateTime']['output']>;
  employeeID?: Maybe<Scalars['ID']['output']>;
  calvings: Array<CreateCalvingActionInput>;
};

export type SomaticCellsReportChartInterface = {
  dates: Array<Scalars['Date']['output']>;
};

/** Union of types representing actions payloads */
export type ActionPayloadType = CreateFreshEventActionInput;

export type UpdateProtocolInjectionInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  injectionID?: InputMaybe<Scalars['ID']['input']>;
  dayNumber?: InputMaybe<Scalars['Int']['input']>;
};

export enum CowRetirementReason {
  RespiratoryDisease = 'RESPIRATORY_DISEASE',
  BirthTrauma = 'BIRTH_TRAUMA',
  LimbsDisease = 'LIMBS_DISEASE',
  LimbsTrauma = 'LIMBS_TRAUMA',
  InternalOrgansDisease = 'INTERNAL_ORGANS_DISEASE',
  Reproduction = 'REPRODUCTION',
  GastrointestinalDisease = 'GASTROINTESTINAL_DISEASE',
  ZoologicalDefect = 'ZOOLOGICAL_DEFECT',
  LowProductivity = 'LOW_PRODUCTIVITY',
  UdderDisease = 'UDDER_DISEASE',
  UdderTrauma = 'UDDER_TRAUMA',
  Ketosis = 'KETOSIS',
  DisturbedMetabolism = 'DISTURBED_METABOLISM',
  Leucosis = 'LEUCOSIS',
  Other = 'OTHER',
  SaleToThePeople = 'SALE_TO_THE_PEOPLE',
  Trauma = 'TRAUMA',
  MeatProcessingPlant = 'MEAT_PROCESSING_PLANT',
  TribalSale = 'TRIBAL_SALE'
}

export type MilkingParlorFarmSetting = {
  __typename?: 'MilkingParlorFarmSetting';
  firstMilkingPerDayStartOn: Scalars['Time']['output'];
};

export type Lactation = {
  __typename?: 'Lactation';
  id: Scalars['ID']['output'];
  company: Company;
  cow: Cow;
  calving?: Maybe<Calving>;
  happenedAt: Scalars['DateTime']['output'];
  lastHeatAt?: Maybe<Scalars['DateTime']['output']>;
  lastInseminatedAt?: Maybe<Scalars['DateTime']['output']>;
  dryAt?: Maybe<Scalars['DateTime']['output']>;
  state: LactationState;
  number: Scalars['Int']['output'];
};

export type BlueprintGeneralSettings = {
  __typename?: 'BlueprintGeneralSettings';
  vitalityFilter: VitalityFilter;
  orderedInputIDs: Array<Scalars['ID']['output']>;
};

export type CutCode = {
  __typename?: 'CutCode';
  sortingGates: Array<SortingGate>;
  cow: Cow;
};

export type EventMappingInput = {
  importedName: Scalars['String']['input'];
  targetName: Scalars['String']['input'];
};

export enum SourceSectionKindEnum {
  Milk = 'MILK',
  Event = 'EVENT',
  Lactation = 'LACTATION',
  Vet = 'VET',
  Common = 'COMMON',
  Dates = 'DATES',
  Arithmetic = 'ARITHMETIC'
}

export type BlueprintConnection = {
  __typename?: 'BlueprintConnection';
  pageInfo: PageInfo;
  edges: Array<BlueprintEdge>;
  nodes: Array<Blueprint>;
};

export type BlueprintRoleEdge = {
  __typename?: 'BlueprintRoleEdge';
  node: BlueprintRole;
  cursor: Scalars['String']['output'];
};

export type SemenDoseEdge = {
  __typename?: 'SemenDoseEdge';
  node: SemenDose;
  cursor: Scalars['String']['output'];
};

export type ReproductionCrRowsByInseminationNumber = ReproductionCrDetailedRowsByEntity & {
  __typename?: 'ReproductionCrRowsByInseminationNumber';
  hashID: Scalars['String']['output'];
  total?: Maybe<ReproductionCrRowsRow>;
  other?: Maybe<ReproductionCrRowsRow>;
  rows: Array<ReproductionCrRowsRow>;
  rowNames: Array<Maybe<ReproductionCrInseminationNumberValue>>;
};

export type CustomReportRowInterface = {
  blueprintSourceFieldValue?: Maybe<CustomReportBlueprintSourceFieldValue>;
  values: Array<Maybe<CustomReportRowValue>>;
};

export enum BlueprintKind {
  List = 'LIST',
  Input = 'INPUT',
  WorkList = 'WORK_LIST'
}

export type CustomReportChartFieldValueAxis = {
  __typename?: 'CustomReportChartFieldValueAxis';
  blueprintSourceFieldValue: CustomReportBlueprintSourceFieldValue;
  aggFormula?: Maybe<CustomReportChartAggFormulaKindEnum>;
  withRightScale: Scalars['Boolean']['output'];
};

export type UpdateMonitorEntryInput = {
  target?: InputMaybe<Scalars['Float']['input']>;
  calculationMethod?: InputMaybe<CalculationMethodEnum>;
  name?: InputMaybe<Scalars['String']['input']>;
  leftBlueprintID?: InputMaybe<Scalars['ID']['input']>;
  leftBlueprintSourceFieldID?: InputMaybe<Scalars['ID']['input']>;
  rightBlueprintID?: InputMaybe<Scalars['ID']['input']>;
  monitorGroupID?: InputMaybe<Scalars['ID']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  launchBlueprint?: Maybe<BlueprintLaunchResultTable>;
  exportLaunchBlueprintCsv?: Maybe<File>;
  createBlueprint: Blueprint;
  updateBlueprint?: Maybe<Scalars['Void']['output']>;
  deleteBlueprint?: Maybe<Scalars['Void']['output']>;
  createBlueprintCycleInput: BlueprintCycleInput;
  createRole: BlueprintRole;
  /** @deprecated use just "createRole" */
  createBlueprintRole: BlueprintRole;
  updateRole?: Maybe<Scalars['Void']['output']>;
  /** @deprecated use just "updateRole" */
  updateBlueprintRole?: Maybe<Scalars['Void']['output']>;
  deleteRole?: Maybe<Scalars['Void']['output']>;
  /** @deprecated use just "deleteRole" */
  deleteBlueprintRole?: Maybe<Scalars['Void']['output']>;
  createBlueprintAction: BlueprintAction;
  createSourceSection: SourceSection;
  updateSourceSection?: Maybe<Scalars['Void']['output']>;
  createSourceField: SourceField;
  updateSourceField?: Maybe<Scalars['Void']['output']>;
  unsetArgument?: Maybe<Scalars['Void']['output']>;
  setArgument?: Maybe<Scalars['Void']['output']>;
  createBlueprintInput: BlueprintInput;
  updateBlueprintInput?: Maybe<Scalars['Void']['output']>;
  createBlueprintFilter: BlueprintFilter;
  updateBlueprintFilter?: Maybe<Scalars['Void']['output']>;
  createBlueprintFilterGroup: BlueprintFilterGroup;
  updateBlueprintFilterGroup?: Maybe<Scalars['Void']['output']>;
  createBlueprintUnion: BlueprintUnion;
  updateBlueprintUnion?: Maybe<Scalars['Void']['output']>;
  deleteBlueprintNode?: Maybe<Scalars['Void']['output']>;
  setBlueprintViewSettings?: Maybe<Scalars['Void']['output']>;
  createDisease: Disease;
  updateDisease?: Maybe<Scalars['Void']['output']>;
  setDiseasesProtocols?: Maybe<Scalars['Void']['output']>;
  deleteDisease?: Maybe<Scalars['Void']['output']>;
  deleteDiseases?: Maybe<Scalars['Void']['output']>;
  createInjection: Injection;
  updateInjection?: Maybe<Scalars['Void']['output']>;
  deleteInjection?: Maybe<Scalars['Void']['output']>;
  deleteInjections?: Maybe<Scalars['Void']['output']>;
  createProtocol: Protocol;
  createProtocolV2: Protocol;
  updateProtocol?: Maybe<Scalars['Void']['output']>;
  updateProtocolV2?: Maybe<Scalars['Void']['output']>;
  deleteProtocol?: Maybe<Scalars['Void']['output']>;
  deleteProtocols?: Maybe<Scalars['Void']['output']>;
  deactivateProtocols?: Maybe<Scalars['Void']['output']>;
  activateProtocols?: Maybe<Scalars['Void']['output']>;
  copyProtocols?: Maybe<Scalars['Void']['output']>;
  createProtocolInjection: ProtocolInjection;
  updateProtocolInjection?: Maybe<Scalars['Void']['output']>;
  deleteProtocolInjection?: Maybe<Scalars['Void']['output']>;
  deleteTestMilking?: Maybe<Scalars['Void']['output']>;
  deleteTestMilkingUpload?: Maybe<Scalars['Void']['output']>;
  loadTestMilkingCsv: TestMilkingUpload;
  exportTestMilkingUploadNotFoundCows: File;
  dismissTestMilkingUploadNotFoundCows?: Maybe<Scalars['Void']['output']>;
  createOperation: Operation;
  updateOperation?: Maybe<Scalars['Void']['output']>;
  deleteOperation?: Maybe<Scalars['Void']['output']>;
  deleteOperations?: Maybe<Scalars['Void']['output']>;
  createInseminationScheme: InseminationScheme;
  updateInseminationScheme?: Maybe<Scalars['Void']['output']>;
  deleteInseminationScheme?: Maybe<Scalars['Void']['output']>;
  deleteInseminationSchemes?: Maybe<Scalars['Void']['output']>;
  createSemenDose: SemenDose;
  updateSemenDose?: Maybe<Scalars['Void']['output']>;
  deleteSemenDose?: Maybe<Scalars['Void']['output']>;
  createPenGroup: PenGroup;
  updatePenGroup?: Maybe<Scalars['Void']['output']>;
  deletePenGroup?: Maybe<Scalars['Void']['output']>;
  createEmployee: Employee;
  updateEmployee?: Maybe<Scalars['Void']['output']>;
  deleteEmployee?: Maybe<Scalars['Void']['output']>;
  createInsemination: Insemination;
  updateInsemination?: Maybe<Scalars['Void']['output']>;
  deleteInsemination?: Maybe<Scalars['Void']['output']>;
  setPinnedCowFields?: Maybe<Scalars['Void']['output']>;
  setCowStaticField?: Maybe<Scalars['Void']['output']>;
  createBull: Bull;
  updateBull?: Maybe<Scalars['Void']['output']>;
  deleteBull?: Maybe<Scalars['Void']['output']>;
  createCow: Cow;
  updateCow?: Maybe<Scalars['Void']['output']>;
  deleteCow?: Maybe<Scalars['Void']['output']>;
  loadCowsCsv?: Maybe<Scalars['Void']['output']>;
  rollbackEvent?: Maybe<Scalars['Void']['output']>;
  rollbackLaunchBlueprint?: Maybe<Scalars['Void']['output']>;
  loadDCImportArchive?: Maybe<Scalars['Void']['output']>;
  importCompanyFromFile?: Maybe<Scalars['Void']['output']>;
  fetchEventsFromFile: Array<ImportedEvent>;
  fetchCowFieldsFromFile: Array<ImportedCowField>;
  exportCompanyToFile: ExportJob;
  loadDairyPlanMilkImportArchive?: Maybe<Scalars['Void']['output']>;
  processRawMilkings?: Maybe<Scalars['Void']['output']>;
  exportDairyPlanCowStatusFile: File;
  exportDairyPlanCutCodesFile: File;
  exportParlorFiles: Array<File>;
  loadRawMilkImportArchive?: Maybe<Scalars['Void']['output']>;
  exportEventsCsv: File;
  createMonitorGroup: MonitorGroup;
  updateMonitorGroup?: Maybe<Scalars['Void']['output']>;
  deleteMonitorGroup?: Maybe<Scalars['Void']['output']>;
  createMonitorEntry: MonitorEntry;
  updateMonitorEntry?: Maybe<Scalars['Void']['output']>;
  deleteMonitorEntry?: Maybe<Scalars['Void']['output']>;
  calculateMonitor?: Maybe<Scalars['Void']['output']>;
  deleteLastMonitorLaunch?: Maybe<Scalars['Void']['output']>;
  deleteMonitorLaunch?: Maybe<Scalars['Void']['output']>;
  setMonitorSchedule?: Maybe<Scalars['Void']['output']>;
  unsetMonitorSchedule?: Maybe<Scalars['Void']['output']>;
  applyCowPlannedInjections?: Maybe<Scalars['Void']['output']>;
  exportCowPlannedInjections: File;
  updateLivestockForecastSetting?: Maybe<Scalars['Void']['output']>;
  createMilkingParlor: MilkingParlor;
  updateMilkingParlor?: Maybe<Scalars['Void']['output']>;
  deleteMilkingParlor?: Maybe<Scalars['Void']['output']>;
  setMilkingParlorFarmSettings?: Maybe<Scalars['Void']['output']>;
  createMilkingParlorInterval: MilkingParlorInterval;
  updateMilkingParlorInterval?: Maybe<Scalars['Void']['output']>;
  deleteMilkingParlorInterval?: Maybe<Scalars['Void']['output']>;
  setPenGroupMilkingIntervalNumber?: Maybe<Scalars['Void']['output']>;
  applyCutCodes?: Maybe<Scalars['Void']['output']>;
  createCustomReport: CustomReport;
  updateCustomReport?: Maybe<Scalars['Void']['output']>;
  deleteCustomReport?: Maybe<Scalars['Void']['output']>;
  launchCustomReport?: Maybe<CustomReportLaunchResult>;
  calculateCustomReportPivotTable: CustomReportPivotTableInterface;
  calculateCustomReportChart: CustomReportChartInterface;
  createCustomReportChartSettings: CustomReportChartSettings;
  updateCustomReportChartSettings?: Maybe<Scalars['Void']['output']>;
  deleteCustomReportChartSettings?: Maybe<Scalars['Void']['output']>;
  createCowsCopyKey: CowsCopyKey;
  deleteCowsFromCowsCopyKey?: Maybe<Scalars['Void']['output']>;
  deleteCowsCopyKey?: Maybe<Scalars['Void']['output']>;
  activateCowsCopyKey?: Maybe<Scalars['Void']['output']>;
  setHerriotIntegrationSettings?: Maybe<Scalars['Void']['output']>;
  unsetHerriotIntegrationSettings?: Maybe<Scalars['Void']['output']>;
  createCustomMilkingReport: CustomMilkingReport;
  updateCustomMilkingReport?: Maybe<Scalars['Void']['output']>;
  deleteCustomMilkingReport?: Maybe<Scalars['Void']['output']>;
  setCustomMilkingReportSettings: CustomMilkingReport;
  calculateCustomMilkingReport: CustomMilkingReportChartInterface;
  setReproductionSettings?: Maybe<Scalars['Void']['output']>;
  setReproductionTargets?: Maybe<Scalars['Void']['output']>;
  createReproductionCrReport: ReproductionCrReport;
  updateReproductionCrReport?: Maybe<Scalars['Void']['output']>;
  deleteReproductionCrReport?: Maybe<Scalars['Void']['output']>;
  setReproductionCrReportSettings: ReproductionCrReport;
  calculateReproductionCrReport: ReproductionCrCalculatedReportInterface;
  createReproductionHdrAndPrReport: ReproductionHdrAndPrReport;
  updateReproductionHdrAndPrReport?: Maybe<Scalars['Void']['output']>;
  deleteReproductionHdrAndPrReport?: Maybe<Scalars['Void']['output']>;
  setReproductionHdrAndPrReportSettings: ReproductionHdrAndPrReport;
  calculateReproductionHdrAndPrReport: ReproductionHdrAndPrCalculatedReportInterface;
  setSomaticCellsReportSettings: SomaticCellsReport;
  calculateSomaticCellsReport: SomaticCellsReportChartInterface;
  requestAuthCode?: Maybe<Scalars['Void']['output']>;
  validateAuthCode: TokenResponse;
  refreshAuthToken: TokenResponse;
  createZorgeToken: TokenResponse;
  resetAuthCodes?: Maybe<Scalars['Void']['output']>;
  createCompany: Company;
  copyCompany: Company;
  updateCompany?: Maybe<Scalars['Void']['output']>;
  deleteCompany?: Maybe<Scalars['Void']['output']>;
  createFarm: Farm;
  createFarms: Array<Farm>;
  updateFarm?: Maybe<Scalars['Void']['output']>;
  deleteFarm?: Maybe<Scalars['Void']['output']>;
  createUser: User;
  createCompanyUser: User;
  updateUser?: Maybe<Scalars['Void']['output']>;
  updateCompanyUser?: Maybe<Scalars['Void']['output']>;
  disableUser?: Maybe<Scalars['Void']['output']>;
  addUserToCompany?: Maybe<Scalars['Void']['output']>;
  addUserToFarm?: Maybe<Scalars['Void']['output']>;
  removeUserFromCompany?: Maybe<Scalars['Void']['output']>;
  removeUserFromFarm?: Maybe<Scalars['Void']['output']>;
  setUserIntegrator?: Maybe<Scalars['Void']['output']>;
  addIntergratorUserToAllFarms?: Maybe<Scalars['Void']['output']>;
};


export type MutationLaunchBlueprintArgs = {
  id: Scalars['ID']['input'];
  inputs?: InputMaybe<Array<BlueprintLaunchInput>>;
  cycleInputs?: InputMaybe<Array<BlueprintLaunchCycleInput>>;
  specialInput?: InputMaybe<BlueprintLaunchSpecialInput>;
  runOn?: InputMaybe<Scalars['Date']['input']>;
};


export type MutationExportLaunchBlueprintCsvArgs = {
  id: Scalars['ID']['input'];
  hashID?: InputMaybe<Scalars['String']['input']>;
  inputs?: InputMaybe<Array<BlueprintLaunchInput>>;
  cycleInputs?: InputMaybe<Array<BlueprintLaunchCycleInput>>;
  specialInput?: InputMaybe<BlueprintLaunchSpecialInput>;
  runOn?: InputMaybe<Scalars['Date']['input']>;
};


export type MutationCreateBlueprintArgs = {
  input: CreateBlueprintInput;
};


export type MutationUpdateBlueprintArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBlueprintInput;
};


export type MutationDeleteBlueprintArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateBlueprintCycleInputArgs = {
  input: CreateBlueprintCycleInputInput;
};


export type MutationCreateRoleArgs = {
  input: CreateBlueprintRoleInput;
};


export type MutationCreateBlueprintRoleArgs = {
  input: CreateBlueprintRoleInput;
};


export type MutationUpdateRoleArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBlueprintRoleInput;
};


export type MutationUpdateBlueprintRoleArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBlueprintRoleInput;
};


export type MutationDeleteRoleArgs = {
  id: Scalars['ID']['input'];
  migrateToRoleID?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteBlueprintRoleArgs = {
  id: Scalars['ID']['input'];
  migrateToRoleID?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCreateBlueprintActionArgs = {
  input: CreateBlueprintActionInput;
};


export type MutationCreateSourceSectionArgs = {
  input: CreateSourceSectionInput;
};


export type MutationUpdateSourceSectionArgs = {
  id: Scalars['ID']['input'];
  input: UpdateSourceSectionInput;
};


export type MutationCreateSourceFieldArgs = {
  input: CreateSourceFieldInput;
};


export type MutationUpdateSourceFieldArgs = {
  id: Scalars['ID']['input'];
  input: UpdateSourceFieldInput;
};


export type MutationUnsetArgumentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSetArgumentArgs = {
  id: Scalars['ID']['input'];
  input: SetArgumentInput;
};


export type MutationCreateBlueprintInputArgs = {
  input: CreateBlueprintInputInput;
};


export type MutationUpdateBlueprintInputArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBlueprintInputInput;
};


export type MutationCreateBlueprintFilterArgs = {
  input: CreateBlueprintFilterInput;
};


export type MutationUpdateBlueprintFilterArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBlueprintFilterInput;
};


export type MutationCreateBlueprintFilterGroupArgs = {
  input: CreateBlueprintFilterGroupInput;
};


export type MutationUpdateBlueprintFilterGroupArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBlueprintFilterGroupInput;
};


export type MutationCreateBlueprintUnionArgs = {
  input: CreateBlueprintUnionInput;
};


export type MutationUpdateBlueprintUnionArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBlueprintUnionInput;
};


export type MutationDeleteBlueprintNodeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSetBlueprintViewSettingsArgs = {
  blueprintID: Scalars['ID']['input'];
  viewSettings: SetBlueprintViewSettingsInput;
};


export type MutationCreateDiseaseArgs = {
  input: CreateDiseaseInput;
};


export type MutationUpdateDiseaseArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDiseaseInput;
};


export type MutationSetDiseasesProtocolsArgs = {
  inputs: Array<SetDiseaseProtocolsInput>;
};


export type MutationDeleteDiseaseArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDiseasesArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationCreateInjectionArgs = {
  input: CreateInjectionInput;
};


export type MutationUpdateInjectionArgs = {
  id: Scalars['ID']['input'];
  input: UpdateInjectionInput;
};


export type MutationDeleteInjectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteInjectionsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationCreateProtocolArgs = {
  input: CreateProtocolInput;
};


export type MutationCreateProtocolV2Args = {
  input: CreateProtocolInputV2;
};


export type MutationUpdateProtocolArgs = {
  id: Scalars['ID']['input'];
  input: UpdateProtocolInput;
};


export type MutationUpdateProtocolV2Args = {
  id: Scalars['ID']['input'];
  input: UpdateProtocolInputV2;
};


export type MutationDeleteProtocolArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteProtocolsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationDeactivateProtocolsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationActivateProtocolsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationCopyProtocolsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationCreateProtocolInjectionArgs = {
  input: CreateProtocolInjectionInput;
};


export type MutationUpdateProtocolInjectionArgs = {
  id: Scalars['ID']['input'];
  input: UpdateProtocolInjectionInput;
};


export type MutationDeleteProtocolInjectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTestMilkingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTestMilkingUploadArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLoadTestMilkingCsvArgs = {
  fileInput: TestMilkingFileInput;
};


export type MutationExportTestMilkingUploadNotFoundCowsArgs = {
  testMilkingUploadID: Scalars['ID']['input'];
};


export type MutationDismissTestMilkingUploadNotFoundCowsArgs = {
  testMilkingUploadID: Scalars['ID']['input'];
};


export type MutationCreateOperationArgs = {
  input: CreateOperationInput;
};


export type MutationUpdateOperationArgs = {
  id: Scalars['ID']['input'];
  input: UpdateOperationInput;
};


export type MutationDeleteOperationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteOperationsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationCreateInseminationSchemeArgs = {
  input: CreateInseminationSchemeInput;
};


export type MutationUpdateInseminationSchemeArgs = {
  id: Scalars['ID']['input'];
  input: UpdateInseminationSchemeInput;
};


export type MutationDeleteInseminationSchemeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteInseminationSchemesArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationCreateSemenDoseArgs = {
  input: CreateSemenDoseInput;
};


export type MutationUpdateSemenDoseArgs = {
  id: Scalars['ID']['input'];
  input: UpdateSemenDoseInput;
};


export type MutationDeleteSemenDoseArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreatePenGroupArgs = {
  input: CreatePenGroupInput;
};


export type MutationUpdatePenGroupArgs = {
  id: Scalars['ID']['input'];
  input: UpdatePenGroupInput;
};


export type MutationDeletePenGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateEmployeeArgs = {
  input: CreateEmployeeInput;
};


export type MutationUpdateEmployeeArgs = {
  id: Scalars['ID']['input'];
  input: UpdateEmployeeInput;
};


export type MutationDeleteEmployeeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateInseminationArgs = {
  input: CreateInseminationInput;
};


export type MutationUpdateInseminationArgs = {
  id: Scalars['ID']['input'];
  input: UpdateInseminationInput;
};


export type MutationDeleteInseminationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSetPinnedCowFieldsArgs = {
  kinds: Array<SourceFieldKindEnum>;
  blueprintSourceFieldIDs: Array<Scalars['ID']['input']>;
};


export type MutationSetCowStaticFieldArgs = {
  cowID: Scalars['ID']['input'];
  kind: SourceFieldKindEnum;
  value: CowFieldValueInput;
};


export type MutationCreateBullArgs = {
  input: CreateBullInput;
};


export type MutationUpdateBullArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBullInput;
};


export type MutationDeleteBullArgs = {
  id: Scalars['ID']['input'];
  migrateToBullID?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCreateCowArgs = {
  input: CreateCowInput;
};


export type MutationUpdateCowArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCowInput;
};


export type MutationDeleteCowArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLoadCowsCsvArgs = {
  fileInput: CowFileInput;
};


export type MutationRollbackEventArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRollbackLaunchBlueprintArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLoadDcImportArchiveArgs = {
  fileInput: DcImportFileInput;
};


export type MutationImportCompanyFromFileArgs = {
  input: ImportFileInput;
};


export type MutationFetchEventsFromFileArgs = {
  input: FetchFromFileInput;
};


export type MutationFetchCowFieldsFromFileArgs = {
  input: FetchFromFileInput;
};


export type MutationExportCompanyToFileArgs = {
  input: ExportFileInput;
};


export type MutationLoadDairyPlanMilkImportArchiveArgs = {
  fileInput: DairyPlanMilkImportFileInput;
};


export type MutationProcessRawMilkingsArgs = {
  input: ProcessRawMilkingsInput;
};


export type MutationExportDairyPlanCowStatusFileArgs = {
  exportInput: DairyPlanCowStatusExportFileInput;
};


export type MutationExportDairyPlanCutCodesFileArgs = {
  exportInput: DairyPlanCutCodesExportFileInput;
};


export type MutationExportParlorFilesArgs = {
  exportInput: ParlorExportFilesInput;
};


export type MutationLoadRawMilkImportArchiveArgs = {
  fileInput: RawMilkImportFileInput;
};


export type MutationExportEventsCsvArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
  since?: InputMaybe<Scalars['Date']['input']>;
  till?: InputMaybe<Scalars['Date']['input']>;
  kinds?: InputMaybe<Array<EventKindEnum>>;
  cowIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  eventIDs?: InputMaybe<Array<EventIdInput>>;
};


export type MutationCreateMonitorGroupArgs = {
  input: CreateMonitorGroupInput;
};


export type MutationUpdateMonitorGroupArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMonitorGroupInput;
};


export type MutationDeleteMonitorGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateMonitorEntryArgs = {
  input: CreateMonitorEntryInput;
};


export type MutationUpdateMonitorEntryArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMonitorEntryInput;
};


export type MutationDeleteMonitorEntryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCalculateMonitorArgs = {
  runOn?: InputMaybe<Scalars['Date']['input']>;
};


export type MutationDeleteMonitorLaunchArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSetMonitorScheduleArgs = {
  input: SetMonitorScheduleInput;
};


export type MutationUpdateLivestockForecastSettingArgs = {
  id: Scalars['ID']['input'];
  input: UpdateLivestockForecastSettingInput;
};


export type MutationCreateMilkingParlorArgs = {
  input: CreateMilkingParlorInput;
};


export type MutationUpdateMilkingParlorArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMilkingParlorInput;
};


export type MutationDeleteMilkingParlorArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSetMilkingParlorFarmSettingsArgs = {
  farmID: Scalars['ID']['input'];
  firstMilkingPerDayStartOn: Scalars['Time']['input'];
};


export type MutationCreateMilkingParlorIntervalArgs = {
  input: CreateMilkingParlorIntervalInput;
};


export type MutationUpdateMilkingParlorIntervalArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMilkingParlorIntervalInput;
};


export type MutationDeleteMilkingParlorIntervalArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSetPenGroupMilkingIntervalNumberArgs = {
  milkingParlorIntervalID: Scalars['ID']['input'];
  penGroupID: Scalars['ID']['input'];
  milkingNumber?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationApplyCutCodesArgs = {
  milkingParlorIntervalID: Scalars['ID']['input'];
  date: Scalars['Date']['input'];
  input: ApplyCutCodeInput;
};


export type MutationCreateCustomReportArgs = {
  input: CreateCustomReportInput;
};


export type MutationUpdateCustomReportArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCustomReportInput;
};


export type MutationDeleteCustomReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLaunchCustomReportArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  hashID?: InputMaybe<Scalars['String']['input']>;
  inputs?: InputMaybe<Array<BlueprintLaunchInput>>;
};


export type MutationCalculateCustomReportPivotTableArgs = {
  customReportHashID: Scalars['String']['input'];
  settingsInput?: InputMaybe<CustomReportSettingsInput>;
};


export type MutationCalculateCustomReportChartArgs = {
  customReportHashID: Scalars['String']['input'];
  settingsInput?: InputMaybe<CalculateCustomReportChartSettingsInput>;
};


export type MutationCreateCustomReportChartSettingsArgs = {
  input: CreateCustomReportChartSettingsInput;
};


export type MutationUpdateCustomReportChartSettingsArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCustomReportChartSettingsInput;
};


export type MutationDeleteCustomReportChartSettingsArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateCowsCopyKeyArgs = {
  input: CreateCowsCopyKeyInput;
};


export type MutationDeleteCowsFromCowsCopyKeyArgs = {
  key: Scalars['String']['input'];
  cowIDs: Array<Scalars['ID']['input']>;
};


export type MutationDeleteCowsCopyKeyArgs = {
  key: Scalars['String']['input'];
};


export type MutationActivateCowsCopyKeyArgs = {
  input: ActivateCowsCopyKeyInput;
};


export type MutationSetHerriotIntegrationSettingsArgs = {
  companyID: Scalars['ID']['input'];
  input: SetHerriotIntegrationSettingsInput;
};


export type MutationUnsetHerriotIntegrationSettingsArgs = {
  companyID: Scalars['ID']['input'];
};


export type MutationCreateCustomMilkingReportArgs = {
  input: CreateCustomMilkingReportInput;
};


export type MutationUpdateCustomMilkingReportArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCustomMilkingReportInput;
};


export type MutationDeleteCustomMilkingReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSetCustomMilkingReportSettingsArgs = {
  id: Scalars['ID']['input'];
  input: SetCustomMilkingReportSettingsInput;
};


export type MutationCalculateCustomMilkingReportArgs = {
  id: Scalars['ID']['input'];
  settings: SetCustomMilkingReportSettingsInput;
};


export type MutationSetReproductionSettingsArgs = {
  farmID: Scalars['ID']['input'];
  input: SetReproductionSettingsInput;
};


export type MutationSetReproductionTargetsArgs = {
  farmID: Scalars['ID']['input'];
  input: SetReproductionTargetsInput;
};


export type MutationCreateReproductionCrReportArgs = {
  input: CreateReproductionCrReportInput;
};


export type MutationUpdateReproductionCrReportArgs = {
  id: Scalars['ID']['input'];
  input: UpdateReproductionCrReportInput;
};


export type MutationDeleteReproductionCrReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSetReproductionCrReportSettingsArgs = {
  id: Scalars['ID']['input'];
  input: SetReproductionCrReportSettingsInput;
};


export type MutationCalculateReproductionCrReportArgs = {
  id: Scalars['ID']['input'];
  input: SetReproductionCrReportSettingsInput;
};


export type MutationCreateReproductionHdrAndPrReportArgs = {
  input: CreateReproductionHdrAndPrReportInput;
};


export type MutationUpdateReproductionHdrAndPrReportArgs = {
  id: Scalars['ID']['input'];
  input: UpdateReproductionHdrAndPrReportInput;
};


export type MutationDeleteReproductionHdrAndPrReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSetReproductionHdrAndPrReportSettingsArgs = {
  id: Scalars['ID']['input'];
  input: SetReproductionHdrAndPrReportSettingsInput;
};


export type MutationCalculateReproductionHdrAndPrReportArgs = {
  id: Scalars['ID']['input'];
  input: SetReproductionHdrAndPrReportSettingsInput;
};


export type MutationSetSomaticCellsReportSettingsArgs = {
  id: Scalars['ID']['input'];
  input: SetSomaticCellsReportSettingsInput;
};


export type MutationCalculateSomaticCellsReportArgs = {
  id: Scalars['ID']['input'];
  settings: SetSomaticCellsReportSettingsInput;
};


export type MutationRequestAuthCodeArgs = {
  email: Scalars['Email']['input'];
};


export type MutationValidateAuthCodeArgs = {
  email: Scalars['Email']['input'];
  code: Scalars['String']['input'];
};


export type MutationRefreshAuthTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationCreateZorgeTokenArgs = {
  companyID: Scalars['ID']['input'];
};


export type MutationResetAuthCodesArgs = {
  email: Scalars['Email']['input'];
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationCopyCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateCompanyArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCompanyInput;
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateFarmArgs = {
  input: CreateFarmInput;
};


export type MutationCreateFarmsArgs = {
  inputs: Array<CreateFarmInput>;
};


export type MutationUpdateFarmArgs = {
  id: Scalars['ID']['input'];
  input: UpdateFarmInput;
};


export type MutationDeleteFarmArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateCompanyUserArgs = {
  input: CreateCompanyUserInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input'];
  input: UpdateUserInput;
};


export type MutationUpdateCompanyUserArgs = {
  id: Scalars['ID']['input'];
  companyID: Scalars['ID']['input'];
  input: UpdateCompanyUserInput;
};


export type MutationDisableUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAddUserToCompanyArgs = {
  id: Scalars['ID']['input'];
  companyID: Scalars['ID']['input'];
};


export type MutationAddUserToFarmArgs = {
  id: Scalars['ID']['input'];
  farmID: Scalars['ID']['input'];
};


export type MutationRemoveUserFromCompanyArgs = {
  id: Scalars['ID']['input'];
  companyID: Scalars['ID']['input'];
};


export type MutationRemoveUserFromFarmArgs = {
  id: Scalars['ID']['input'];
  farmID: Scalars['ID']['input'];
};


export type MutationSetUserIntegratorArgs = {
  id: Scalars['ID']['input'];
  integratorID?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationAddIntergratorUserToAllFarmsArgs = {
  id: Scalars['ID']['input'];
};

export type CowInjection = CowEvent & {
  __typename?: 'CowInjection';
  happenedAt: Scalars['DateTime']['output'];
  comment: Scalars['String']['output'];
  canRollback: Scalars['Boolean']['output'];
  cow: Cow;
  lactation?: Maybe<Lactation>;
  id: Scalars['ID']['output'];
  company: Company;
  injection: Injection;
  cowProtocol?: Maybe<CowProtocol>;
};

export type ReproductionHdrAndPrRowByDayInMilk = ReproductionHdrAndPrRow & {
  __typename?: 'ReproductionHdrAndPrRowByDayInMilk';
  hashID: Scalars['String']['output'];
  fitForInsemination?: Maybe<Scalars['Int']['output']>;
  inseminated?: Maybe<Scalars['Int']['output']>;
  hdr?: Maybe<Scalars['Int']['output']>;
  fitForPregnancy?: Maybe<Scalars['Int']['output']>;
  pregnant?: Maybe<Scalars['Int']['output']>;
  pr?: Maybe<Scalars['Int']['output']>;
  notPregnant?: Maybe<Scalars['Int']['output']>;
  notPregnantPercent?: Maybe<Scalars['Int']['output']>;
  cr?: Maybe<Scalars['Int']['output']>;
  abortions?: Maybe<Scalars['Int']['output']>;
  dayInMilk: Scalars['Int']['output'];
};

export type CustomReportSettingsSortByValueInput = {
  valueKey: CustomReportSettingsValueInput;
  blueprintSourceFieldValue?: InputMaybe<CustomReportBlueprintSourceFieldValueInput>;
};

export type CowTestMilkOnDayInMilk = {
  __typename?: 'CowTestMilkOnDayInMilk';
  happenedAt: Scalars['Date']['output'];
  milkWeightKilograms: Scalars['Float']['output'];
  fatPercent?: Maybe<Scalars['Float']['output']>;
  proteinPercent?: Maybe<Scalars['Float']['output']>;
  daysInMilk: Scalars['Int']['output'];
  sccThousandsPerMl?: Maybe<Scalars['Float']['output']>;
};

export type BlueprintViewKindSettings = {
  __typename?: 'BlueprintViewKindSettings';
  tableSettings?: Maybe<BlueprintViewTableSettings>;
  graphSettings?: Maybe<BlueprintViewGraphSettings>;
};

export type CowBull = Cow | Bull;

export type UpdateMonitorGroupInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBlueprintGeneralSettingsInput = {
  vitalityFilter?: InputMaybe<VitalityFilter>;
  orderedInputIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ApplyCutCodeInput = {
  cowIDs: Array<Scalars['ID']['input']>;
  gateNumber: Scalars['Int']['input'];
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type BlueprintRoleConnection = {
  __typename?: 'BlueprintRoleConnection';
  pageInfo: PageInfo;
  edges: Array<BlueprintRoleEdge>;
  nodes: Array<BlueprintRole>;
};

export type MilkingParlorFlowRateReportRowEntry = MilkingParlorFlowRateReportEntry & {
  __typename?: 'MilkingParlorFlowRateReportRowEntry';
  cowStallPerHour?: Maybe<Scalars['Float']['output']>;
  flowRate0To15Sec?: Maybe<Scalars['Float']['output']>;
  flowRate15To30Sec?: Maybe<Scalars['Float']['output']>;
  flowRate30To60Sec?: Maybe<Scalars['Float']['output']>;
  peakFlowRate?: Maybe<Scalars['Float']['output']>;
  milkWeight0To120Sec?: Maybe<Scalars['Float']['output']>;
  milkWeightPercent0To120Sec?: Maybe<Scalars['Float']['output']>;
  penGroup?: Maybe<PenGroup>;
};

export type CustomMilkingReportYAxisField = {
  __typename?: 'CustomMilkingReportYAxisField';
  kind: CustomMilkingReportYAxisFieldKind;
  groupBy?: Maybe<CustomMilkingReportYAxisFieldGrouping>;
  withRightScale: Scalars['Boolean']['output'];
};

export type CustomMilkingReportSettingsHistoricValue = CustomMilkingReportSettingsHistoricValueLactationNumbers | CustomMilkingReportSettingsHistoricValueLactationGroupNumbers | CustomMilkingReportSettingsHistoricValuePenGroups;

export type MonitorLaunch = {
  __typename?: 'MonitorLaunch';
  id: Scalars['ID']['output'];
  happenedAt: Scalars['DateTime']['output'];
};

export type CustomReportChartMultipleValues = CustomReportChartInterface & {
  __typename?: 'CustomReportChartMultipleValues';
  xAxisLabels: Array<Maybe<CustomReportBlueprintSourceFieldValue>>;
  yAxisMultiValueDatasets: Array<Array<Array<CustomReportChartValue>>>;
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID']['output'];
  maxLactationInseminationsNumber: Scalars['Int']['output'];
  maxLactationNumber: Scalars['Int']['output'];
  cowsCount: Scalars['Int']['output'];
  blueprintsCount: Scalars['Int']['output'];
  employees: EmployeeTypeConnection;
  isLockedForWrite: Scalars['Boolean']['output'];
  monitorSchedule?: Maybe<MonitorSchedule>;
  /** @deprecated use just "roles" */
  blueprintRoles: Array<BlueprintRole>;
  roles: Array<BlueprintRole>;
  herriotIntegrationSettings?: Maybe<HerriotIntegrationSettings>;
  isHerriotIntegrationSet: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  licensedBy: Scalars['DateTime']['output'];
  farms: CompanyFarmConnection;
  users: CompanyUserConnection;
  usersCount: Scalars['Int']['output'];
  tzIdentifier: Scalars['String']['output'];
};

export type CustomMilkingReportChartXAxisDateLabels = {
  __typename?: 'CustomMilkingReportChartXAxisDateLabels';
  xAxisDateLabels: Array<Scalars['Date']['output']>;
};

export type UpdateSourceSectionInput = {
  kind?: InputMaybe<SourceSectionKindEnum>;
};

export type CustomMilkingReportSettingsHistoricValueInput = {
  lactationNumbers?: InputMaybe<Array<Scalars['Int']['input']>>;
  lactationGroupNumbers?: InputMaybe<Array<LactationGroupNumber>>;
  penGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type TestMilkingFileInput = {
  happenedAt?: InputMaybe<Scalars['Date']['input']>;
  file: Scalars['Upload']['input'];
  resolveConflictsStrategy?: TestMilkingUploadResolveConflictsStrategy;
};

export type CustomMilkingReportSettingsFiltersInput = {
  actualFilter?: InputMaybe<CustomMilkingReportSettingsActualFilterInput>;
  historicFilter?: InputMaybe<CustomMilkingReportSettingsHistoricFilterInput>;
};

export type SetReproductionSettingsInput = {
  cowsVoluntaryWaitingPeriodDays: Scalars['Int']['input'];
  heifersVoluntaryWaitingPeriodDays: Scalars['Int']['input'];
};

export enum BreedingValueTypeEnum {
  Breeding = 'BREEDING',
  NonBreeding = 'NON_BREEDING',
  Undefined = 'UNDEFINED'
}

export enum LactationState {
  Early = 'EARLY',
  Mid = 'MID',
  Late = 'LATE',
  Dry = 'DRY',
  LateDry = 'LATE_DRY',
  Zero = 'ZERO'
}

export type ExportJob = {
  __typename?: 'ExportJob';
  id: Scalars['ID']['output'];
  file?: Maybe<File>;
  name: Scalars['String']['output'];
  status: ExportJobStatus;
  error?: Maybe<Scalars['String']['output']>;
};

export type ProtocolScheduledInjectionInput = {
  day: Scalars['Int']['input'];
  comment: Scalars['String']['input'];
  injectionID: Scalars['ID']['input'];
};

export type CustomReportSettingsInput = {
  rows: Array<CustomReportSettingsRowInput>;
  columns: Array<CustomReportSettingsColumnInput>;
  values: Array<CustomReportSettingsValueInput>;
};

export type SomaticCellsReportSettingsHistoricValueInput = {
  lactationNumbers?: InputMaybe<Array<Scalars['Int']['input']>>;
  lactationGroupNumbers?: InputMaybe<Array<LactationGroupNumber>>;
  penGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type CalvingResult = {
  __typename?: 'CalvingResult';
  id: Scalars['ID']['output'];
  company: Company;
  calving: Calving;
  cow?: Maybe<Cow>;
  isBull: Scalars['Boolean']['output'];
  isDead: Scalars['Boolean']['output'];
};

export enum ScheduleInterval {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  BiWeekly = 'BI_WEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Yearly = 'YEARLY'
}

export type MlkParlorGeneralReportMilkingTime = {
  __typename?: 'MlkParlorGeneralReportMilkingTime';
  durationSec: Scalars['Int']['output'];
  avgSec: Scalars['Int']['output'];
  start: Scalars['Time']['output'];
  stop: Scalars['Time']['output'];
};

export type CustomReportColumn = CustomReportColumnInterface & {
  __typename?: 'CustomReportColumn';
  blueprintSourceField: SourceField;
};

export type UserCompanyConnection = {
  __typename?: 'UserCompanyConnection';
  edges: Array<UserCompanyEdge>;
  nodes: Array<Company>;
  /** @deprecated  */
  hasNextPage: Scalars['Boolean']['output'];
  pageInfo: BeriaPageInfo;
};

export enum ActionKindEnum {
  DeleteCows = 'DELETE_COWS',
  SetBirthWeight = 'SET_BIRTH_WEIGHT',
  InjectCow = 'INJECT_COW',
  UserEventCow = 'USER_EVENT_COW',
  ProtocolCow = 'PROTOCOL_COW',
  ProtocolCowStop = 'PROTOCOL_COW_STOP',
  SetCowComment = 'SET_COW_COMMENT',
  UpdateCow = 'UPDATE_COW',
  DiseaseCow = 'DISEASE_COW',
  DiseaseWithLimbCow = 'DISEASE_WITH_LIMB_COW',
  CreateDefaultEventDied = 'CREATE_DEFAULT_EVENT_DIED',
  CreateDefaultEventDry = 'CREATE_DEFAULT_EVENT_DRY',
  CreateDefaultEventFresh = 'CREATE_DEFAULT_EVENT_FRESH',
  CreateDefaultEventOk = 'CREATE_DEFAULT_EVENT_OK',
  CreateDefaultEventReck = 'CREATE_DEFAULT_EVENT_RECK',
  CreateDefaultEventHeat = 'CREATE_DEFAULT_EVENT_HEAT',
  CreateDefaultEventBred = 'CREATE_DEFAULT_EVENT_BRED',
  CreateDefaultEventPreg = 'CREATE_DEFAULT_EVENT_PREG',
  CreateDefaultEventOpen = 'CREATE_DEFAULT_EVENT_OPEN',
  CreateDefaultEventPrev = 'CREATE_DEFAULT_EVENT_PREV',
  CreateDefaultEventMove = 'CREATE_DEFAULT_EVENT_MOVE',
  CreateDefaultEventBullpen = 'CREATE_DEFAULT_EVENT_BULLPEN',
  CreateDefaultEventAbort = 'CREATE_DEFAULT_EVENT_ABORT',
  CreateDefaultEventDnb = 'CREATE_DEFAULT_EVENT_DNB',
  CreateDefaultEventSold = 'CREATE_DEFAULT_EVENT_SOLD',
  CreateDefaultEventCheck = 'CREATE_DEFAULT_EVENT_CHECK',
  CreateDefaultEventCalfvac = 'CREATE_DEFAULT_EVENT_CALFVAC',
  CreateDefaultEventXid = 'CREATE_DEFAULT_EVENT_XID',
  CreateDefaultEventCulture = 'CREATE_DEFAULT_EVENT_CULTURE',
  CreateDefaultEventFootrim = 'CREATE_DEFAULT_EVENT_FOOTRIM',
  CreateDefaultEventGnrh = 'CREATE_DEFAULT_EVENT_GNRH',
  CreateDefaultEventHosp = 'CREATE_DEFAULT_EVENT_HOSP',
  CreateDefaultEventOvsynch = 'CREATE_DEFAULT_EVENT_OVSYNCH',
  CreateDefaultEventProst = 'CREATE_DEFAULT_EVENT_PROST',
  CreateDefaultEventToclose = 'CREATE_DEFAULT_EVENT_TOCLOSE',
  CreateDefaultEventVacc = 'CREATE_DEFAULT_EVENT_VACC',
  CreateDefaultEventWell = 'CREATE_DEFAULT_EVENT_WELL',
  CreateDefaultEventIllmisc = 'CREATE_DEFAULT_EVENT_ILLMISC',
  CreateDefaultEventWeight = 'CREATE_DEFAULT_EVENT_WEIGHT',
  CreateDefaultEventHeight = 'CREATE_DEFAULT_EVENT_HEIGHT',
  CreateDefaultEventBcs = 'CREATE_DEFAULT_EVENT_BCS'
}

export type MilkingParlorGeneralReportGroupedRowEntry = {
  __typename?: 'MilkingParlorGeneralReportGroupedRowEntry';
  cows: MlkParlorGeneralReportCows;
  milk: MlkParlorGeneralReportMilk;
  milkingTime: MlkParlorGeneralReportMilkingTime;
  notIdentifiedCount: Scalars['Int']['output'];
};

export type CowsMilking = {
  __typename?: 'CowsMilking';
  perHead: Scalars['Float']['output'];
  perHerd: Scalars['Float']['output'];
};

export type ReproductionCrRowsByEmployee = ReproductionCrDetailedRowsByEntity & {
  __typename?: 'ReproductionCrRowsByEmployee';
  hashID: Scalars['String']['output'];
  total?: Maybe<ReproductionCrRowsRow>;
  other?: Maybe<ReproductionCrRowsRow>;
  rows: Array<ReproductionCrRowsRow>;
  rowNames: Array<Maybe<Employee>>;
};

export type SomaticCellsReportChartEmpty = SomaticCellsReportChartInterface & {
  __typename?: 'SomaticCellsReportChartEmpty';
  dates: Array<Scalars['Date']['output']>;
};

export type UpdateLivestockForecastSettingInput = {
  dryPeriod?: InputMaybe<Scalars['Int']['input']>;
  freshPeriod?: InputMaybe<Scalars['Int']['input']>;
  cowsVoluntaryWaitingPeriod?: InputMaybe<Scalars['Int']['input']>;
  heifersVoluntaryWaitingPeriod?: InputMaybe<Scalars['Int']['input']>;
  cowsPregnancyRate?: InputMaybe<Scalars['Float']['input']>;
  heifersPregnancyRate?: InputMaybe<Scalars['Float']['input']>;
  cowsLeftInFirstLactRate?: InputMaybe<Scalars['Float']['input']>;
  cowsLeftInOtherLactRate?: InputMaybe<Scalars['Float']['input']>;
  bullsLeftZeroToTwoMonthRate?: InputMaybe<Scalars['Float']['input']>;
  bullsLeftThreeToSixMonthRate?: InputMaybe<Scalars['Float']['input']>;
  bullsLeftSevenToTwelveMonthRate?: InputMaybe<Scalars['Float']['input']>;
  heifersLeftZeroToTwoMonthRate?: InputMaybe<Scalars['Float']['input']>;
  heifersLeftThreeToSixMonthRate?: InputMaybe<Scalars['Float']['input']>;
  heifersLeftSevenToTwelveMonthRate?: InputMaybe<Scalars['Float']['input']>;
  heifersInFirstLactRate?: InputMaybe<Scalars['Float']['input']>;
  heifersInOtherLactRate?: InputMaybe<Scalars['Float']['input']>;
  stillbirthsInFirstLactRate?: InputMaybe<Scalars['Float']['input']>;
  stillbirthsInOtherLactRate?: InputMaybe<Scalars['Float']['input']>;
};

export enum MonitorScheduleInterval {
  Weekly = 'WEEKLY',
  BiWeekly = 'BI_WEEKLY',
  Monthly = 'MONTHLY'
}

export type DatetimeHardcodedValue = {
  __typename?: 'DatetimeHardcodedValue';
  value: Scalars['DateTime']['output'];
};

export type BlueprintFilterGroup = {
  __typename?: 'BlueprintFilterGroup';
  id: Scalars['ID']['output'];
  company: Company;
  parentFilterGroup?: Maybe<BlueprintFilterGroup>;
  union?: Maybe<BlueprintUnion>;
  filters: Array<BlueprintFilter>;
  actions: Array<BlueprintAction>;
  logicOperator: LogicOperator;
};

export type CustomReportPivotTableInterface = {
  columns: Array<CustomReportColumnInterface>;
  rows: Array<CustomReportRowInterface>;
};

export type SomaticCellsReportSettingsHistoricFilterInput = {
  kind?: InputMaybe<MilkingHistoricFilterKind>;
  value?: InputMaybe<SomaticCellsReportSettingsHistoricValueInput>;
};

export type Cow = {
  __typename?: 'Cow';
  id: Scalars['ID']['output'];
  retirementReason?: Maybe<CowRetirementReason>;
  company: Company;
  farm: Farm;
  penGroup: PenGroup;
  previousPenGroup?: Maybe<PenGroup>;
  mother?: Maybe<Cow>;
  motherClone?: Maybe<Cow>;
  father?: Maybe<Bull>;
  fatherClone?: Maybe<Bull>;
  milkingParlorPenGroup?: Maybe<PenGroup>;
  calvingsConnection: CalvingTypeConnection;
  lactationsConnection: CowLactationConnection;
  fields: Array<CowField>;
  events: CowEventInterfaceConnection;
  lactationGraphs: Array<CowLactationGraph>;
  genealogy: Array<GenealogyCow>;
  comment: Scalars['String']['output'];
  identifier: Scalars['Identifier']['output'];
  oldIdentifier?: Maybe<Scalars['Identifier']['output']>;
  name: Scalars['String']['output'];
  state: CowState;
  previousState?: Maybe<CowState>;
  dateOfBirth: Scalars['DateTime']['output'];
  birthWeightKilograms?: Maybe<Scalars['Float']['output']>;
  currentWeightKilograms?: Maybe<Scalars['Float']['output']>;
  currentHeightCentimeters?: Maybe<Scalars['Float']['output']>;
  usdaNumber: Scalars['String']['output'];
  registrationNumber: Scalars['String']['output'];
  breed: Scalars['String']['output'];
  selexBreedID?: Maybe<Scalars['Int']['output']>;
  motherDcID?: Maybe<Scalars['Int']['output']>;
  motherSelexID?: Maybe<Scalars['String']['output']>;
  motherName: Scalars['String']['output'];
  motherUsdaNumber: Scalars['String']['output'];
  fatherDcID?: Maybe<Scalars['Int']['output']>;
  fatherSelexID?: Maybe<Scalars['String']['output']>;
  fatherName: Scalars['String']['output'];
  fatherUsdaNumber: Scalars['String']['output'];
  compNumber: Scalars['String']['output'];
  numberOnPreviousFarm: Scalars['String']['output'];
  retiredAt?: Maybe<Scalars['DateTime']['output']>;
  dcID?: Maybe<Scalars['Int']['output']>;
  selexID?: Maybe<Scalars['String']['output']>;
  herdEntryDate?: Maybe<Scalars['DateTime']['output']>;
  lamenessScore: Scalars['Int']['output'];
  nipplesCount: Scalars['Int']['output'];
  electronicTagNumber: Scalars['String']['output'];
  transponderNumber: Scalars['String']['output'];
  bloodProteinTestValue?: Maybe<Scalars['Float']['output']>;
  bloodProteinTestHappenedAt?: Maybe<Scalars['DateTime']['output']>;
  herriotCode: Scalars['String']['output'];
  herriotCodeSetAt?: Maybe<Scalars['DateTime']['output']>;
  collarNumber: Scalars['String']['output'];
  earTagNumber: Scalars['String']['output'];
  isBull: Scalars['Boolean']['output'];
};


export type CowFieldsArgs = {
  isPinned?: InputMaybe<Scalars['Boolean']['input']>;
  section?: InputMaybe<SourceSectionKindEnum>;
};


export type CowEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  since?: InputMaybe<Scalars['DateTime']['input']>;
  till?: InputMaybe<Scalars['DateTime']['input']>;
  kinds?: InputMaybe<Array<EventKindEnum>>;
};


export type CowLactationGraphsArgs = {
  lactationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  groupBy?: LactationGraphGroupByEnum;
};

export type HerriotIntegrationEntity = {
  __typename?: 'HerriotIntegrationEntity';
  name: Scalars['String']['output'];
  guid: Scalars['UUID']['output'];
  kind: HerriotIntegrationEntityKindEnum;
};

export type Farm = {
  __typename?: 'Farm';
  id: Scalars['ID']['output'];
  milkingParlorSettings?: Maybe<MilkingParlorFarmSetting>;
  reproductionSettings: ReproductionSettings;
  reproductionTargets: ReproductionTargets;
  name: Scalars['String']['output'];
  number: Scalars['String']['output'];
  company: Company;
};

export enum LimbEnum {
  Al = 'AL',
  Lf = 'LF',
  Lr = 'LR',
  Rf = 'RF',
  Rr = 'RR',
  Bf = 'BF',
  Bh = 'BH',
  Bl = 'BL',
  Br = 'BR',
  Lx = 'LX',
  Rx = 'RX',
  Fl = 'FL',
  Fr = 'FR',
  Rl = 'RL',
  Hr = 'HR'
}

export type MonitorEdge = {
  __typename?: 'MonitorEdge';
  node: MonitorGroupMonitorEntry;
  cursor: Scalars['String']['output'];
};

export type CustomMilkingReportChartInterface = {
  xAxisLabels: CustomMilkingReportChartXAxisLabels;
};

export type ImportedEvent = {
  __typename?: 'ImportedEvent';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type CreateBlueprintGeneralSettingsInput = {
  vitalityFilter?: VitalityFilter;
};

export type UpdateEmployeeInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type CustomReportLaunchResult = {
  __typename?: 'CustomReportLaunchResult';
  hashID: Scalars['String']['output'];
  blueprintLaunchResult: CustomReportBlueprintLaunchResult;
  pivotTable: CustomReportPivotTableInterface;
  charts: Array<CustomReportChartInterface>;
};

export type ItemMappingInput = {
  originID: Scalars['ID']['input'];
  targetID?: InputMaybe<Scalars['ID']['input']>;
};

export type Argument = {
  name: Scalars['String']['output'];
  verboseName: Scalars['String']['output'];
};

export type CalvingEdge = {
  __typename?: 'CalvingEdge';
  node: Calving;
  cursor: Scalars['String']['output'];
};

export type ReproductionCrCalculatedReportEmpty = ReproductionCrCalculatedReportInterface & {
  __typename?: 'ReproductionCrCalculatedReportEmpty';
  cacheCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  xAxisValues: Array<ReproductionCrValue>;
  groupByValues: Array<ReproductionCrValue>;
};

export type ReproductionHdrAndPrReportEdge = {
  __typename?: 'ReproductionHdrAndPrReportEdge';
  node: ReproductionHdrAndPrReport;
  cursor: Scalars['String']['output'];
};

export type CowOperation = CowEvent & {
  __typename?: 'CowOperation';
  happenedAt: Scalars['DateTime']['output'];
  comment: Scalars['String']['output'];
  canRollback: Scalars['Boolean']['output'];
  cow: Cow;
  lactation?: Maybe<Lactation>;
  id: Scalars['ID']['output'];
  company: Company;
  operation: Operation;
};

export enum CompareToPreviousPeriodMode {
  PeriodByPeriod = 'PERIOD_BY_PERIOD',
  YearByYear = 'YEAR_BY_YEAR'
}

export type MonitorEntry = {
  __typename?: 'MonitorEntry';
  target?: Maybe<Scalars['Float']['output']>;
  calculationMethod: CalculationMethodEnum;
  name: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  leftBlueprint: Blueprint;
  leftBlueprintSourceField?: Maybe<SourceField>;
  rightBlueprint?: Maybe<Blueprint>;
  monitorGroup: MonitorGroup;
  values: Array<MonitorLaunchValue>;
};

export type MonitorGroupMonitorEntry = MonitorGroup | MonitorEntry;

export type UpdateMilkingParlorIntervalInput = {
  penGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  intervalStart?: InputMaybe<Scalars['Time']['input']>;
  intervalEnd?: InputMaybe<Scalars['Time']['input']>;
};

export enum Dow {
  Mon = 'MON',
  Tue = 'TUE',
  Wed = 'WED',
  Thu = 'THU',
  Fri = 'FRI',
  Sat = 'SAT',
  Sun = 'SUN'
}

export type Integrator = {
  __typename?: 'Integrator';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  companies: IntegratorCompanyConnection;
};

export type LivestockLactationPeriodsTotal = {
  __typename?: 'LivestockLactationPeriodsTotal';
  firstLactation: Scalars['Int']['output'];
  otherLactations: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type MilkingParlorMilkingMistakesReportRow = MilkingParlorMilkingMistakesReportRowEntry | MilkingParlorMilkingMistakesReportGroupedRowEntry;

export type SetMonitorScheduleInput = {
  interval: MonitorScheduleInterval;
  startOn: Scalars['DateTime']['input'];
  dows?: InputMaybe<Array<Array<Dow>>>;
};

export type CustomReportChartDirty = CustomReportChartInterface & {
  __typename?: 'CustomReportChartDirty';
  xAxisLabels: Array<Maybe<CustomReportBlueprintSourceFieldValue>>;
};

export type Employee = {
  __typename?: 'Employee';
  id: Scalars['ID']['output'];
  company: Company;
  comment: Scalars['String']['output'];
  number: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  middleName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type LivestockExpectedRetired = {
  __typename?: 'LivestockExpectedRetired';
  cows: LivestockLactationPeriodsTotal;
  bulls: LivestockLiveMonthsPeriodsTotal;
  heifers: LivestockLiveMonthsPeriodsTotal;
  total: Scalars['Int']['output'];
};

export type ExportJobEdge = {
  __typename?: 'ExportJobEdge';
  node: ExportJob;
  cursor: Scalars['String']['output'];
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  edges: Array<CompanyEdge>;
  nodes: Array<Company>;
  pageInfo: BeriaPageInfo;
};

export type UpdateBlueprintInputInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  prompt?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOperationInput = {
  name: Scalars['String']['input'];
};

export enum ExportEventsEnum {
  Exit = 'EXIT',
  Weight = 'WEIGHT'
}

export type EmployeeTypeConnection = {
  __typename?: 'EmployeeTypeConnection';
  pageInfo: PageInfo;
  edges: Array<EmployeeTypeEdge>;
  nodes: Array<Employee>;
};

export type CowPredictedMilkOnDayInMilk = {
  __typename?: 'CowPredictedMilkOnDayInMilk';
  happenedAt: Scalars['Date']['output'];
  milkWeightKilograms: Scalars['Float']['output'];
  daysInMilk: Scalars['Int']['output'];
  fatPercent?: Maybe<Scalars['Float']['output']>;
  proteinPercent?: Maybe<Scalars['Float']['output']>;
};

export enum ProcessKindEnum {
  Treatment = 'TREATMENT',
  Vaccination = 'VACCINATION',
  Synchronization = 'SYNCHRONIZATION',
  Care = 'CARE',
  Common = 'COMMON'
}
